import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';

// import { initializeApp } from 'firebase/app';
// import { getMessaging, isSupported, getToken, deleteToken, onMessage } from 'firebase/messaging';

const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_WEBKEY);

export default firebase.initializeApp(firebaseConfig);

// let messaging = null;
// (async () => {
//     if (await isSupported()) {
//         messaging = getMessaging(firebaseApp);
//     }
// })();

// export { firebaseApp, messaging, isSupported, getToken, deleteToken, onMessage };
