import * as React from 'react';

const SvgCustomPromotion = (props) => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className='svg-icon'
        {...props}>
        <path d="M1 20C0.716667 20 0.479167 19.9042 0.2875 19.7125C0.0958333 19.5208 0 19.2833 0 19V11C0 10.7167 0.0958333 10.4792 0.2875 10.2875C0.479167 10.0958 0.716667 10 1 10H4V6C4 4.33333 4.58333 2.91667 5.75 1.75C6.91667 0.583333 8.33333 0 10 0H14C15.6667 0 17.0833 0.583333 18.25 1.75C19.4167 2.91667 20 4.33333 20 6V19C20 19.2833 19.9042 19.5208 19.7125 19.7125C19.5208 19.9042 19.2833 20 19 20C18.7167 20 18.4792 19.9042 18.2875 19.7125C18.0958 19.5208 18 19.2833 18 19V17H14V19C14 19.2833 13.9042 19.5208 13.7125 19.7125C13.5208 19.9042 13.2833 20 13 20H1ZM7 14.85L3.125 12.65C2.875 12.5 2.625 12.5 2.375 12.65C2.125 12.8 2 13.0167 2 13.3C2 13.4333 2.03333 13.5625 2.1 13.6875C2.16667 13.8125 2.25833 13.9083 2.375 13.975L6.5 16.325C6.65 16.4083 6.81667 16.45 7 16.45C7.18333 16.45 7.35 16.4083 7.5 16.325L11.625 13.975C11.7417 13.9083 11.8333 13.8125 11.9 13.6875C11.9667 13.5625 12 13.4333 12 13.3C12 13.0167 11.875 12.8 11.625 12.65C11.375 12.5 11.125 12.5 10.875 12.65L7 14.85ZM14 15H18V6C18 4.9 17.6083 3.95833 16.825 3.175C16.0417 2.39167 15.1 2 14 2H10C8.9 2 7.95833 2.39167 7.175 3.175C6.39167 3.95833 6 4.9 6 6V10H13C13.2833 10 13.5208 10.0958 13.7125 10.2875C13.9042 10.4792 14 10.7167 14 11V15ZM9 8C8.71667 8 8.47917 7.90417 8.2875 7.7125C8.09583 7.52083 8 7.28333 8 7C8 6.71667 8.09583 6.47917 8.2875 6.2875C8.47917 6.09583 8.71667 6 9 6H15C15.2833 6 15.5208 6.09583 15.7125 6.2875C15.9042 6.47917 16 6.71667 16 7C16 7.28333 15.9042 7.52083 15.7125 7.7125C15.5208 7.90417 15.2833 8 15 8H9Z" fill="#383838" />
    </svg>
);

export default SvgCustomPromotion;



