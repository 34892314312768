import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Icon from '../../components/icon/Icon';
import Logo from '../../components/Logo_v2';
import { useMst } from '../../models';
import config from '../../config';
import { set } from 'lodash';

const Brand = ({ asideStatus, setAsideStatus, asideSubStatus, setAsideSubStatus }) => {
	const { user, company } = useMst();
	const [logo, setLogo] = useState(<Logo height={17} />);
	useEffect(() => {
		function validateImageUrl(url) {
			return new Promise((resolve) => {
				const img = new Image();
				img.onload = () => resolve(true); // 로드 성공
				img.onerror = () => resolve(false); // 로드 실패
				img.src = url; // URL 설정
			});
		}
		if (company?.get?.logoFile) {
			validateImageUrl(config.API_URL + '/images' + company.get?.logoFile?.path + '/' + company.get?.logoFile?.fileName).then((res) => {
				if (res) {
					setLogo(
						<img
							src={config.API_URL + '/images' + company.get?.logoFile?.path + '/' + company.get?.logoFile?.fileName}
							srcSet={config.API_URL + '/images' + company.get?.logoFile?.path + '/' + company.get?.logoFile?.fileName}
							alt={company?.get?.name}
							title={company.get?.name || ''}
							style={{ height: '25px', maxWidth: '120px' }}
							// onError={(e) => {
							// 	// e.target.src = ';';
							// }}
						/>
					);
				}
			});
		}
	}, [company]);

	return (
		<div className='brand'>
			<div className='brand-logo'>
				<h1 className='brand-title aside-logo'>{logo}</h1>
			</div>
			<button
				type='button'
				className='btn brand-aside-toggle'
				aria-label='Toggle Aside'
				onClick={() => {
					setAsideStatus(!asideStatus);
					setAsideSubStatus(!asideSubStatus);
				}}>
				<Icon icon='MenuOpen' className='brand-aside-toggle-close' size='2x' />
				<Icon icon='Menu' className='brand-aside-toggle-open' size='2x' />
			</button>
		</div>
	);
};
Brand.propTypes = {
	asideStatus: PropTypes.bool.isRequired,
	setAsideStatus: PropTypes.func.isRequired,
};

export default Brand;
