import { types } from 'mobx-state-tree';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import { browserName, browserVersion, osVersion, osName } from 'react-device-detect';
import config from '../config';
import DeviceService from '../services/DeviceService';

export const Configs = types
	.model('Config', {
		appVersion: types.maybeNull(types.string),
		fcmCode: types.maybeNull(types.string),
		fcmId: types.optional(types.number, 0),
		uuid: types.maybeNull(types.string),
		publicIp: types.maybeNull(types.string),
		privateIp: types.maybeNull(types.string),
		os: types.maybeNull(types.string),
		model: types.maybeNull(types.string),
		version: types.maybeNull(types.string),
	})
	.views((self) => ({
		get get() {
			return { ...self };
		},
	}))
	.actions((self) => ({
		async setFcm(v) {
			self.fcmCode = v;
		},
		async setFcmId(v) {
			self.fcmId = v || 0;
		},
		async setUuid(v) {
			self.uuid = v.toString();
		},
		async setPublicIp(v) {
			self.publicIp = v.toString();
		},
		async setPrivateIp(v) {
			self.privateIp = v.toString();
		},
		async setAppVersion(v) {
			self.appVersion = v.toString();
		},
		async setOs(v) {
			self.os = v.toString();
		},
		async setVersion(v) {
			self.version = v.toString();
		},
		async setModel(v) {
			self.model = v.toString();
		},
		async sendDevice() {
			let data = self.get;
			delete data.fcmId;
			DeviceService.send(data).then((res) => {
				// console.log('sendDevice', res);
				if (res?.data > 0) {
					self.setFcmId(res.data);
				} else {
					self.setFcmId(0);
				}
			});
		},
	}))
	.create({
		fcmCode: '',
		uuid: '',
		publicIp: '',
		privateIp: '',
		appVersion: `${config.appVersion}-${config.buildVersion}`,
		version: `${browserVersion}`,
		model: `${osName} ${osVersion} ${browserName}`,
		os: '',
		fcmId: 0,
	});
