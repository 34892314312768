import axios from 'axios';
import config from '../config';
import { getItem, removeItem, setItem } from './localstorage';
import jwtDecode from 'jwt-decode';

// 2022.10.19 axios 통신 progress
let progress = 0;
let timerId = null;

function setProgress(value) {
	progress = value;
	try {
		window.progressbar.onChange(progress);
	} catch (e) {
		// console.log(e)
	}
	// console.log(progress, window);
}

function timer() {
	if (progress < 98) {
		const diff = 100 - progress;
		const inc = diff / (10 + progress * (1 + progress / 100));
		setProgress(progress + inc);
	}
	timerId = setTimeout(timer, 50);
}

const axiosApi = axios.create({
	baseURL: config.API_URL,
	timeout: 0,
	withCredentials: true,
});

// intercepting to capture errors
axiosApi.interceptors.request.use(
	(request) => {
		// if(timerId === null) {
		setProgress(0);
		timer();
		// }

		if (!request?.headers?.['Content-Type']) request.headers['Content-Type'] = 'application/json';

		// console.log('request', request.headers);

		request.headers['client-id'] = config.CLIENT_ID;
		request.headers['mobile'] = false;
		const userType = getItem('userType');
		if(userType) request.headers['user-type'] = userType;

		request.headers['Access-Control-Allow-Methods'] = '*';
		request.headers['Access-Control-Allow-Credentials'] = true;
		request.headers['Access-Control-Allow-Origin'] = window.location.protocol + '//' + window.location.hostname + (window.location.port > 0 ? ':' : '') + window.location.port;

		//request.headers['code'] = Configs.fcmCode || ''
		//request.headers['os'] = Configs.os || ''

		const accessToken = getItem('access-token');
		const refreshToken = getItem('refresh-token');

		if (accessToken) {
			let tokenData = jwtDecode(accessToken);
			request.headers['authorization'] = 'Bearer ' + accessToken;
			request.headers['refresh-token'] = 'Bearer ' + refreshToken;
			request.headers['user-id'] = tokenData.id;
		}
		return request;
	},
	(error) => console.log(error)
);

axiosApi.interceptors.response.use(
	(response) => {
		// console.log('response', response)

		if (timerId) {
			clearTimeout(timerId);
			timerId = null;
		}
		setProgress(100);

		if (response.data.result || response.status === 200) {
			if (!response.headers['content-type'].includes('application/json')) {
				// console.log('download header');
				return response;
			}

			if (response.headers['access-token']) setItem('access-token', response.headers['access-token']);
			if (response.headers['refresh-token']) setItem('refresh-token', response.headers['refresh-token']);
			return response.data;
		} else {
			// console.log("response>>", response)
			let message;
			switch (response.status) {
				case 500:
					message = 'Internal Server Error';
					break;
				case 401:
					message = 'Invalid credentials';
					break;
				case 404:
					message = 'Sorry! the data you are looking for could not be found';
					break;
				/* case 910:
          message = "아이디 또는 비밀번호가 틀렸습니다."
          break */
				default:
					message = response.data.error || response.data.data.error;
			}
			//console.log("message>>", message)
			return Promise.reject(message);
		}
	},
	(error) => {
		setProgress(100);
		if (error.response) {
			if (error.response.data && error.response.data.message) {
				console.log('error.response', error.response);
				if (error.response.data.code === 910) {
					return error.response.data;
				}
				//console.log('!!!')
				//console.log("error.response.data", error.response.data)
				else if (error.response.data.code === 401 /*  || error.response.data.code === 500 */) {
					//removeItem('access-token')
					//removeItem('refresh-token')
					endSession();
				}
				// 운영반영 시점이라 추후 exception handling 때 넣어야 함
				else if (error.response.data.code === 500) {
					console.log(error);
					// 일반 backend에서 throw 하는 경우,(500에러) Promise.reject 하도록 추가
					// return Promise.reject(error.response.data)
					// or return error.response.data
					return error.response.data;
				} else {
					return error.response.data;
				}
			}
		}
	}
	// Any status codes that falls outside the range of 2xx cause this function to trigger
);

// const axiosApi2 = axios.create({
//   baseURL: config.API_URL,
//   timeout: 0,
// })

// axiosApi2.interceptors.request.use(
//   request => {
//     // if(timerId === null) {
//       setProgress(0);
//       timer();
//     // }

//     request.headers["Content-Type"] = "multipart/form-data"
//     request.headers["client-id"] = config.CLIENT_ID
//     request.headers['mobile'] = false
//     //request.headers['code'] = Configs.fcmCode || ''
//     //request.headers['os'] = Configs.os || ''

//     const accessToken = getItem("access-token")
//     const refreshToken = getItem("refresh-token")

//     if (accessToken) {
//       let tokenData = jwtDecode(accessToken)
//       request.headers["authorization"] = "Bearer " + accessToken
//       request.headers["refresh-token"] = "Bearer " + refreshToken
//       request.headers["user-id"] = tokenData.id
//     }

//     // if (accessToken && refreshToken) {
//     //   let tokenData
//     //   try {
//     //     tokenData = jwtDecode(accessToken)
//     //     request.headers["authorization"] = "Bearer " + accessToken
//     //     request.headers["user-id"] = tokenData.id
//     //   } catch (e) {
//     //     console.log(e)
//     //   }

//     //   if (!tokenData) {
//     //     try {
//     //       tokenData = jwtDecode(refreshToken)
//     //       request.headers["authorization"] = "Bearer " + accessToken
//     //       request.headers["refresh-token"] = "Bearer " + refreshToken
//     //       request.headers["user-id"] = tokenData.id
//     //     } catch (e) {
//     //       console.log(e)
//     //     }
//     //   }
//     // }

//     return request
//   },
//   error => console.log(error)
// )

// axiosApi2.interceptors.response.use(
//   response => {

//     if(timerId) {
//       clearTimeout(timerId);
//       timerId = null;
//     }
//     setProgress(100);

//     if (response.data.result || response.status === 200) {

//       // if(!response.headers['content-type'].includes('application/json')) {
//       //   console.log('download header');
//       //   return response;
//       // }

//       if (response.headers["access-token"]) setItem("access-token", response.headers["access-token"])
//       if (response.headers["refresh-token"]) setItem("refresh-token", response.headers["refresh-token"])
//       return response.data
//     } else {
//       //console.log("response>>", response)
//       let message
//       switch (response.status) {
//         case 500:
//           message = "Internal Server Error"
//           break
//         case 401:
//           message = "Invalid credentials"
//           break
//         case 404:
//           message = "Sorry! the data you are looking for could not be found"
//           break
//         /* case 910:
//           message = "아이디 또는 비밀번호가 틀렸습니다."
//           break */
//         default:
//           message = response.data.error || response.data.data.error
//       }
//       //console.log("message>>", message)
//       return Promise.reject(message)
//     }

//   },
//   error => {
//     if (error.response) {
//       //console.log(error)
//       if (error.response.data && error.response.data.message) {
//         if(error.response.data.code === 910) {
//           return error.response.data
//         }
//         //console.log('!!!')
//         //console.log("error.response.data", error.response.data)
//         if (error.response.data.code === 401/*  || error.response.data.code === 500 */) {
//           //removeItem('access-token')
//           //removeItem('refresh-token')
//           endSession();
//         }
//       }
//     }
//   }
//   // Any status codes that falls outside the range of 2xx cause this function to trigger
// )

const endSession = async () => {
	await Promise.all([
		removeItem('access-token'),
		removeItem('refresh-token'),
		removeItem('rootState'),
		removeItem('webToken'),
		removeItem('authUser'),
		//user.logout(),
		//company.logout(),
	]).then(() => {
		//document.location.href(`/${demoPages.login.path}`);
	});
};

class APIClient {
	/**
	 * Fetches data from given url
	 */
	get = (url, params) => axiosApi.get(url, params);

	/**
	 * post given data to url
	 */
	post = (url, data) => axiosApi.post(url, data);
	post_media = (url, data) =>
		axiosApi.post(url, data, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});

	/**
	 * Updates data
	 */
	put = (url, data) => axiosApi.put(url, data);

	/**
	 * Delete
	 */
	remove = (url) => axiosApi.delete(url);

	/**
	 * File
	 */
	post2 = (url, data) =>
		axiosApi.post(url, data, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
	put2 = (url, data) =>
		axiosApi.put(url, data, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
}

export { APIClient };

// /**
//  * Fetches data from given url
//  */
//  get = (url, params) => {
//   try {
//     return axiosApi.get(url, params)
//   } catch (e) {
//     console.log(e)
//   }
// }

// /**
//  * post given data to url
//  */
// post = (url, data) => {
//   try {
//     return axiosApi.post(url, data)
//   } catch (e) {
//     console.log(e)
//   }
// }

// /**
//  * Updates data
//  */
// put = (url, data) => {
//   try {
//     return axiosApi.put(url, data)
//   } catch (e) {
//     console.log(e)
//   }
// }

// /**
//  * Delete
//  */
// delete = url => {
//   try {
//     return axiosApi.delete(url)
//   } catch (e) {
//     console.log(e)
//   }
// }
