import { isValid } from 'date-fns';
import { APIClient } from '../lib/apiHelper';

const { get, post, put, remove, put2, post2 } = new APIClient();

export default {
	/**
	 * 회사 상세
	 */
	detail: (code, type = null, workPlaceId = null) => get('/company/' + (type ? 'detail/' + type + '/' : '') + code + (workPlaceId ? '/' + workPlaceId : '')),
	/**
	 * 회사 리스트
	 * @method GET
	 */
	list: (option = null) => get('/company' + (option ? '?' + new URLSearchParams(option) : '')),
	/**
	 * 회사 생성
	 * @param {*} data
	 * @returns
	 */
	create: (data) => post2('/company', data),
	/**
	 * 회사 수정
	 * @param {*} data
	 * @returns
	 */
	modify: (data) => put2('/company', data),
	/**
	 * 회사 삭제
	 * @param {*} id
	 * @returns
	 */
	delete: (code) => remove('/company/' + code),

	/**
	 * 회사 존재 여부 확인
	 * @param {*} code
	 * @returns boolean
	 */
	companyExists: (companyCode) => get(`/company/security/${companyCode}`),

	/**
	 * 고객 관리자 관리
	 * @param {*} data
	 * @returns
	 */
	AdminUser: (data) => post('/company/adminUser', data),
	/**
	 * 고객 관리자 삭제
	 */
	deleteAdminUser: (id) => remove('/company/adminUser/' + id),
	/**
	 * 사용자 관리
	 * @param {*} data
	 * @returns
	 */
	createUser: (data) => post('/company/user', data),
	createUsers: (companyCode, data) => post('/company/users/' + companyCode, data),
	updateUsersExcelData : (companyCode, data) => post(`/company/upload/user/${companyCode}`, data),
	/**
	 * 사용자 수정
	 * @param {*} data
	 * @returns
	 */
	updateUser: (data) => put('/company/user', data),
	/**
	 * 사용자 목록
	 */
	userList: (companyId, userType = null, year = null, options = null, workPlaceId = null) =>
		get(
			'/company/user?companyId=' +
				companyId +
				(userType ? '&userType=' + userType : '') +
				(year ? '&year=' + year : '') +
				(options ? '&' + new URLSearchParams(options) : '') +
				(workPlaceId ? '&workPlaceId=' + workPlaceId : '')
		),
	/**
	 * 인사정보 리스트
	 * @param {*} code
	 * @returns
	 */
	userHrList: (code, page = 1, filter = {}) => post('/company/hr/' + code + '/' + page, filter),
	/**
	 * 사번 중복 체크
	 * @param {*} code
	 * @returns
	 */
	checkLicenses: (code, licenses) => post('/company/license/isExists/' + code, licenses),
	/**
	 * 결재라인 전용 사용자 목록
	 */
	approvalUserList: (code) => get('/company/approval/user/' + code),
	/**
	 * 현재 회사 선택
	 * @method PUT
	 */
	selectCompany: () => get('/company/select'),
	/**
	 * 코드 리스트
	 * @method GET
	 */
	codeList: (companyCode = null) => get(`/company/code/${companyCode ? companyCode : ''}`),
	/**
	 * 코드 상세
	 * @param {*} data
	 * @returns
	 */
	codeDetail: (companyCode, parentId, code) => get(`/company/code/${companyCode}/${parentId}/${code}`),
	/**
	 * 직위 중복 체크
	 */
	isExistsRank: (companyCode, ranks) => post('/code/rank/isExists/' + companyCode, ranks),
	/**
	 * 직책 중복 체크
	 */
	isExistsPosition: (companyCode, positions) => post('/code/position/isExists/' + companyCode, positions),
	/**
	 * 사용자 연차 정보 생성
	 */
	createUserRestInfo: (data) => post('/user-rest-info', data),
	/**
	 * 사용자 연차 정보 수정
	 */
	updateUserRestInfo: (data) => put('/user-rest-info', data),
	/**
	 * 사용자 연차 정보 조회
	 */		
	checkUserRestInfo: (companyCode, option) => post(`/company/is-exists/user-rest-info/${companyCode}`, option),
	/**
	 * DATA 업로드, 연차생성, 연차 엑셀 Excel 업로드
	 * @param {*} data 
	 * @returns 
	 */
	updateUserLeaveExcelData : (companyCode, data) => post(`/company/update/user-rest-info/${companyCode}`, data),
	/**
	 * DATA 업로드, 입사1년미만 연차생성, 연차 엑셀 Excel 업로드
	 * @param {*} data 
	 * @returns 
	 */
	updateUserUnder1YearLeaveExcelData : (companyCode, data) => post(`/company/update/user-rest-info/under/${companyCode}`, data),
	/**
	 * 사용자 연차 마감
	 */
	closeUserRestInfo: (data) => post('/user-rest-info/close', data),
	/**
	 * 연차 삭제
	 */
	deleteUserRestInfo: (data) => post('/user-rest-info/delete', data),
	/**
	 * 연차 이월
	 * @returns 
	 */
	forwardUserRestInfo: (companyCode) => get('/user-rest-info/forward/' + companyCode),
	/**
	 * 보안정책 수정
	 */
	securityModify: (data) => put('/company/security', data),
	/**
	 * 회사 코드 수정
	 */
	codeModify: (data) => put('/company/code', data),
	/**
	 * 사용기능 저장
	 */
	settingModify: (data) => put('/company/setting', data),
	/**
	 * 사용기능 삭제
	 * @param {*} code
	 * @returns
	 */
	settingDelete: (code, id) => remove('/company/setting/' + code + '/' + id),

	approvalList: (code, type, option = {}) => post('/company/approval/' + type + '/' + code + '/registered', option ? option : {}),
	/**
	 * 결재선 미등록자 리스트
	 */
	approvalLineUnList: (code, type, option = null) => get('/company/approval/' + type + '/' + code + '/unregistered' + (option ? '?' + new URLSearchParams(option) : '')),
	/**
	 * 결재선 부서일괄 리스트
	 */
	approvalLineDepartmentList: (code) => get('/company/approval/' + code + '/department'),
	/**
	 * 결재선 등록
	 * @param {*} code
	 * @param {*} data
	 * @param {*} type
	 * @returns
	 */
	approvalLineRegister: (code, data, type) => post('/company/approval/' + type + '/' + code, data),
	/**
	 * 결재선 삭제
	 * @param {*} code
	 * @returns
	 */
	approvalLineDelete: (code, data) => put('/company/approval/' + code, data),
	/**
	 * 결재라인 엑셀 업로드
	 * @param {*} companyCode 
	 * @param {*} type 
	 * @param {*} data 
	 * @returns 
	 */
	aprovalLineUpadateExcel : (companyCode, type, data) => post(`/company/update/approval-line/${type}/${companyCode}`, data),
	/**
	 * 부서 리스트
	 */
	departmentList: (companyCode, option = null) => get('/company/department/' + companyCode + (option ? '?' + new URLSearchParams(option) : '')),
	/**
	 * 부서 코드, 부서명 리스트
	 * @param {*} companyCode 
	 * @param {*} departments 
	 * @returns 
	 */
	departmentTupleList : (companyCode) => get(`/company/department/tuple/${companyCode}`),
	/**
	 * 부서 코드 추가
	 */
	createDepartments: (companyCode, departments) => post(`/company/upload/${companyCode}/code/departments`,departments),
	/**
	 * 부서 중복 체크
	 */
	isExistsDepartments: (code, departments) => post('/company/department/isExists/' + code, departments),
	/**
	 * 부서 저장 및 수정
	 */
	departmentModify: (code, data) => post('/company/department/' + code, data),

	/**
	 * 사업장 API
	 */
	workPlaceList: (code, option = null) => get('/company/workPlace/' + code + (option ? '?' + new URLSearchParams(option) : '')),
	workPlaceDetail: (code, id) => get('/company/workPlace/' + code + '/' + id),
	workPlaceCreate: (data) => post2('/company/workPlace', data),
	workPlaceModify: (data) => put2('/company/workPlace', data),
	workPlaceDelete: (code, id) => remove(`company/workPlace/${code}/${id}`),
	workPlaceIsExists: (code, workPlaces) => post(`/company/workPlace/isExists/${code}`, workPlaces),

	/**
	 * 사용자 API
	 */
	/**
	 * 이메일 중복 체크
	 * @param {*} code
	 * @param {*} email
	 * @returns
	 */
	checkEmail: (code, type, email) => get('/user/isExistsEmail/' + code + '/' + type + '/' + email),
	/**
	 * 사번 중복 체크
	 * @param {*} code
	 * @param {*} license
	 * @returns
	 */
	checkLicense: (code, type, license) => get('/user/isExistsLicense/' + code + '/' + type + '/' + license),
	/**
	 * 휴대폰 중복 체크
	 * @param {*} code
	 * @param {*} phone
	 * @returns
	 */
	checkPhone: (code, type, phone) => get('/user/isExistsPhone/' + code + '/' + type + '/' + phone),
	/**
	 * 파일 다운로드
	 * /images/2/A010/1565a797ed00ff567eaf41620064f077833e1037.jpg
	 */
	download: (path, fileName) => get(`/images${path}/${fileName}`),
	/**
	 * 휴가 코드 리스트
	 */
	restCodeList: (companyCode) => get('/code/rest/' + companyCode),
	createRestTargetUser: (code, data) => post('/user-rest-info/create/user/' + code, data),
	/**
	 * 휴가 대상자 생성
	 */
	createRestTarget: (code, data) => post('/user-rest-info/create/' + code, data),
	/**
	 * 연차 조회
	 */
	restTargetList: (code, year, type, option = null) => get('/user-rest-info/' + code + '/' + type + '/' + year + (option ? '?' + new URLSearchParams(option) : '')),
	/**
	 * 연차 사용
	 */
	TargetRestActive: (data) => post('/user-rest-info/use', data),
	/**
	 * 사용자 검색
	 */
	userSearch: (companyCode, search, option = null) => get('/company/' + companyCode + '/user/search/' + search + (option ? '?' + new URLSearchParams(option) : '')),
	/**
	 * 위임자 검색
	 * @param {*} companyCode
	 * @param {*} search
	 * @param {*} option
	 * @returns
	 */
	userSearchDelegation: (companyCode, search, option = null) => get('/company/' + companyCode + '/delegation/search/' + search + (option ? '?' + new URLSearchParams(option) : '')),
	/**
	 * 퇴사자 리스트
	 */
	retireRestInfo: (companyCode, option = null) => get('/user-rest-info/retire/' + companyCode + (option ? '?' + new URLSearchParams(option) : '')),
	/**
	 * 퇴사자 연차 비교
	 */
	retireRestInfoCompare: (companyCode, data) => post('/user-rest-info/retire/' + companyCode, data),
	/**
	 * 퇴사자 연차 삭제
	 * @param {*} companyCode
	 * @param {*} id
	 * @returns
	 */
	retireRestInfoDelete: (companyCode, id) => remove('/user-rest-info/retire/' + companyCode + '/' + id),
	retireRestInfoDeletes: (companyCode, data) => post('/user-rest-info/retire/' + companyCode + '/delete', data),
	/**
	 * 수당지급 메일 발송
	 * @returns
	 */
	mailCalculate: (companyCode, data) => post('/user-rest-info/email/calculate/' + companyCode, data),
	/**
	 * 수당지급 리스트
	 */
	calculateList: (companyCode) => get('/user-rest-info/calculate/' + companyCode),
	/**
	 * 수당지급 리스트 개인
	 */
	calculateDetail: (companyCode) => get('/user-rest-info/calculate/user/' + companyCode),
	/**
	 * 수당지급 정산내역 조회 (사용자&결재권자)
	 */
	calculateApproval: (companyCode, data) => post('/user-rest-info/calculate/approval/' + companyCode, data),
	/**
	 * 수당지급 정산내역 조회 (관리자)
	 */
	calculateApprovalAdmin: (companyCode, data) => get('/user-rest-info/calculate/users/' + companyCode + '?' + new URLSearchParams(data)),
	/**
	 * 수당지급 정산내역 삭제 (관리자)
	 */
	calculateApprovalDelete: (companyCode, data) => post('/user-rest-info/calculate/users/delete/' + companyCode, data),
	/**
	 * 수당지급 사용자 > 관리자 통보
	 * @param {*} data
	 * @returns
	 */
	calculateSend: (companyCode, data) => post('/user-rest-info/calculate/send/' + companyCode, data),
	/**
	 * 휴가 신청
	 */
	restRequest: (data) => post2('/approval/request', data),
	/**
	 * 휴가 신청 (관리자) 일반휴가
	 * @returns 
	 */
	restRequestAdmin: (companyCode, data) => post('/approval/request/admin/'+companyCode, data),
	/**
	 * 휴가 신청 (관리자) 일반휴가 삭제
	 * @param {*} data 
	 * @returns 
	 */
	restRequestAdminDelete: (companyCode, data) => post('/approval/request/admin/delete/'+companyCode, data),
	/**
	 * 휴가 사후첨부
	 */
	restRequestAttach: (id, data) => post2('/approval/request/files/' + id, data),
	/**
	 * 휴가 신청 일자 목록
	 */
	restRequestDate: (companyCode, sdate, edate, userId='' ) => get(`/approval/request/date/${companyCode}?sdate=${sdate}&edate=${edate}&userId=${userId}`),
	/**
	 * 휴가 신청 리스트
	 */
	restRequestList: (companyCode, option) => get(`/approval/request/${companyCode}${option ? '?' + new URLSearchParams(option) : ''}`),
	/**
	 * 휴가 신청 리스트 (사용일수 포함)
	 */
	restRequestLists: (companyCode, option) => get(`/approval/requests/${companyCode}${option ? '?' + new URLSearchParams(option) : ''}`),
	/**
	 * 휴가 상세 정보
	 * @returns
	 */
	restRequestDetail: (companyCode, id) => get(`/approval/request/${companyCode}/${id}`),
	/** 휴가 신청 삭제 */
	restRequestDelete: (companyCode, id) => remove(`/approval/request/${companyCode}/${id}`),
	/** 결재 신청 // api_v1.js 참조 */
	restRequestApply: (companyCode, id) => get(`/approval/apply/${companyCode}/${id}`),
	/** 결재신청 취소 */
	restCancel: (companyCode, id) => get(`/approval/applyCancel/${companyCode}/${id}`),
	/** 사용자 결재 리스트 */
	restRequestApplyList: (companyCode, option) => get(`/approval/requestApproval/${companyCode}${option ? '?' + new URLSearchParams(option) : ''}`),
	/** 결재 승인/반려 */
	restRequestApproval: (companyCode, id, state, option = null) => post(`/approval/confirm/${state}/${companyCode}/${id}`, option),
	/** 결재권자 리스트 */
	approvalAllUserList: (companyCode) => get(`/approval/lines/${companyCode}`),
	/** 휴가 결재취소 요청 */
	restRequestCancel: (companyCode, id) => get(`/approval/applyCancelRequest/${companyCode}/${id}`),

	/** 결재권자 위임 등록 */
	approvalDelegate: (companyCode, data) => post(`/approval/absentee/${companyCode}`, data),
	/** 결재권자 위임 리스트 */
	approvalDelegateList: (companyCode, type, options = null) => get(`/approval/absentee/${companyCode}/${type}${options ? '?' + new URLSearchParams(options) : ''}`),
	/** 결재권자 위임 삭제 */
	approvalDelegateDelete: (companyCode, ids) => post(`/approval/absentee/${companyCode}/remove`, ids),
	/** 결재권자 위임 수정 */
	approvalDelegateModify: (companyCode, data) => put(`/approval/absentee/${companyCode}`, data),


	upoladCompassionateRestCode : (companyCode, data) => post(`/company/upload/${companyCode}/code/compassionate`, data),
	uploadVacationRestCode : (companyCode, data) => post(`/company/upload/${companyCode}/code/vacation`, data),

	uploadLeave : (companyCode, data) => post(`company/upload/${companyCode}/user-rest-info/YEAR`, data),
	uploadLeave1Yr : (companyCode, data) => post(`company/upload/${companyCode}/user-rest-info/MONTH`, data),

	/** 연차휴가 사용일수 일괄관리 */
	// 조회
	requestModificationSearch: (companyCode, option) => get(`/company/requestModification/${companyCode}?${new URLSearchParams(option)}`),
	// 저장&수정
	requestModificationSave: (companyCode, data) => post(`/company/requestModification/${companyCode}`, data),	
	requestModificationUpdateExcel: (companyCode, data) => post(`/company/update/requestModification/${companyCode}`, data),	
	// 삭제
	requestModificationDelete: (companyCode, data) => post(`/company/requestModification/${companyCode}/delete`, data),

	// 연차 일괄 등록 중복 여부
	// api.get('/company/validation/requestModification/:companyCode/:userId', approvalController.isValidRequestModification);
	isValidModification: (companyCode, option=null) => post(`/company/validation/requestModification/${companyCode}`, option),

	// api.remove('/view/:folder/:id/:fileName', authController.imageDelete)
	// 이미지 삭제
	imageDelete: (path) => remove(`/view${path}`),

	// 연차촉진
	// 연차촉진 리스트
	leavePromotionList: (companyCode, option) => get(`/company/restPromotion/${companyCode}${option ? '?' + new URLSearchParams(option) : ''}`),
	// 연차촉진 삭제
	leavePromotionDelete: (companyCode, data) => post(`/company/restPromotion/${companyCode}/remove`, data),
	// 연차촉진 1차 통보
	leavePromotionNotiDuration: (companyCode, data) => post(`/company/restPromotion/${companyCode}/notiDuration`, data),
	// 연차촉진 1차 통보취소
	leavePromotionNotiDurationCancel: (companyCode, data) => post(`/company/restPromotion/${companyCode}/notiDuration/cancel`, data),
	// 연차촉진 2차 사용시기 지정 수정(임시저장)
	leavePromotionPlan2Modify: (companyCode, id, data) => put(`/company/restPromotion/${companyCode}/usageSpeciNoti/${id}`, data),
	// 연차촉진 2차 사용시기 지정 통보
	leavePromotionPlan2Noti: (companyCode, data) => post(`/company/restPromotion/${companyCode}/usageSpeciNotiDuration`, data),
	// 연차촉진 1차 사용자 제출
	promotionPlanSubmit : (companyCode, data) => post(`/company/restPromotion/${companyCode}/promotionPlanSubmit`, data),
	// 연차촉진 열람
	promotionPlanRead: (companyCode, planStep, id) => get(`/company/restPromotion/${companyCode}/read/${planStep}/${id}`),
	// 연차촉진 사용자 상세정보
	promotionDetail: (companyCode, year='') => get(`/company/restPromotion/${companyCode}/detail${year ? '/' + year : ''}`),
}
