import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

// 플러그인 적용
dayjs.extend(utc);
dayjs.extend(timezone);

// 기본 타임존을 한국(KST)으로 설정
dayjs.tz.setDefault('Asia/Seoul');

export default dayjs;