import React from 'react';
import DashboardHeader from '../pages/common/Headers/DashboardHeader';
import { authMenu } from '../menu';

const headers = [
	// {
	// 	path: authMenu.blank.path,
	// 	element: null,
	// },
	{
		path: authMenu.VerifyEmail.path,
		element: null,
	},
	{
		path: authMenu.login.path,
		element: null,
	},
	{
		path: authMenu.sendMail.path,
		element: null,
	},
	{
		path: authMenu.page404.path,
		element: null,
	},
	{
		path: authMenu.signUp.path,
		element: null,
	},
	{
		path: authMenu.PasswdReset.path,
		element: null,
	},
	{
		path: `*`,
		element: <DashboardHeader />,
		// element: (rootStore?.user?.me?.id || 0) === 0 ? null : <DashboardHeader />,
	},
];

export default headers;
