const appVersion = '1.0.0.1';
const buildVersion = 'P618';

// console.log(process.env)

const title = process.env.REACT_APP_TITLE;

const config = {
	title: title,
	CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
	API_URL: process.env.REACT_APP_API_URL,
	KAKAO_API_KEY: process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY,
	REACT_APP_BUSINESS_REGISTRATION_INFORMATION_API_KEY: process.env.REACT_APP_BUSINESS_REGISTRATION_INFORMATION_API_KEY,
	appVersion: appVersion == '1.0.0.1' ? process.env.REACT_APP_TYPE : appVersion,
	buildVersion: buildVersion == 'P618' ? new Date().valueOf() : buildVersion,
};

export default config;
