import { saveAs } from 'file-saver';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
// import isBefore from 'dayjs/plugin/isBefore';
// import showNotification from '../components/extras/showNotification';
// import CompanyService from '../services/CompanyService';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { isNumber } from 'lodash';
import React from 'react';
import NOTIFICATIONH_CHANNELS from '../pages/common/Notifications/notification_channels';
import { promotionType } from '../components/types/promotion.types';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

// dayjs.extend(isBefore);

// export function calculateWorkingHours(startFromTime, startToTime, breakTime) {
//     const start = dayjs(`2024-01-01 ${startFromTime}`);
//     let end = dayjs(`2024-01-01 ${startToTime}`);

//     // start보다 end가 작다면 end를 +1일로 설정
//     if (end.isBefore(start)) {
//         end = end.add(1, 'day');
//     }

//     let workingMinutes = end.diff(start, 'minute');

//     for (const [breakStart, breakEnd] of breakTime) {
//         let breakStartTime = dayjs(`2024-01-01 ${breakStart}`);
//         let breakEndTime = dayjs(`2024-01-01 ${breakEnd}`);

//         // 휴게 시간의 종료 시간이 시작 시간보다 이전이면 종료 시간을 +1일로 설정
//         if (breakEndTime.isBefore(breakStartTime)) {
//             breakEndTime = breakEndTime.add(1, 'day');
//         }

//         // 근무 시간이 휴게 시간과 겹치는 경우 겹치는 부분을 계산하여 제외
//         const overlapStart = dayjs.max(start, breakStartTime);
//         const overlapEnd = dayjs.min(end, breakEndTime);

//         if (overlapStart.isBefore(overlapEnd)) {
//             workingMinutes -= overlapEnd.diff(overlapStart, 'minute');
//         }
//     }

//     // 8시간 기준으로 소수점 형식으로 변환
//     const workingHours = workingMinutes / 480;
//     return workingHours.toFixed(2); // 소수점 두 자리로 표시
// }

/**
 * 사용자 개수
 * @param {*} data 
 * @returns 
 */
export const getUniqueCount = (data) => {
	const uniqueUserIds = new Set(data.map(({ userId }) => userId));
	return uniqueUserIds.size;
};

/**
* @typedef {Obejct} calculateRestInfoPromotionRemainingDaysResult - 결과
* @property {string} promoType - 촉진 타입
* @property {number} availableDayOff - 사용 가능 휴가 일수
* @property {number} usedDays - 사용 일수
* @property {number} remainingDays - 남은 일수
* @property {string[]} promoRange - 촉진 기간
*/

/**
 * 연차촉진 연차 일자 계산산
 * @param {*} userRestInfo - 사용자 휴가 정보
 * @param {import('../components/types/promotion.types').promotionType} promoType - 촉진 타입
 * @returns {calculateRestInfoPromotionRemainingDaysResult} result - 결과
 */
export function calculateRestInfoPromotionRemainingDays(userRestInfo, promoType) {
	
	/** @param {calculateRestInfoPromotionRemainingDaysResult} result */
	let result = {
		promoType: promoType,
		promoRange: ['', ''],
		availableDayOff: 0,
		usedDays: 0,
		remainingDays: 0,
	}

	switch(promoType) {
		case promotionType.YEAR : // 12
			result.availableDayOff = userRestInfo?.availableDayOff || 0;
			result.usedDays = userRestInfo?.usedDays || 0;
			result.promoRange = [dayjs(userRestInfo?.startDate).format('YYYY-MM-DD'), dayjs(userRestInfo?.endDate).format('YYYY-MM-DD')];
			break;
		case promotionType.FORMER : // 9
			result.availableDayOff = userRestInfo?.yearsInfo?.reduce((pre, cur, curIndex) => {
				if (curIndex <= 9) {
					return parseFloat(pre) + parseFloat(cur?.generation || 0);
				} else {
					return parseFloat(pre);
				}
			}, 0);
			result.promoRange = [dayjs(userRestInfo?.startDate).format('YYYY-MM-DD'), dayjs(userRestInfo?.yearsInfo[userRestInfo?.yearsInfo?.length - 3]?.e).format('YYYY-MM-DD')];
			result.availableDayOff = parseFloat(result.availableDayOff) + parseFloat(userRestInfo?.modification || 0);
			result.usedDays = (result.availableDayOff - 2) >= (userRestInfo?.usedDays || 0) ? (userRestInfo?.usedDays || 0) - 2 : (userRestInfo?.usedDays || 0);
			break;
		case promotionType.LATTER : // 2일분은 2개월치에 대한 개수로, 앞에서 차감 후 남은 일수로 계산하면 맞을듯? 가감일수..ㅂㄷ
			result.availableDayOff = userRestInfo?.yearsInfo?.reduce((pre, cur, curIndex) => {
				if (curIndex > 9) {
					return parseFloat(pre) + parseFloat(cur?.generation || 0);
				} else {
					return parseFloat(pre);
				}
			}, 0);
			result.promoRange = [dayjs(userRestInfo?.yearsInfo[userRestInfo?.yearsInfo?.length - 2]?.s).format('YYYY-MM-DD'), dayjs(userRestInfo?.yearsInfo[userRestInfo?.yearsInfo?.length - 1]?.e).format('YYYY-MM-DD')];
			result.usedDays = (userRestInfo?.remainingDays || 0) >= result.availableDayOff ? 0 : (userRestInfo?.remainingDays || 0);
			break;
	}

	result.remainingDays = parseFloat(result.availableDayOff) - parseFloat(result.usedDays);

	return result;
}

export function userTypeToTable(userType) {
	switch (userType) {
		case 'ADMIN':
			return 'adminUser';
		case 'SYSTEM':
			return 'systemUser';
		case 'USER':
			return 'hrUser';
		default:
			return 'hrUser';
	}
}

export function replaceMessageClear(message) {
	return message.replace(/\{\{.+?\}\}/g, '');
}

// 기준일자에 따른 적용시작일, 종료일, 기준년도 계산
/**
 * 
 * @param {*} option 설정
 * @param {*} joinDate (적용)입사일자
 * @param {*} date 기준일자
 * @returns {
 * applyStartDate: 적용시작일,
 * appplyEndDate: 적용종료일,
 * year: 기준년도
 * workYear: 근속년수
 * }
 */
export function getApplyDateByOption(option, joinDate, date) {

	const annLeaveCrit = option?.annLeaveCrit; // true 회계연도 false 입사일자
	const restDate = option?.accrualMonth || '01';

	let applyStartDate = null;
	if (annLeaveCrit === 'A015') {
		applyStartDate = dayjs(date, 'YYYY-MM-DD').year() + '-' + restDate.toString().padStart(2, "0") + "-01";
	} else {
		if (dayjs(joinDate).format('MM-DD') > dayjs(date, 'YYYY-MM-DD').format('MM-DD')) {
			applyStartDate = dayjs(date, 'YYYY-MM-DD').subtract(1, 'year').year() + '-' + dayjs(date, 'YYYY-MM-DD').format('MM-DD');
		} else {
			applyStartDate = dayjs(date, 'YYYY-MM-DD').year() + '-' + dayjs(date, 'YYYY-MM-DD').format('MM-DD');
		}
	}
	const appplyEndDate = dayjs(applyStartDate, 'YYYY-MM-DD').add(1, 'year').subtract(1, 'day').format('YYYY-MM-DD');
	const year = dayjs(applyStartDate, 'YYYY-MM-DD').year();
	const workYear = calculateWorkYears(joinDate, date, annLeaveCrit);

	return { applyStartDate, appplyEndDate, year, workYear };
}

// 근속년수 계산
export function calculateWorkYears(joinDate, toDate, basis = 'A010') {
	const startDate = dayjs(joinDate);
	const referenceDate = dayjs(toDate);
  
	if (basis === 'A010') {
		// 입사일자 기준 근속년수
		// console.log('입사일자 근속년수', dayjs(startDate).format('YYYY.MM.DD'), dayjs(referenceDate).format('YYYY.MM.DD'), referenceDate.diff(startDate, 'year', true))
		return referenceDate.diff(startDate, 'year', true);
	} else if (basis === 'A015') {
		// 회계연도 기준 근속년수      
		const fiscalYearStart = dayjs(referenceDate).startOf('year');
		const hireYearStart = dayjs(startDate).startOf('year');
		// console.log('회계연도 근속년수', dayjs(hireYearStart).format('YYYY.MM.DD'), dayjs(fiscalYearStart).format('YYYY.MM.DD'), fiscalYearStart.diff(hireYearStart, 'year'))
		return fiscalYearStart.diff(hireYearStart, 'year');
	} else {
		throw new Error("입사년도, 회계연도 외의 기준은 지원하지 않습니다");
	}
  }

export function isConvertTimeToFloat(value, type = 'RATIO', decimal = 3) {
	try {

		// if(!isNumber(value)) return 0;

		// if(String(value).includes('.')) return value;
		// const [days, hours] = value?.toString()?.split('.');
		const [days, hours] = value?.toString()?.includes('.') ? value?.toString().split('.') : [value, 0];

		if (type === 'TIME') {
			if (parseInt(hours) > 7) {
				alert('시간은 7시간 이하로 입력해주세요.');
				// console.log('시간은 7시간 이하로 입력해주세요.', parseFloat(`${days}.7`));
				return parseFloat(`${days}.7`);
			}
		} else {
			if (hours?.toString()?.length > decimal) {
				alert(`소수점은 ${decimal}자리 이하로 입력해주세요.`);
				return parseFloat(value).toFixed(decimal);
			}
		}
		// console.log('isConvertTimeToFloat !!! ', value);
		return value;
	} catch (error) {
		// console.log('isConvertTimeToFloat err !!! ', value);
		return value;
	}
}

// convert float to time type = 'TIME' = .7 (7시간), type = 'RATIO' = 그대로 toFixed(decimal)
// float 시간으로 변환
export function convertFloatToTime(value, type='RATIO', decimal=3, isNull=false) {

	// console.log('convertFloatToTime >>>>>>>>>>>>>> ', value, type, decimal);

	if(!isNumber(value)) return value;
	if(value === 0 && isNull) return "";

	// console.log('convertFloatToTime', value, type, de
	// cimal);

	try {
		if (type === 'TIME') {
			// 1.0 = 8시간 > 0.125 = 1시간 > 0.125 를 0.1 로, 0.25 를 0.2 로 변경

			const [days, hours] = value?.toString()?.includes('.') ? value?.toString().split('.') : [value, 0];
			const hoursInADay = 8;
			// console.log('convertFloatToTime', value, days, parseFloat('0.'+hours), hoursInADay);

			const totalHours = parseFloat('0.'+parseFloat(hours || 0) * parseFloat(hoursInADay));
			// console.log('totalHours', parseFloat(totalHours));

			const lastValue = (days >= 0 ? parseFloat(days) + parseFloat(totalHours) : parseFloat(days) - parseFloat(totalHours));
			// console.log('last 비율 > 시간 A > ', value, ' -> ', lastValue);
			return isNaN(lastValue) ? 0 : lastValue;

			// console.log('convertFloatToTime', value, hoursInADay);

			// const totalHours = value * hoursInADay;

			// console.log('totalHours', totalHours);

			// const days = Math.floor(totalHours / hoursInADay);

			// console.log('days', days);

			// const remainingHours = totalHours % hoursInADay;

			// console.log('remainingHours', remainingHours);

			// const convertedValue = `${days}.${remainingHours}`;

			// console.log('convertedValue', convertedValue);

			// return convertedValue;
		} else {
			return parseFloat(value).toFixed(decimal);
		}
	} catch (error) {
		return value;
	}
}

// 시간 다시 float 변환
export function convertTimeToFloat(value, type='RATIO', decimal=3) {

	// console.log('convertTimeToFloat >>>>>>> ', value, type, decimal);
	// if(!isNumber(value)) return 0;

	try {
		if (type === 'TIME') {
			// if(!String(value).includes('.')) return value;
			// const [days, hours] = value?.toString().split('.');
			const [days, hours] = value?.toString()?.includes('.') ? value?.toString().split('.') : [value, 0];
			// console.log('convertTimeToFloat > ', value, value?.toString().split('.'), days, hours);

			const hoursInADay = 8;

			const totalHours = ((parseInt(hours) || 0) / hoursInADay);
	
			// console.log('totalHours > ', totalHours);
			// const totalHours = parseInt(days) * hoursInADay + (parseInt(hours) || 0);

			const lastValue = (days >= 0 ? parseFloat(days) + parseFloat(totalHours) : parseFloat(days) - parseFloat(totalHours));
			// console.log('last 시간 > 비율 B > ', value , ' -> ', lastValue);

			return isNaN(lastValue) ? 0 : lastValue;
		} else {
			return parseFloat(value).toFixed(decimal);
		}
	} catch (error) {
		return value;
	}
}


// const values = [1.0, 0.125, 0.5, 2.5, 25, 22.5, 10.125]; // 테스트 입력값
// values.forEach(value => {
// 	const converted = convertFloatToTime(value, 'TIME', 3);
// 	console.log(`입력: ${value}, 변환 결과: ${converted}시간`);
// 	const converted2 = convertTimeToFloat(converted, 'TIME');
// 	console.log(`입력: ${converted}, 변환 결과: ${converted2}`);
// });

export const searchVacationRestRows = (restCodeList, restType, code, codeId) => {
	// console.log('restType', restType, code);
	switch (restType) {
		case 'REST':
		case 'COMPASSIONATE':
		case 'VACATION':

			let findCode = restCodeList.find((item) => item.code === 'A010')?.children?.find((item) => item.id === codeId);
			if(!findCode) {
				findCode = restCodeList.find((item) => item.code === 'A015')?.children?.find((item) => item.id === codeId);
			}
			if(findCode) {
				return findCode;
			} else {
				return null;
			}
			// return restCodeList.find((item) => item.code === 'A010')?.children?.find((item) => item.id === codeId)?.name || '연차휴가';
			// return restCodeList.find((item) => item.code === 'A015')?.children?.find((item) => item.code === code)?.name || '연차휴가';
		default:
			return null;
	}
};

export function isDateInRange(dataArray, searchStartDate, searchEndDate) {
	if (!searchStartDate || !searchEndDate) return []; // 검색 조건이 없으면 빈 배열 반환

	return dataArray?.filter(item => {
		if (!item.startDate || !item.endDate) return false; // 빈 값 체크

		const startDate = dayjs(item.startDate);
		const endDate = dayjs(item.endDate);
		const searchStart = dayjs(searchStartDate);
		const searchEnd = dayjs(searchEndDate);

		// 검색 조건에 맞는 항목 필터링
		return (
		(searchStart.isSameOrAfter(startDate) && searchStart.isSameOrBefore(endDate)) || // searchStartDate가 범위 내
		(searchEnd.isSameOrAfter(startDate) && searchEnd.isSameOrBefore(endDate)) ||     // searchEndDate가 범위 내
		(searchStart.isBefore(startDate) && searchEnd.isAfter(endDate))                 // 범위를 완전히 포함
		);
	});
  }

export function timeForToday(value) {

	if(!value) return '';

	const today = new Date();
	const timeValue = new Date(value);

	const betweenTime = Math.floor((today.getTime() - timeValue.getTime()) / 1000 / 60);
	if (betweenTime < 1) return '방금전';
	if (betweenTime < 60) {
		return `${betweenTime}${'분전'}`;
	}

	const betweenTimeHour = Math.floor(betweenTime / 60);
	if (betweenTimeHour < 24) {
		return `${betweenTimeHour}${'시간전'}`;
	}

	return dayjs(timeValue).format('YYYY-MM-DD');

	// const betweenTimeDay = Math.floor(betweenTime / 60 / 24);
	// if (betweenTimeDay < 365) {
	// 	return `${betweenTimeDay}일전`;
	// }

	// return `${Math.floor(betweenTimeDay / 365)}년전`;
}

export function getCompanyCode(code) {
	switch (code) {
		case 'AAAA':
			return '공통';
		default:
			return code;
	}
}

export function calculateDecimalTime(timeRange) {

	// TODO: startTime 보다 endTime이 작다면 endTime을 다음날로 설정
	
	const [startTime, endTime] = timeRange;
	const start = dayjs(`2000-01-01 ${startTime}`); // 날짜는 동일하게 임의로 설정
	let end = dayjs(`2000-01-01 ${endTime}`);
	if(end.isBefore(start)) {
		end = end.add(1, 'day');
	}
	// const end = dayjs(`2000-01-01 ${endTime}`);

	const diffInMinutes = end.diff(start, 'minute'); // 분 단위 시간 차이 계산
	const hours = diffInMinutes / 60; // 시간 단위로 변환
	const decimalTime = hours / 8; // 8시간 기준으로 소수점 변환

	return decimalTime;
}

export function convertToDaysHoursMinutes(decimal) {
	const totalMinutes = decimal * 8 * 60; // 1일을 8시간 기준으로 분으로 환산
	const days = Math.floor(totalMinutes / (8 * 60));
	const remainingMinutesAfterDays = totalMinutes % (8 * 60);
	const hours = Math.floor(remainingMinutesAfterDays / 60);
	const minutes = Math.round(remainingMinutesAfterDays % 60);

	let result = '';
	if (days >= 0) result += `${days}일 `;
	if (hours > 0) result += `${hours}시간 `;
	if (minutes > 0) result += `${minutes}분`;

	return result.trim(); // 마지막 공백 제거
}

export function convertTodaysSecondsToTime(seconds) {
	// const hours = Math.floor(seconds / 360);
	const minutes = Math.floor((seconds % 360) / 60);
	const remainingSeconds = seconds % 60;

	let result = '';
	// if (hours > 0) result += `${hours?.toString().padStart(2,'0')}:`;
	if (minutes > 0) result += `${minutes?.toString().padStart(1,'0')}: `;
	else result += `0: `;
	if (remainingSeconds > 0) result += `${remainingSeconds?.toString().padStart(2, '0')}`;
	else result += `00`;

	return result.trim(); // 마지막 공백 제거
}

export function toggleCollapsedById(data, targetId) {
	// 재귀적으로 트리를 탐색하여 해당 ID를 찾음
	function toggleCollapsedHelper(node) {
		if (node.id === targetId) {
			node.collapsed = !node.collapsed; // collapsed 값을 토글
			return true; // 해당 노드를 찾았음을 표시
		}
		if (node.children) {
			for (const childNode of node.children) {
				if (toggleCollapsedHelper(childNode)) {
					return true; // 자식 노드에서 찾았으면 반환
				}
			}
		}
		return false; // 해당 ID를 찾지 못함
	}

	// 루트 노드부터 시작하여 탐색
	for (const node of data) {
		if (toggleCollapsedHelper(node)) {
			break; // 해당 ID를 찾았으므로 반복 종료
		}
	}

	return data; // 변경된 트리 데이터 반환
}

export function getCompanyStatus(status) {
	switch (status) {
		case 'WAIT':
			return '계약대기';
		case 'CONTRACT':
			return '계약중';
		case 'TERMINATION':
			return '계약해지';
		default:
			return '';
	}
}

export function getCodeTypeName(type) {
	switch (type) {
		case 'S':
			return '시스템';
		case 'Y':
			return '연차휴가';
		case 'H':
			return '일반휴가';
		case 'C':
			return '공통';
		case 'P':
			return '인적정보';
		default:
			return '';
	}
}

export function getDayName(day) {
	const koreanDaysOfWeek = ['일', '월', '화', '수', '목', '금', '토'];
	try {
		return koreanDaysOfWeek[day];
	} catch {
		return '';
	}
}

export function getTextColorByBackgroundColor(hexColor) {
	const c = hexColor.substring(1); // 색상 앞의 # 제거
	const rgb = parseInt(c, 16); // rrggbb를 10진수로 변환
	const r = (rgb >> 16) & 0xff; // red 추출
	const g = (rgb >> 8) & 0xff; // green 추출
	const b = (rgb >> 0) & 0xff; // blue 추출

	const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

	// 색상 선택
	return luma < 127.5 ? 'white' : 'dark'; // 글자색이
}

export function divisionArray(arr, n) {
	const len = arr.length;
	const cnt = Math.floor(len / n) + (Math.floor(len % n) > 0 ? 1 : 0);
	let tmp = [];
	for (var i = 0; i < cnt; i++) {
		tmp.push(arr.splice(0, n));
	}
	return tmp;
}

export function remainingRestsRender(a) {
	let originalRest = parseFloat(a || 0);

	let result = 0;
	let day = 0;
	let hrs = 0;

	result = originalRest < 0 ? Math.abs(originalRest) : originalRest;
	day = Math.floor(result);

	if (result < 1) {
		hrs = result / 0.125;
	} else {
		hrs = (result % Math.floor(result)) / 0.125;
	}

	return `${day}일 ${hrs > 0 ? hrs+'시간':''}`;
}

export function remainingRests(a) {
	let originalRest = parseFloat(a || 0);

	let result = 0;
	let day = 0;
	let hrs = 0;

	result = originalRest < 0 ? Math.abs(originalRest) : originalRest;
	day = Math.floor(result);

	if (result < 1) {
		hrs = result / 0.125;
	} else {
		hrs = (result % Math.floor(result)) / 0.125;
	}

	return { day: day, hrs: hrs };
}

export function convertToYYYYMMDD(dateString) {
	const formattedDate = dayjs(dateString, ['YYYY-MM-DD', 'YYYYMMDD', 'YYYY_MM_DD', 'YYYY/MM/DD', 'MM/DD/YYYY', 'MM-DD-YYYY', 'MM_DD_YYYY', 'YYYY-MM-DD']).format('YYYY-MM-DD');
	return formattedDate;
} 

export const checkMoment = (input, format = 'YYYY-MM-DD') => {
	const formats = ['YYYY-MM-DD', 'YYYYMMDD', 'YYYY_MM_DD', 'YYYY/MM/DD', 'MM/DD/YYYY', 'MM-DD-YYYY', 'MM_DD_YYYY', 'YYYY-MM-DD']; // 이후 계속 추가
	for (let i = 0; i < formats.length; i++) {
		if (dayjs(input, formats[i]).isValid() === true) return dayjs(input, formats[i]).format(format);
	}
	return input;
};

export function s2ab(s) {
	var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
	var view = new Uint8Array(buf); //create uint8array as viewer
	for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
	return buf;
}

// export async function verificationFile(file, validTableData, userCompanyList, companyId, type) {
// 	if (file) {
// 		return new Promise(async (resolve) => {
// 			const xlsx = require('xlsx');
// 			const reader = new FileReader();
// 			let excelKeys = [];
// 			switch (type) {
// 				case 'rest':
// 					// 사용자 검증 / 사용 시작일자 / 사용 종료일자 / 휴가여부
// 					let resRestType = await CompanyService.getRestType(companyId);
// 					let restType = resRestType.data;

// 					reader.onload = (e) => {
// 						let userList = userCompanyList.map((x) => x.user);
// 						let result = true;
// 						let target = e.target.result;
// 						const wb = xlsx.read(target, { type: 'binary' });
// 						const sheetname = wb.SheetNames[0];
// 						let data = validTableData?.length > 0 ? validTableData : xlsx.utils.sheet_to_json(wb.Sheets[sheetname], { raw: false });

// 						const regExpEmail = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;

// 						for (let i = 0; i < data.length; i++) {
// 							// console.log(data[i]['사용 시작일자'], (data[i]['사용 시작일자']).indexOf('-'))
// 							//사용 시작일자 유효성 체크
// 							if (data[i]['사용 시작일자'].indexOf('-') < 0) {
// 								data[i]['사용 시작일자'] = data[i]['사용 시작일자']
// 									.split('/')
// 									.map((x) => (x.length == 1 ? '0' + x : x))
// 									.join('/');
// 								// console.log('A', data[i]['사용 시작일자'])
// 								if (!dayjs(data[i]['사용 시작일자'], 'MM/DD/YY', true).isValid()) {
// 									data[i]['사용 시작일자'] = validTableData?.length > 0 ? data[i]['사용 시작일자'] : dayjs(data[i]['사용 시작일자'], 'MM/DD/YY').format('YYYY-MM-DD');
// 									data[i]['사용 시작일자'] = data[i]['사용 시작일자'].indexOf('#') > -1 ? data[i]['사용 시작일자'] : '#' + data[i]['사용 시작일자'] + '#';
// 									result = false;
// 								} else {
// 									data[i]['사용 시작일자'] = dayjs(data[i]['사용 시작일자'], 'MM/DD/YY').format('YYYY-MM-DD');
// 								}
// 							} else {
// 								// console.log('B')
// 								if (!dayjs(data[i]['사용 시작일자'], 'YYYY-MM-DD', true).isValid()) {
// 									data[i]['사용 시작일자'] = validTableData?.length > 0 ? data[i]['사용 시작일자'] : dayjs(data[i]['사용 시작일자'], 'YYYY-MM-DD').format('YYYY-MM-DD');
// 									data[i]['사용 시작일자'] = data[i]['사용 시작일자'].indexOf('#') > -1 ? data[i]['사용 시작일자'] : '#' + data[i]['사용 시작일자'] + '#';
// 									result = false;
// 								}
// 							}
// 							//사용 종료일자 유효성 체크
// 							if (data[i]['사용 종료일자'].indexOf('-') < 0) {
// 								data[i]['사용 종료일자'] = data[i]['사용 종료일자']
// 									.split('/')
// 									.map((x) => (x.length == 1 ? '0' + x : x))
// 									.join('/');
// 								// console.log('A', data[i]['사용 시작일자'])
// 								if (!dayjs(data[i]['사용 종료일자'], 'MM/DD/YY', true).isValid()) {
// 									data[i]['사용 종료일자'] = validTableData?.length > 0 ? data[i]['사용 종료일자'] : dayjs(data[i]['사용 종료일자'], 'MM/DD/YY').format('YYYY-MM-DD');
// 									data[i]['사용 종료일자'] = data[i]['사용 종료일자'].indexOf('#') > -1 ? data[i]['사용 종료일자'] : '#' + data[i]['사용 종료일자'] + '#';
// 									result = false;
// 								} else {
// 									data[i]['사용 종료일자'] = dayjs(data[i]['사용 종료일자'], 'MM/DD/YY').format('YYYY-MM-DD');
// 								}
// 							} else {
// 								// console.log('B')
// 								if (!dayjs(data[i]['사용 종료일자'], 'YYYY-MM-DD', true).isValid()) {
// 									data[i]['사용 종료일자'] = validTableData?.length > 0 ? data[i]['사용 종료일자'] : dayjs(data[i]['사용 종료일자'], 'YYYY-MM-DD').format('YYYY-MM-DD');
// 									data[i]['사용 종료일자'] = data[i]['사용 종료일자'].indexOf('#') > -1 ? data[i]['사용 종료일자'] : '#' + data[i]['사용 종료일자'] + '#';
// 									result = false;
// 								}
// 							}

// 							const checkValid = (str) => {
// 								str = str.replace(/ /g, ''); // 공백 제거
// 								return str.charAt(0) == '#' && str.charAt(str.length - 1) == '#' ? str.substring(1, str.length - 1) : str; // '#'있다면 제거
// 							};
// 							// 이메일 유효성체크 준비
// 							data[i]['이메일'] = checkValid(data[i]['이메일']);

// 							//성명과 이메일 매칭 체크
// 							let nameUser = userList.find((x) => x.name == data[i]['성명'])?.email;
// 							let emailUser = userList.find((x) => x.email == data[i]['이메일'])?.email;

// 							//이메일 형식 체크
// 							if (data[i]['이메일'].length < 6 || !regExpEmail.test(data[i]['이메일']) || !userList.find((x) => x.email == data[i]['이메일']) || nameUser !== emailUser) {
// 								data[i]['이메일'] = data[i]['이메일'].indexOf('#') > -1 ? data[i]['이메일'] : '#' + data[i]['이메일'] + '#';
// 								result = false;
// 							}

// 							//사용자 유효성 체크
// 							if (!userList.find((x) => x.name == data[i]['성명'].replace(/ /g, ''))) {
// 								data[i]['성명'] = data[i]['성명'].indexOf('#') > -1 ? data[i]['성명'] : '#' + data[i]['성명'] + '#';

// 								// 기존 소스
// 								// data[i]['이메일'] = data[i]['이메일'].indexOf('#') > -1 ?
// 								// data[i]['이메일'] :  '#' + data[i]['이메일'] + '#'
// 								result = false;
// 							}

// 							// 휴가 여부 체크
// 							// 임시로 연차와 반차만 입력 가능하도록.
// 							if (!['연차', '반차'].find((x) => x == data[i]['휴가유형'].replace(/ /g, ''))) {
// 								// 기존 소스 : if (!restType.find(x => x.name == data[i]['휴가유형'].replace(/ /g, ''))) {
// 								data[i]['휴가유형'] = data[i]['휴가유형'].indexOf('#') > -1 ? data[i]['휴가유형'] : '#' + data[i]['휴가유형'] + '#';
// 								result = false;
// 							}
// 						}
// 						resolve({ data: data, result: result });
// 					};
// 					reader.readAsBinaryString(file);
// 					break;

// 				case 'work':
// 					excelKeys = ['name', 'companyNumber', 'date', 'startTime', 'endTime', 'ccCode1', 'ccCode2', 'ccCode3'];
// 					// 근태 업로드
// 					reader.onload = (e) => {
// 						let result = true;
// 						let target = e.target.result;
// 						const wb = xlsx.read(target, { type: 'binary' });
// 						const sheetname = wb.SheetNames[0];
// 						let data = validTableData?.length > 0 ? validTableData : xlsx.utils.sheet_to_json(wb.Sheets[sheetname], { raw: false, defval: ' ' }); // 데이터가 없는 셀의 기본값 공백(' ')으로 설정

// 						console.log('work data', data);

// 						for (let i = 0; i < data.length; i++) {
// 							if (Object.keys(data[i]).includes('공사현장')) {
// 								result = false;
// 								return resolve({ msg: 'notMatchingType' });
// 							}
// 							// 컬럼별 validation
// 							if (!dayjs(convertToYYYYMMDD(data[i]['일자']), 'YYYY-MM-DD', true).isValid()) {
// 								data[i]['일자'] = validTableData?.length > 0 ? data[i]['일자'] : dayjs(convertToYYYYMMDD(data[i]['일자']), 'YYYY-MM-DD').format('YYYY-MM-DD');
// 								data[i]['일자'] = data[i]['일자'].indexOf('#') > -1 ? data[i]['일자'] : '#' + data[i]['일자'] + '#';
// 								result = false;
// 							} else {
// 								data[i]['일자'] = convertToYYYYMMDD(data[i]['일자']);
// 							}
// 							// 근태CC 1,2,3 validation -> 세 컬럼 중 한 컬럼의 값만 존재해야 함
// 							let count = 0;
// 							for (let key in data[i]) {
// 								if (['근태CC 1', '근태CC 2', '근태CC 3'].includes(key) && !!data[i][key]?.trim()) {
// 									count++;
// 									if (count > 1) {
// 										data[i]['근태CC 1'] = data[i]['근태CC 1'].indexOf('#') > -1 ? data[i]['근태CC 1'] : '#' + data[i]['근태CC 1'] + '#';
// 										data[i]['근태CC 2'] = data[i]['근태CC 2'].indexOf('#') > -1 ? data[i]['근태CC 2'] : '#' + data[i]['근태CC 2'] + '#';
// 										data[i]['근태CC 3'] = data[i]['근태CC 3'].indexOf('#') > -1 ? data[i]['근태CC 3'] : '#' + data[i]['근태CC 3'] + '#';
// 										result = false;
// 										continue;
// 									}
// 								}
// 							}

// 							console.log('>>', data[i]['근무 시작시간'], dayjs(data[i]['근무 시작시간'], 'HH:mm', true).isValid());

// 							// 231228 근무 시작 시간이 HH:mm, H:mm 두 형식에 맞게 들어오지 않을때, #을 붙여서 업로드 방지하도록 유효성 체크 추가
// 							if (!dayjs(data[i]['근무 시작시간'], 'HH:mm', true).isValid() && !dayjs(data[i]['근무 시작시간'], 'H:mm', true).isValid()) {
// 								data[i]['근무 시작시간'] = validTableData?.length > 0 ? data[i]['근무 시작시간'] : dayjs(data[i]['근무 시작시간'], 'H:mm').format('HH:mm');
// 								// H:mm을 HH:mm으로 형식을 바꿨는데도 불구하고, Valid 오류가 발생하게 된다면, 그때 result를 false로 처리
// 								// 여기서 처리를 하더라도, backend 로직에서 시간 type이 맞지 않는다면 attend save시 exception이 발생함.
// 								if (!dayjs(data[i]['근무 시작시간'], 'HH:mm', true).isValid() && !dayjs(data[i]['근무 시작시간'], 'H:mm', true).isValid()) {
// 									data[i]['근무 시작시간'] = data[i]['근무 시작시간'].indexOf('#') > -1 ? data[i]['근무 시작시간'] : '#' + data[i]['근무 시작시간'] + '#';
// 									result = false;
// 								}
// 							}
// 							// 근무 시작 시간과 동일하게 변경
// 							if (!dayjs(data[i]['근무 종료시간'], 'HH:mm', true).isValid() && !dayjs(data[i]['근무 종료시간'], 'H:mm', true).isValid()) {
// 								data[i]['근무 종료시간'] = validTableData?.length > 0 ? data[i]['근무 종료시간'] : dayjs(data[i]['근무 종료시간'], 'H:mm').format('HH:mm');
// 								if (!dayjs(data[i]['근무 종료시간'], 'HH:mm', true).isValid() && !dayjs(data[i]['근무 종료시간'], 'H:mm', true).isValid()) {
// 									data[i]['근무 종료시간'] = data[i]['근무 종료시간'].indexOf('#') > -1 ? data[i]['근무 종료시간'] : '#' + data[i]['근무 종료시간'] + '#';
// 									result = false;
// 								}
// 							}
// 						}
// 						if (validTableData?.length > 0 && result == true) {
// 							for (let i = 0; i < data.length; i++) {
// 								let updatedKeyData = {};
// 								excelKeys.forEach((key, index) => {
// 									updatedKeyData[key] = data[i][Object.keys(data[i])[index]].trim();
// 								});
// 								updatedKeyData['rowNum'] = i.toString();
// 								updatedKeyData['ccCode'] = !!updatedKeyData['ccCode1'] ? updatedKeyData['ccCode1'] : !!updatedKeyData['ccCode2'] ? updatedKeyData['ccCode2'] : updatedKeyData['ccCode3'];
// 								data[i] = updatedKeyData;
// 							}
// 						}

// 						console.log('work data >> ', data);

// 						resolve({ data: data, result: result });
// 					};
// 					reader.readAsBinaryString(file);
// 					break;
// 				case 'constr':
// 					excelKeys = ['name', 'companyNumber', 'date', 'constrSite', 'constrRate', 'constrRank', 'constrType', 'constrYear', 'constrGrade', 'constrMgr'];
// 					// 공사수당 업로드
// 					reader.onload = (e) => {
// 						let result = true;
// 						let target = e.target.result;
// 						const wb = xlsx.read(target, { type: 'binary' });
// 						const sheetname = wb.SheetNames[0];
// 						let data = validTableData?.length > 0 ? validTableData : xlsx.utils.sheet_to_json(wb.Sheets[sheetname], { raw: false, defval: ' ' }); // 데이터가 없는 셀의 기본값 공백(' ')으로 설정

// 						for (let i = 0; i < data.length; i++) {
// 							if (Object.keys(data[i]).includes('근무 시작시간')) {
// 								result = false;
// 								return resolve({ msg: 'notMatchingType' });
// 							}
// 							// 컬럼별 validation
// 							if (!dayjs(data[i]['일자'], 'YYYY-MM-DD', true).isValid()) {
// 								data[i]['일자'] = validTableData?.length > 0 ? data[i]['일자'] : dayjs(data[i]['일자'], 'YYYY-MM-DD').format('YYYY-MM-DD');
// 								data[i]['일자'] = data[i]['일자'].indexOf('#') > -1 ? data[i]['일자'] : '#' + data[i]['일자'] + '#';
// 								result = false;
// 							}
// 						}
// 						if (validTableData?.length > 0 && result == true) {
// 							for (let i = 0; i < data.length; i++) {
// 								let updatedKeyData = {};
// 								excelKeys.forEach((key, index) => {
// 									updatedKeyData[key] = data[i][Object.keys(data[i])[index]].trim();
// 								});
// 								updatedKeyData['rowNum'] = i.toString();
// 								data[i] = updatedKeyData;
// 							}
// 						}
// 						resolve({ data: data, result: result });
// 					};
// 					reader.readAsBinaryString(file);
// 					break;
// 				default:
// 					// 직원등록,직원초대 유효성 체크
// 					//휴대폰번호 / 입사일자 / 이메일
// 					console.log('default');
// 					reader.onload = (e) => {
// 						let target = e.target.result;
// 						const wb = xlsx.read(target, { type: 'binary' });
// 						const sheetname = wb.SheetNames[0];
// 						let data = validTableData?.length > 0 ? validTableData : xlsx.utils.sheet_to_json(wb.Sheets[sheetname], { raw: false });

// 						// const patternPhone = new RegExp("01[016789]-[0-9]{2,3}-[0-9]{3,4}");
// 						//const patternPhone = new RegExp("01[016789]-[^0][0-9]{2,3}-[0-9]{3,4}");
// 						const patternPhone = /01[016789]-[0-9]{3,4}-[0-9]{4}/;
// 						const regExpEmail = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
// 						let emails = [];
// 						let result = true;

// 						for (let i = 0; i < data.length; i++) {
// 							let verificationData = {};
// 							//휴대폰 번호 형식
// 							if (!patternPhone.test(data[i]['휴대폰번호'])) {
// 								data[i]['휴대폰번호'] = data[i]['휴대폰번호'].indexOf('#') > -1 ? data[i]['휴대폰번호'] : '#' + data[i]['휴대폰번호'] + '#';
// 								result = false;
// 							}
// 							//입사일자 유효성 체크
// 							//MM/DD/YY

// 							// 이메일 유효성체크 준비
// 							let email = data[i]['이메일'].replace(/ /g, ''); // 공백 제거
// 							//이메일 중복 체크

// 							if (emails.find((x) => x === email)) {
// 								data[i]['이메일'] = '중복';
// 								result = false;
// 							} else {
// 								emails.push(email);
// 							}
// 							//이메일 형식 체크
// 							if (email.length < 6 || !regExpEmail.test(email)) {
// 								data[i]['이메일'] = data[i]['이메일'].indexOf('#') > -1 ? data[i]['이메일'] : '#' + data[i]['이메일'] + '#';
// 								result = false;
// 							}
// 						}
// 						resolve({ data: data, result: result });
// 					};
// 					reader.readAsBinaryString(file);
// 					break;
// 			}
// 		});
// 	} else {
// 		showNotification('유효성 검사', '파일이 존재하지 않습니다.', 'danger');
// 		return false;
// 	}
// }

export async function excelDownload(jsonDataList, key, options) {
	const xlsx = require('xlsx');
	let workSheetDatas = [];
	let sheetName;
	// console.log('jsonDataList >> ', jsonDataList);
	switch (key) {
		case 'userList':
			sheetName = '사원정보';
			jsonDataList.map((x) => {
				let attnedType = [];
				x?.group?.area?.gps && attnedType.push('GPS');
				x?.secomNumber && attnedType.push('출입연동');
				x?.group?.area?.wifi && attnedType.push('WIFI');

				let deviceModel = [];
				deviceModel = x.device.map((y) => y.os);
				deviceModel = new Set(deviceModel);
				deviceModel = [...deviceModel];

				let state = '';
				switch (x.state) {
					case 'SUCCESS':
						state = '재직';
						break;
					case 'LEAVE':
						state = '퇴사';
						break;
					case 'REST':
						state = '휴직';
						break;
					case 'REQUEST':
						state = '가입 요청';
						break;
					case 'DENY':
						state = '요청 거절';
						break;
				}

				let depart = x.departments?.find((x) => x.main);

				let workSheetData = {
					성명: x.user?.name || '',
					사번: x.companyNumber || '',
					이메일: x.user?.email || '',
					휴대폰: x.user?.phone || '',
					부서: depart?.department?.name,
					소속그룹: x.group?.name || '',
					직위: x.rank?.name || '',
					직책: x.position?.name || '',
					인증방식: attnedType?.join('/') || '',
					연차: `${((x.restInfo?.rest || 0) - (x.restInfo?.userest || 0)).toFixed(1)} / ${(x.restInfo?.rest).toFixed(1)}`,
					권한: x.role?.name || '',
					디바이스: deviceModel?.join('/') || '',
					근무상태: state,
				};
				workSheetDatas.push(workSheetData);
			});
			break;
		default:
			break;
	}
	let wb = xlsx.utils.book_new();
	let ws = xlsx.utils.json_to_sheet(workSheetDatas);
	xlsx.utils.book_append_sheet(wb, ws, sheetName);
	xlsx.writeFile(wb, `${dayjs().format('YYYY-MM-DD')}_${sheetName}.xlsx`);
}

// 글자수 자르기
export function truncateString(str, maxLength) {
	if (str.length > maxLength) {
		return str.slice(0, maxLength) + '...';
	} else {
		return str;
	}
}

export const getLineUser = (approvalList = []) => {
	const userList = [];
	approvalList?.forEach((approval) => {
		approval.line.forEach((user) => {
			userList.push(user.user);
		});
	});
	return userList;
};

export const convertMinsToHrsMins = (mins) => {
	let h = Math.floor(mins / 3600);
	let m = Math.floor((mins % 3600) / 60);
	let s = Math.floor(mins % 60);
	//let h = Math.floor(mins / (60 * 60));
	//let m = mins % (60);
	h = h < 10 ? '0' + h : h;
	m = m < 10 ? '0' + m : m;
	return `${h}:${m}`;
};

export function excelSerialDateToJSDate(excelSerialDate) {
	const daysBeforeUnixEpoch = 70 * 365 + 19;
	const hour = 60 * 60 * 1000;
	return new Date(Math.round((excelSerialDate - daysBeforeUnixEpoch) * 24 * hour) + 12 * hour);
}

export function excelSerialTimeToJSTime(excelSerialDate) {
	// 0.333333333 > 08:00
	return convertMinsToHrsMins(Math.round(excelSerialDate * (24 * 60 * 60)));
}

// 배열 > 오브젝트
export function convertArrayToObject(arr) {
	const result = {};
	for (const item of arr) {
		result[item] = '';
	}
	return result;
}

// 코드값 ++
export function generateValue(startValue) {
	const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
	let alphaIndex = alphabet.indexOf(startValue[0]);
	let numericValue = parseInt(startValue.substring(1));

	// 알파벳이 있을 경우
	if (alphaIndex !== -1) {
		// 값이 995 이후에는 알파벳을 변경하고 숫자를 0으로 초기화
		if (numericValue >= 995) {
			alphaIndex = (alphaIndex + 1) % 26;
			numericValue = 0;
		} else {
			// 아닐 경우에는 숫자를 5씩 증가
			numericValue += 5;
		}
	} else {
		// 숫자값만 있을 경우에는 5씩 증가
		numericValue += 5;
	}

	// 1000을 초과할 경우 0으로 초기화
	if (numericValue >= 1000) {
		numericValue = numericValue % 1000;
	}

	// 다음 알파벳과 숫자를 합쳐서 반환
	const nextAlpha = alphaIndex !== -1 ? alphabet[alphaIndex] : '';
	const nextValue = nextAlpha + numericValue.toString().padStart(3, '0');

	return nextValue;
}

// 사용자 메뉴 제어
export function getMenuWithActionsUser(userMenu, options) {
	// console.log('getMenuWithActionsUser > ', userMenu, options);
	// role.applyVacPromoScheme == false 이면 id 가 dayoffPlan 인거 제외
	// role.useAllowPaySettleDays == false 이면 id 가 payManage 인거 제외

	// 삭제방식은 렌더링 문제가 있으니, 초기 빈메뉴에서 사용가능한 메뉴를 추가하는 방식으로 변경 하자
	// let result = {};
	const role = options?.workPlace?.restdaySetting || {};
	// console.log('role > ', role);

	let result = userMenu;
	// console.log('userMenu > ', result);

	if (role?.applyVacPromoScheme == false) { // 연차 휴가 사용촉진제
		delete result['dayoffPlan'];
	}
	if (role?.useAllowPaySettleDays == false) { // 수당지급 정산
		delete result['payManage'];
	}
	// if (options?.isApproval == false) { // 결재자여부?
	if(role?.vacAuth == 'A015' || options?.isApproval == false) {
		delete result['dayoffApproval'];
		delete result['dayoffApprovalDelegation'];
	}
	return result;
}

// 메뉴 제어
export function getMenuWithActions(adminMenu, options) {

	const result = {};
	const role = options?.role?.role;
	// console.log('getMenuWithActions > ', adminMenu, role);

	function checkRole(roleSection, id) {
		try {
			// console.log('checkRole > ', roleSection?.id, id);
			if (roleSection?.id === id && roleSection?.isUse && roleSection?.action?.includes('A010')) {
				// console.log('checkRole > ', roleSection?.id, id);
				return true;
			}
			return roleSection[id] && roleSection[id]?.isUse && roleSection[id]?.action?.includes('A010');
		} catch (e) {
			return false;
		}
	}

	function traverse(menu, roleMneu, parentKey = null) {

		if(!menu) return;
		if(!roleMneu) return;

		for (const key in menu) {

			if (menu[key].subMenu) {
				traverse(menu[key].subMenu, role[key]?.subMenu, key);
				continue;
			}

			const adminMenuItem = menu[key];

			if (adminMenuItem) {

				if (checkRole(roleMneu, menu[key].id)) {
					if(parentKey) {
						if (!result[parentKey]) {
							result[parentKey] = { ...menu[parentKey], id: '', icon: '', text: '', path: '', subMenu: {} };
						}
						result[parentKey].id = adminMenu[parentKey].id;
						result[parentKey].icon = adminMenu[parentKey].icon;
						result[parentKey].text = adminMenu[parentKey].text;
						result[parentKey].path = adminMenu[parentKey].path;
						result[parentKey].subMenu[key] = menu[key];
					} else {
						result[key] = menu[key];
					}
				}
			}
		}
	}

	traverse(adminMenu, role);
	// console.log('result > ', result);
	return result;
}

// 파일 크기
export function returnFileSize(number) {
	try {
		if (number < 1024) {
			return number + 'bytes';
		} else if (number > 1024 && number < 1048576) {
			return (number / 1024).toFixed(1) + 'KB';
		} else if (number > 1048576) {
			return (number / 1048576).toFixed(1) + 'MB';
		}
	} catch {
		return null;
	}
}

// 알림 종류
export const defaultAlarmSetting = [
	{
		code: 'A010',
		name: '연차수당 요청',
		children: Object.values(NOTIFICATIONH_CHANNELS)
	},
	{
		code: 'A015',
		name: '연차수당 통보',
		children: Object.values(NOTIFICATIONH_CHANNELS)
	},
	{
		code: 'A020',
		name: '결재신청',
		children: Object.values(NOTIFICATIONH_CHANNELS)
	},
	{
		code: 'A025',
		name: '결재승인',
		children: Object.values(NOTIFICATIONH_CHANNELS)
	},
	{
		code: 'A030',
		name: '결재반려',
		children: Object.values(NOTIFICATIONH_CHANNELS)
	},
	{
		code: 'A035',
		name: '결재완료',
		children: Object.values(NOTIFICATIONH_CHANNELS)
	},
	{
		code: 'A040',
		name: '결재취소 요청',
		children: Object.values(NOTIFICATIONH_CHANNELS)
	},
	{
		code: 'A045',
		name: '결재권한 위임',
		children: Object.values(NOTIFICATIONH_CHANNELS)
	},
	{
		code: 'A050',
		name: '결재취소 알림',
		children: Object.values(NOTIFICATIONH_CHANNELS)
	},
	{
		code: 'A055',
		name: '연차촉진 알림',
		children: Object.values(NOTIFICATIONH_CHANNELS)
	},
];

export function isValidEmail(email) {
	const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
	return emailRegex.test(email);
}

export function isValidPhoneNumber(phoneNumber) {
	if (!phoneNumber) return false;
	const regex = /^[+\-]?[0-9]+[0-9+\-]*$/;
	return regex.test(phoneNumber);
}

const createMarkup = (html) => ({ __html: html });
export function splitTags (content) {
  return <div dangerouslySetInnerHTML={createMarkup(content)} />;
};