import React, { useState } from 'react';
import { useMeasure } from 'react-use';
import classNames from 'classnames';
import useDarkMode from '../../hooks/useDarkMode';
import config from '../../config';
import { useMst } from '../../models';
import Modal, { ModalBody } from '../../components/bootstrap/Modal';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const Footer = observer(() => {
	const [ref, { height }] = useMeasure();
	const { user, company, notifiction } = useMst();
	const { t } = useTranslation(['translation', 'menu']);

	const root = document.documentElement;
	root.style.setProperty('--footer-height', `${height}px`);

	const { darkModeStatus } = useDarkMode();

	return (
		<footer ref={ref} className='footer'>
			<div className='container-fluid'>
				<div className='row align-self-center'>
					<div className='col lh-1 align-self-center'>
						<span className='px-2 fw-light col-xs-6 fc-6 text-black-50'>Copyright © 2024-2025 헬로타임.</span>
					</div>
					<div className='col-auto'>
						<div
							className={classNames('text-decoration-none', {
								'link-dark': !darkModeStatus,
								'link-light': darkModeStatus,
							})}></div>
					</div>
				</div>
			</div>
		</footer>
	);
});

export default Footer;
