import React, { useState, useContext, useLayoutEffect, useCallback, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useMeasure, useWindowSize } from 'react-use';
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../../components/bootstrap/OffCanvas';
import ThemeContext from '../../contexts/themeContext';
import Button, { ButtonGroup } from '../../components/bootstrap/Button';
import Portal from '../Portal/Portal';
import useDarkMode from '../../hooks/useDarkMode';
import NotificationList from '../../pages/common/Headers/components/NotificationList';
import NotificationSetting from '../../pages/common/Headers/components/NotificationSetting';
import { useMst } from '../../models';
import { useTranslation } from 'react-i18next';
import AlarmService from '../../services/AlarmService';
import showNotification from '../../components/extras/showNotification';
import Logo from '../../components/Logo_v2';
import Icon from '../../components/icon/Icon';

import Badge from '../../components/bootstrap/Badge';
import config from '../../config';
import { useChannelIOApi } from 'react-channel-plugin';

export const HeaderLeft = ({ children, className }) => {
	return <div className={classNames('header-left', 'col-md', className)}>{children}</div>;
};
HeaderLeft.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};
HeaderLeft.defaultProps = {
	className: null,
};

export const HeaderRight = ({ children, className }) => {
	const [ref, { height }] = useMeasure();

	const root = document.documentElement;
	root.style.setProperty('--header-right-height', `${height}px`);

	return (
		<div ref={ref} className={classNames('header-right', 'col-md-auto', className)}>
			{children}
		</div>
	);
};
HeaderRight.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};
HeaderRight.defaultProps = {
	className: null,
};

const Header = ({ children, hasLeftMobileMenu, hasRightMobileMenu }) => {
	const { themeStatus } = useDarkMode();

	const windowsWidth = useWindowSize().width;
	const [refMobileHeader, sizeMobileHeader] = useMeasure();
	const [refHeader, sizeHeader] = useMeasure();

	const { user, notifiction } = useMst();
	const [offcanvasStatus, setOffcanvasStatus] = useState(false);
	const { t } = useTranslation(['translation', 'menu']);
	const [settingFlag, setSettingFlag] = useState(false);

const { showMessenger, updateUser } = useChannelIOApi();

	const root = document.documentElement;
	root.style.setProperty('--mobile-header-height', `${sizeMobileHeader.height}px`);
	root.style.setProperty('--header-height', `${sizeHeader.height}px`);

	const { asideStatus, setAsideStatus, leftMenuStatus, setLeftMenuStatus, rightMenuStatus, setRightMenuStatus, asideSubStatus, setAsideSubStatus } = useContext(ThemeContext);

	const allReadHandler = useCallback(async () => {
		// console.log('userId, companyId')
		AlarmService.alarmReadAll(user.me.company.code).then((response) => {
			if (response.data) {
				// console.log('response >>',response)
				showNotification('알람', '알람을 모두 읽었습니다.', 'info');
				notifiction.getRefresh(user.me.company.code);
				setOffcanvasStatus(false);
			} else {
				// showNotification('알람','모두읽기 실패.','danger')
			}
		});

	}, [user, notifiction]);

	useLayoutEffect(() => {
		if ((asideStatus || leftMenuStatus || rightMenuStatus) && windowsWidth < process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE) document.body.classList.add('overflow-hidden');
		return () => {
			document.body.classList.remove('overflow-hidden');
		};
	});

	const [logo, setLogo] = useState(<Logo height={17} />);

	// useEffect(() => {
	// 	function validateImageUrl(url) {
	// 		return new Promise((resolve) => {
	// 			const img = new Image();
	// 			img.onload = () => resolve(true); // 로드 성공
	// 			img.onerror = () => resolve(false); // 로드 실패
	// 			img.src = url; // URL 설정
	// 		});
	// 	}
	// 	if (company?.get?.logoFile) {
	// 		validateImageUrl(config.API_URL + '/images' + company.get?.logoFile?.path + '/' + company.get?.logoFile?.fileName).then((res) => {
	// 			if (res) {
	// 				setLogo(
	// 					<img
	// 						src={config.API_URL + '/images' + company.get?.logoFile?.path + '/' + company.get?.logoFile?.fileName}
	// 						srcSet={config.API_URL + '/images' + company.get?.logoFile?.path + '/' + company.get?.logoFile?.fileName}
	// 						alt={company?.get?.name}
	// 						title={company.get?.name || ''}
	// 						style={{ height: '25px', maxWidth: '120px' }}
	// 					// onError={(e) => {
	// 					// 	// e.target.src = ';';
	// 					// }}
	// 					/>
	// 				);
	// 			}
	// 		});
	// 	}
	// }, [company]);

	return (
		<>
			<header ref={refMobileHeader} className='mobile-header bg-white border-bottom border-light'>
				<div className='container-fluid'>
					<div className='d-flex justify-content-between align-items-center'>
						<div>{logo}</div>
						<div>
							<Button type='button' className='p-2' onClick={showMessenger} aria-label='Messenger'>
								<Icon icon='ChannelTolk' size='3x' />
							</Button>
							<Button className='p-2' type='button' onClick={() => setOffcanvasStatus(true)} aria-label='Notifications'>
								<Icon icon={notifiction?.get.fcmCode ? 'Notifications' : 'NotificationsOff'} size='3x' />
							</Button>
							<Button
								className='p-2'
								type='button'
								onClick={() => {
									setAsideStatus(!asideStatus);
									setAsideSubStatus(!asideSubStatus);
									setLeftMenuStatus(false);
									setRightMenuStatus(false);
								}}>
								<Icon icon='Menu' size='3x' />
							</Button>
							<OffCanvas id='notificationCanvas' titleId='offcanvasExampleLabel' placement='end' isOpen={offcanvasStatus} setOpen={setOffcanvasStatus} isBackdrop={true} isBodyScroll={true}>
								<OffCanvasHeader setOpen={setOffcanvasStatus}>
									<OffCanvasTitle className='w-100 d-flex justify-content-end' id='notificationOffCanvasSide'>
										<div className='me-3'>
											<ButtonGroup isToolbar>
												{(notifiction?.get?.badge || 0) > 0 && (
													<Button color='dark' size='sm' onClick={() => allReadHandler()}>
														{'모두 읽음'}
													</Button>
												)}
												{/* <Button
													icon={'GearFill'}
													color='light'
													onClick={(e) => {
														e.stopPropagation();
														// setSettingFlag(!settingFlag);
													}}
												/> */}
											</ButtonGroup>
										</div>
									</OffCanvasTitle>
								</OffCanvasHeader>
								<OffCanvasBody className='border border-top border-light p-0'>
									{/* {settingFlag ? <NotificationSetting /> : <NotificationList />} */}
									<NotificationList setIsOpen={setOffcanvasStatus} />
								</OffCanvasBody>
							</OffCanvas>
						</div>
					</div>
				</div>
			</header>
			<header
				ref={refHeader}
				className={classNames('header', {
					'header-left-open': leftMenuStatus,
					'header-right-open': rightMenuStatus,
				})}>
				<div className='container-fluid'>
					<div className='row d-flex align-items-center'>
						{children}
						{(leftMenuStatus || rightMenuStatus) && (
							<Portal>
								<div
									role='presentation'
									className={classNames('header-overlay', {
										'header-overlay-left-menu': leftMenuStatus,
										'header-overlay-right-menu': rightMenuStatus,
									})}
									onClick={() => {
										setAsideStatus(false);
										setAsideSubStatus(false);
										setLeftMenuStatus(false);
										setRightMenuStatus(false);
									}}
								/>
							</Portal>
						)}
					</div>
				</div>
			</header>
		</>
	);
};
Header.propTypes = {
	children: PropTypes.node.isRequired,
	hasLeftMobileMenu: PropTypes.bool,
	hasRightMobileMenu: PropTypes.bool,
};
Header.defaultProps = {
	hasLeftMobileMenu: true,
	hasRightMobileMenu: true,
};

export default Header;
