import { types } from 'mobx-state-tree';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import { authMenu } from '../menu';
import AuthService from '../services/AuthService';
import { isBoolean } from 'lodash';
import { setItem } from '../lib/localstorage';

const Company = types.model({
	id: types.optional(types.number, 0),
	name: types.optional(types.string, ''),
	code: types.optional(types.string, ''),
	employeeType: types.optional(types.string, ''),		
	featureApplyUnit: types.optional(types.string, ''),
	holidayApplyUnit: types.optional(types.string, ''),
	representativeName: types.optional(types.string, ''),
	platform: types.optional(types.array(types.string), []),
	companyPlatforms: types.optional(types.string, '{}'),
	corporationType: types.optional(types.string, ''),
	corporationDate: types.optional(types.string, ''),
}).views((self) => ({
	get get() {
		try {
			return {
				...self,
				companyPlatforms: JSON.parse(self.companyPlatforms),
			};
		} catch (e) {
			console.log(e);
			// window.location.replace('/' + authMenu.login.path);
		}
	},
}))
.actions((self) => ({
	async setData(data) {
		self.id = data?.id ?? self.id;
		self.name = data?.name || self?.name || '';
		self.code = data?.code || self?.code || '';
		self.employeeType = data?.employeeType || self?.employeeType || '';
		self.featureApplyUnit = data?.featureApplyUnit || self?.featureApplyUnit || '';
		self.holidayApplyUnit = data?.holidayApplyUnit || self?.holidayApplyUnit || '';
		self.representativeName = data?.representativeName || self?.representativeName || '';
		self.platform = data?.platform || self?.platform || [];
		self.companyPlatforms = data?.companyPlatforms ? JSON.stringify(data?.companyPlatforms || {}) : self?.companyPlatforms || '{}';
		self.corporationType = data?.corporationType || self?.corporationType || '';
		self.corporationDate = data?.corporationDate || self?.corporationDate || '';
	},
}));


export const User = types
	.model('User', {
		id: types.optional(types.number, 0),
		companyId: types.optional(types.number, 0),
		license: types.optional(types.string, ''),
		email: types.optional(types.string, ''),
		phone: types.optional(types.string, ''),
		name: types.optional(types.string, ''),
		gender: types.optional(types.string, ''),
		address: types.optional(types.string, ''),
		birth: types.optional(types.string, ''),
		roleId: types.optional(types.number, 0),
		role: types.optional(types.string, '{}'),
		departId: types.optional(types.number, 0),
		depart: types.optional(types.string, '{}'),
		workPlaceId: types.optional(types.number, 0),
		workPlace: types.optional(types.string, '{}'),
		accessRole: types.optional(types.string, ''), // 접근권한 (전체, 소속)
		type: types.optional(types.string, ''), // 신분구분 (내부, 외부)
		rankId: types.optional(types.number, 0),
		rank: types.optional(types.string, '{}'),
		positionId: types.optional(types.number, 0),
		position: types.optional(types.string, '{}'),
		jobGradeId: types.optional(types.number, 0),
		jobGrade: types.optional(types.string, '{}'),
		joinDate: types.optional(types.string, ''),
		applyJoinDate: types.optional(types.string, ''),
		retireDate: types.optional(types.string, ''),
		isDayoff: types.optional(types.boolean, false),
		isRest: types.optional(types.boolean, false),
		applyVacPromoScheme: types.optional(types.boolean, false),
		// workingHour: types.optional(types.string, []),
		isChat: types.optional(types.boolean, false),
		isMailing: types.optional(types.boolean, false),
		isUse: types.optional(types.boolean, false),
		createdAt: types.optional(types.string, ''),
		updatedAt: types.optional(types.string, ''),
		// deletedAt: types.optional(types.string, ''),
		updatedPasswdAt: types.optional(types.string, ''),
		loginAt: types.optional(types.string, ''),
		lockedAt: types.optional(types.string, ''),

		userRestInfos: types.optional(types.string, '[]'),
		isApproval: types.optional(types.boolean, false),
		company: types.optional(Company, {}),

		approvalLines: types.optional(types.string, '[]'),

		isUser: types.optional(types.integer, 0),
		isAdmin: types.optional(types.integer, 0),

	})
	.views((self) => ({
		get me() {
			try {
				return {
					...self,
					role: JSON.parse(self.role),
					userRestInfos: JSON.parse(self.userRestInfos),
					rank: JSON.parse(self.rank),
					depart: JSON.parse(self.depart),
					position: JSON.parse(self.position),
					jobGrade: JSON.parse(self.jobGrade),
					workPlace: JSON.parse(self.workPlace),
					approvalLines: JSON.parse(self.approvalLines),
					company: self.company.get,
					// userCompany: JSON.parse(self.userCompany),
				};
			} catch (e) {
				console.log(e);
				window.location.replace('/' + authMenu.login.path);
			}
		},
	}))
	.actions((self) => ({
		async reset() {
			self.userRestInfos = '[]';
		},

		async authMe() {
			try {
				// self.logout();
				
				const response = await AuthService.me();
				if (response.data) {

					self.reset();
					// self.userRestInfos = JSON.stringify([]);
					self.setData({
						...response.data,
					});
					self.company.setData(response.data.company);

					return response?.data;
				}
				self.logout();
				return null;
				//  else {
				// 	if (window.location.pathname !== authMenu.login.path) {
				// 		// alert('세션이 종료되어 로그아웃 됩니다.');
				// 		window.location.href = '/' + authMenu.login.path;
				// 	}
				// }
			} catch (err) {
				self.logout();
				if (window.location.pathname !== authMenu.login.path) {
					// alert('세션이 종료되어 로그아웃 됩니다.');
					// window.location.replace('/' + authMenu.login.path);
					const query = window.location.pathname + window.location.search;
					// console.log('query', query);
					window.location.replace('/' + authMenu.login.path + (query ? `?redirect=${query}` : ''));
					// window.location.href = '/' + authMenu.login.path;
				}
				return null;
				// if (window.location.pathname !== authMenu.login.path) {
				// 	// alert('세션이 종료되어 로그아웃 됩니다.');
				// 	window.location.href = '/' + authMenu.login.path;
				// }
			}
		},

		async setRank(rank) {
			self.userRank = rank;
		},

		async setDepartment(depart) {
			self.userDepartment = depart;
		},

		async setIsAdmin(isAdmin) {
			self.isAdmin = isAdmin;
		},

		async setOriginalSession(session) {
			self.originalSession = session;
		},

		async setData(data) {
			self.id = data?.id ?? self.id;
			self.companyId = data?.companyId ?? self?.companyId;
			self.license = data?.license || self?.license || '';
			self.email = data?.email || self?.email || '';
			self.phone = data?.phone || self?.phone || '';
			self.name = data?.name || self?.name || '';
			self.gender = data?.gender || self?.gender || '';
			self.address = data?.address || self?.address || '';
			self.birth = data?.birth || self?.birth || '';
			self.roleId = data?.roleId ?? self?.roleId;
			self.role = data?.role ? JSON.stringify(data?.role || {}) : self?.role || '{}';
			self.departId = data?.departId ?? self?.departId;
			self.depart = data?.depart ? JSON.stringify(data?.depart || {}) : self?.depart || '{}';
			self.workPlaceId = data?.workPlaceId ?? self?.workPlaceId;
			self.workPlace = data?.workPlace ? JSON.stringify(data?.workPlace || {}) : self?.workPlace || '{}';
			self.accessRole = data?.accessRole || self?.accessRole || '';
			self.type = data?.type || self?.type || '';
			self.rankId = data?.rankId ?? self?.rankId;
			self.rank = data?.rank ? JSON.stringify(data?.rank || {}) : self?.rank || '{}';
			self.positionId = data?.positionId ?? self?.positionId;
			self.position = data?.position ? JSON.stringify(data?.position || {}) : self?.position || '{}';
			self.jobGradeId = data?.jobGradeId ?? self?.jobGradeId;
			self.jobGrade = data?.jobGrade ? JSON.stringify(data?.jobGrade || {}) : self?.jobGrade || '{}';
			self.joinDate = data?.joinDate || self?.joinDate || '';
			self.applyJoinDate = data?.applyJoinDate || self?.applyJoinDate || '';
			self.retireDate = data?.retireDate || self?.retireDate || '';
			self.isDayoff = isBoolean(data?.isDayoff) ? data.isDayoff : self.isDayoff;
			self.isRest = isBoolean(data?.isRest) ? data.isRest : self.isRest;
			self.applyVacPromoScheme = isBoolean(data?.applyVacPromoScheme) ? data.applyVacPromoScheme : self.applyVacPromoScheme;
			// self.workingHour = data?.workingHour || self?.workingHour || [];
			self.isChat = isBoolean(data?.isChat) ? data.isChat : self.isChat;
			self.isMailing = isBoolean(data?.isMailing) ? data.isMailing : self.isMailing;
			self.isUse = isBoolean(data?.isUse) ? data.isUse : self.isUse;

			self.isUser = data?.isUser ?? self?.isUser;
			self.isAdmin = data?.isAdmin ?? self?.isAdmin;


			self.createdAt = data?.createdAt || self?.createdAt || '';
			self.updatedAt = data?.updatedAt || self?.updatedAt || '';
			// self.deletedAt = data?.deletedAt || self?.deletedAt || '';
			self.updatedPasswdAt = data?.updatedPasswdAt || self?.updatedPasswdAt || '';
			self.loginAt = data?.loginAt || self?.loginAt || '';
			self.lockedAt = data?.lockedAt || self?.lockedAt || '';
			
			self.userRestInfos = data?.userRestInfos ? JSON.stringify(data?.userRestInfos || []) : self?.userRestInfos || '[]';
			self.isApproval = isBoolean(data?.isApproval) ? data.isApproval : self.isApproval;
			// self.company = data?.company ? { ...data?.company } : self?.company || {}

			self.approvalLines = data?.approvalLines ? JSON.stringify(data?.approvalLines || []) : self?.approvalLines || '[]';

		},

		async logout() {
				self.id = 0;
				self.companyId = 0;
				self.license = '';
				self.email = '';
				self.phone = '';
				self.name = '';
				self.gender = '';
				self.address = '';
				self.birth = '';
				self.roleId = 0;
				self.role = '{}';
				self.departId = 0;
				self.depart = '{}';
				self.workPlaceId = 0;
				self.workPlace = '{}';
				self.accessRole = '';
				self.type = '';
				self.rankId = 0;
				self.rank = '{}';
				self.positionId = 0;
				self.position = '{}';
				self.jobGradeId = 0;
				self.jobGrade = '{}';
				self.joinDate = '';
				self.applyJoinDate = '';
				self.retireDate = '';
				self.isDayoff = false;
				self.isRest = false;
				self.applyVacPromoScheme = false;
				// self.workingHour = [];
				self.isChat = false;
				self.isMailing = false;
				self.isUse = false;
				self.createdAt = '';
				self.updatedAt = '';
				// self.deletedAt = '';
				self.updatedPasswdAt = '';
				self.loginAt = '';
				self.lockedAt = '';
				self.userRestInfos = '[]';
				self.isApproval = false;
				self.company = {};

				self.isUser = 0;
				self.isAdmin = 0;

				self.approvalLines = '[]';

				setItem('user-connect-type', '');
		},
	}));

export default User;
