import { APIClient } from '../lib/apiHelper';
const { post, get, put } = new APIClient();

export default {
	// alarm: alarm => get('/alarm?companyId=' + alarm),
	// alarmModify: alarm => put('/alarm', alarm),
	// alarmSetting: alarm => get('/alarm/setting?userId=' + alarm),
	// alarmSettingModify: alarm => put('/alarm/setting', alarm),
	alarmRead: (companyCode, id) => put(`/alarm/read/${companyCode}/${id}`),
	alarmReadAll: (companyCode) => put(`/alarm/read/all/${companyCode}`),
};
