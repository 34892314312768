import React from 'react';
// import { demoPages, layoutMenu, pawsswordFind } from '../menu';
import Footer from '../layout/Footer/Footer';
import { rootStore } from '../models';
import { element } from 'prop-types';
import { authMenu, systemAdminMenu } from '../menu';

const footers = [
	{
		path: '*',
		element: <Footer />,
		// element: (rootStore?.user?.me?.id || 0) === 0 ? null : <Footer />
	},
	{
		path: '/leave/request/detail/:id',
		element: <></>,
	},
	{
		path: '/leave/request',
		element: <></>,
	},
	{
		path: systemAdminMenu.system_setting.path + '/:code',
		element: <></>,
	},
	{
		path: authMenu.login.path,
		element: <></>,
	},
	{
		path: authMenu.PasswdReset.path,
		element: <></>,
	}
];

export default footers;
