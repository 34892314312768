import React, { useContext, useEffect, useRef } from 'react';
import Header, { HeaderLeft } from '../../../layout/Header/Header';
// import Search from '../../../components/Search';
import CommonHeaderRight from './CommonHeaderRight';
import { useMst } from '../../../models';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
// import firebaseInit from '../../../firebaseInit';
// import firebase from 'firebase/app';
import { useToasts } from 'react-toast-notifications';
import Toasts from '../../../components/bootstrap/Toasts';
import { observer } from 'mobx-react';
import { browserName, browserVersion, osVersion, osName } from 'react-device-detect';
import Alert from '../../../components/bootstrap/Alert';
import { useTranslation } from 'react-i18next';
import Button, { ButtonGroup } from '../../../components/bootstrap/Button';
import Icon from '../../../components/icon/Icon';
import ThemeContext from '../../../contexts/themeContext';
import { Base64 } from 'js-base64';
import config from '../../../config';
import Breadcrumb, { BreadcrumbItem } from '../../../components/bootstrap/Breadcrumb';

// import firebaseInit from '../../../firebaseInit';
// import firebase from 'firebase/compat/app';
// import AuthService from '../../../services/AuthService';

// let messaging = null;
const DashboardHeader = observer(() => {
	const navigate = useNavigate();
	const location = useLocation();
	const { user, company, notifiction, navtabmenus } = useMst();
	const { setAsdieSubMenuStatus, setAsideMenu, breadcrumb } = useContext(ThemeContext);
	const { t } = useTranslation(['translation', 'menu']);

	// if (firebase.messaging.isSupported() && !messaging) {
	// 	messaging = firebaseInit.messaging();
	// }
	// if (firebase.messaging.isSupported() && messaging) {

	// 	console.log('포그라운드 메시지 수신 등록');

	// 	messaging.onMessage((payload) => {
	// 		console.log("포그라운드 메시지 수신:", payload);
	// 		/*
	// 			{
	// 				"from": "433045748133",
	// 				"messageId": "5a664d05-b921-4676-89fa-89b3c13dfea7",
	// 				"notification": {
	// 					"title": "결재 요청",
	// 					"body": "결재 요청이 있습니다."
	// 				},
	// 				"data": {
	// 					"payload": "{\"title\":\"결재 요청\",\"message\":\"결재 요청이 있습니다.\",\"data\":{\"userId\":219,\"companyId\":10,\"type\":\"APPROVAL\",\"screen\":\"Approval\",\"link\":\"/approval/1\",\"params\":{\"id\":1}},\"eventId\":\"81\",\"eventType\":\"ALARM\",\"tokens\":[\"fMf7dfQ3NzvCIBe8CiB5q7:APA91bHNuYcbRxAjW6oTjPE9L6r3Fsc-YcWJJ43OBe1PH-J-QMls_21FsCOdaYPqO3JY9q0icFIRHDcEz2h8m3it4fXzW5K_CKVGCxXjVO80uby6nhb1Fco\"]}"
	// 				}
	// 			}
	// 		*/
	// 		addToast(
	// 			<Toasts
	// 				icon={'InfoOutline'}
	// 				iconColor={'dark'}
	// 				title={<b>{t((payload.data?.title ? payload.data?.title : payload.notification.title), payload.data)}</b>}
	// 			/* isDismiss */
	// 			>
	// 				<div className='d-flex justify-content-between align-items-center w-100'>
	// 					<div className={`border p-2 border-info`}>
	// 						<Icon icon='EventAvailable' size='2x' color={'info'} />
	// 					</div>
	// 					<div className='flex-grow-1 px-4 text-wrap' style={{overflowWrap: 'anywhere'}}>{t((payload.data?.message ? payload.data?.message : payload.notification.body), payload.data)}</div>
	// 					{/* {payload?.data?.detailId && <Button type='button' size='xs' onClick={()=>{
	// 						const cryptoUrl = Base64.encode(`${company.get.id}_${payload?.data?.detailId}`);
	// 						window.location.href = `/e-approval/document/${cryptoUrl}`;
	// 					}} ><small>링크</small></Button>} */}
	// 				</div>
	// 			</Toasts>,
	// 			{
	// 				autoDismiss: true,
	// 				autoClose: 10000, // 10초
	// 			}
	// 		);

	// 		// 알림 갯수 갱신
	// 		notifiction.getRefresh(user.me.company.code);

	// 	});
	// }

	// if (firebase.messaging.isSupported()) {
	// 	messaging = firebaseInit.messaging();
	// 	console.log('messaging', messaging)
	// 	messaging.onMessage(function (payload) {
	// 		console.log("onMessage", payload);
	// 		// alarm 에 보이도록 !!!
	// 		// refresh Notification

	// 		// addToast(
	// 		// 	<Toasts
	// 		// 		icon={'InfoOutline'}
	// 		// 		iconColor={'info'}
	// 		// 		title={t((payload.data?.title ? payload.data?.title : payload.notification.title), payload.data)}
	// 		// 		/* isDismiss */
	// 		// 		>
	// 		// 		<>
	// 		// 			<p>{t((payload.data?.message ? payload.data?.message : payload.notification.body), payload.data)}</p>
	// 		// 			{/* {payload?.data?.detailId && <Button type='button' size='xs' onClick={()=>{
	// 		// 				const cryptoUrl = Base64.encode(`${company.get.id}_${payload?.data?.detailId}`);
	// 		// 				window.location.href = `/e-approval/document/${cryptoUrl}`;
	// 		// 			}} ><small>링크</small></Button>} */}
	// 		// 		</>
	// 		// 	</Toasts>,
	// 		// 	{
	// 		// 		autoDismiss: false,
	// 		// 	}
	// 		// );
	// 		// reFreshMsg();
	// 	});

	// 	messaging.onBackgroundMessage(function (payload) {
	// 		console.log("onBackgroundMessage", payload);
	// 		// const notificationTitle = t(payload.notification.title, payload.data);
	// 		// const notificationOptions = {
	// 		// 	body: t(payload.notification.body, payload.data)
	// 		// }
	// 		// return self.registration.showNotification(notificationTitle, notificationOptions);
	// 	});
	// }

	// 		// messaging.setBackgroundMessageHandler(function(payload) {
	// 		// 	console.log('[firebase-messaging-sw.js] Received background message', payload);
	// 		// 	return new Promise(function(resolve, reject) {
	// 		// 		resolve();
	// 		// 		setTimeout(function(){
	// 		// 			self.registration.getNotifications().then(notifications => {
	// 		// 				notifications.forEach((notification) => {
	// 		// 					notification.close();
	// 		// 				})
	// 		// 			})
	// 		// 		},10);
	// 		// 	});
	// 		// });

	// 	// messaging.setBackgroundMessageHandler(function (payload) {
	// 	// 	console.log("onBackgroundMessage", payload);
	// 	// 	const notificationTitle = t(payload.notification.title, payload.data);
	// 	// 	const notificationOptions = {
	// 	// 		body: t(payload.notification.body, payload.data)
	// 	// 	}
	// 	// 	return self.registration.showNotification(notificationTitle, notificationOptions);
	// 	// });

	// }

	// const { addToast } = useToasts();

	const reFreshMsg = async () => {
		// notifiction.getRefresh(company.get.id);
	};

	// function useInterval(callback, delay) {
	// 	const savedCallback = useRef();
	// 	useEffect(() => {
	// 		savedCallback.current = callback;
	// 	}, [callback]);

	// 	useEffect(() => {
	// 		function tick() {
	// 			savedCallback.current();
	// 		}
	// 		if (delay !== null) {
	// 			let id = setInterval(tick, delay);
	// 			return () => clearInterval(id);
	// 		}
	// 	}, [delay]);
	// }

	// const checkSession = async () => {
	// 	await AuthService.check().then( async res => {
	// 		// await notifiction.getRefresh(company.get.id)
	// 		if(res.data === undefined) {
	// 			navigate(`/${authMenu.login.path}`)
	// 		}
	// 	})
	// };

	// useInterval(() => {
	// 	checkSession();
	// }, 1000 * 60 * 5)

	// useEffect(()=>{
	// 	//console.log("@@@", user.me)
	// 	if(!user.me.id) {
	// 		navigate(`/${authMenu.login.path}`);
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [user])

	// useEffect(()=>{
	// 	console.log('navtabmenus.get.list', navtabmenus.get.list)
	// }, [navtabmenus.get.list])

	useEffect(() => {
		reFreshMsg();
		//checkSession();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Header>
				<HeaderLeft className={'text-truncate m-0 p-0'}>
					<Breadcrumb list={null} isToHome={false} divider={<Icon icon='ArrowRight' size='lg' />} autoActive>
						<BreadcrumbItem divider={<Icon icon='Home' size='lg' color={null} style={{ color: '#8F959B' }} />} />
						{breadcrumb?.length > 0 ? (
							breadcrumb?.map((item, index) => (
								<BreadcrumbItem key={`breadcrumb-${item.id}-${index}`} to={item.path} isActive={breadcrumb?.length === index - 1}>
									<span className={(breadcrumb?.length || 0) === index + 1 ? 'fw-bold text-dark' : 'text-dark-50 fw-light'}>{item?.text || ''}</span>
								</BreadcrumbItem>
							))
						) : (
							<BreadcrumbItem to={window.location.pathname}>
								<span className='fw-bold'>{document.getElementsByTagName('TITLE')[0]?.text?.replace('| ' + config?.title, '')}</span>
							</BreadcrumbItem>
						)}
					</Breadcrumb>

					{/* {navtabmenus?.get?.list.length > 0 ? (
						<div className='col-md overflow-auto me-5'>
							{navtabmenus?.get?.list?.map((item) => (
								<ButtonGroup key={item.id} className='border border-light rounded me-2'>
									<Button
										type='button'
										size='sm'
										icon='Pageview'
										color={item.isActive === true ? 'light' : (location.pathname === '/'+item.path) == true ? 'light' : ''}
										onClick={() => {
											let lists = navtabmenus?.get?.list?.map( v => {
												return {...v, isActive: item.path === v.path ? true : false}
											})
											navtabmenus.setMenus(lists);
											navigate(item.path[0] === '/' ? item.path : '/' + item.path);
										}}
										className='text-nowrap'>
										{item.name}
									</Button>
									<Button
										type='button'
										size='sm'
										color={item.isActive === true ? 'light' : (location.pathname === '/'+item.path) == true ? 'light' : ''}
										onClick={async () => {
											let lists = navtabmenus.get.list?.filter((v) => v.id !== item.id);
											navtabmenus.setMenus(lists);
										}}>
										<Icon icon='close' />
									</Button>
								</ButtonGroup>
							))}
						</div>
					) : (
						<></>
					)} */}
				</HeaderLeft>
				<CommonHeaderRight /* afterChildren={<CommonHeaderChat />} */ />
			</Header>
			{/* {browserName.includes('Safari') && (
				<Alert icon='infoOutline' className={'mx-5'} rounded={0}>
					Safari 브라우저에서는 푸시알림이 지원되지 않습니다. Chrome, Edge 브라우저를 이용해주세요
				</Alert>
			)} */}
		</>
	);
});

export default DashboardHeader;
