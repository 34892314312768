
/**
 * @typedef {enum} PROMO_PUSH_TYPES - 촉진 타입
 */
export const PROMO_PUSH_TYPES = {
    /** @property {string} REST_PROMOTION_YEAR_PLAN_1 - 근속1년이상 1차 통보 */
    REST_PROMOTION_YEAR_PLAN_1: 'REST_PROMOTION_YEAR_PLAN_1',
    /** @property {string} REST_PROMOTION_YEAR_PLAN_2 - 근속1년이상 2차 통보 */
    REST_PROMOTION_YEAR_PLAN_2: 'REST_PROMOTION_YEAR_PLAN_2',
    /** @property {string} REST_PROMOTION_FORMER_PLAN_1 - 입사1년미만 9일치 1차 통보 */
    REST_PROMOTION_FORMER_PLAN_1: 'REST_PROMOTION_FORMER_PLAN_1',
    /** @property {string} REST_PROMOTION_FORMER_PLAN_2 - 입사1년미만 9일치 2차 통보 */
    REST_PROMOTION_FORMER_PLAN_2: 'REST_PROMOTION_FORMER_PLAN_2',
    /** @property {string} REST_PROMOTION_LATTER_PLAN_1 - 입사1년미만 2일치 1차 통보 */
    REST_PROMOTION_LATTER_PLAN_1: 'REST_PROMOTION_LATTER_PLAN_1',
    /** @property {string} REST_PROMOTION_LATTER_PLAN_2 - 입사1년미만 2일치 2차 통보 */
    REST_PROMOTION_LATTER_PLAN_2: 'REST_PROMOTION_LATTER_PLAN_2',
};    

/**
 * @typedef {enum} PROMO_STEP - 촉진 타입
 */
export const PROMO_STEP = {
    /** @property {string} PLAN_1 - 1차 */
    PLAN_1: 'PLAN_1',
    /** @property {string} PLAN_2 - 2차 */
    PLAN_2: 'PLAN_2'
}

/**     
     * @typedef {enum} MODAL_TYPE - 모달 타입 구분
     */
export const MODAL_TYPE = {
    /** @property {string} DOCUMENT - 문서 */
    DOCUMENT: 'DOCUMENT',
    /** @property {string} SUBMIT - 제출 */
    SUBMIT: 'SUBMIT'
}

/**
 * @typedef {Enumerator} promotionType - 촉진 구분 
 *
 * @property {promotionType}  - 촉진 구분
*/
export const promotionType = {
    /** @property {string} YEAR - 근속1년이상 */
    YEAR: 'YEAR',
    /** @property {string} FORMER - 입사1년미만 9일 */
    FORMER: 'FORMER',
    /** @property {string} LATTER - 입사1년미만 2일 */
    LATTER: 'LATTER',
};

/**
 * @typedef {Object} promotionList - 연차촉진 상단 알림
 * @property {string} message - 메시지
 * @property {string} icon - 아이콘
 * @property {int} dDay - D-Day (남은 일자)
 * @property {string} color - 색상
 * @property {string} status - 상태
 * @property {boolean} required - 필수여부
 * @property {promotionData} data - 데이터
 * @property {Function} action - 액션
 */

/**
 * 연차촉진 상태바 출력
 * @param {{ promotionLists: promotionList[] }} props - 연차촉진 Object
 */

/** 
 * @typedef {object} promotionData
 * @property {string} createdAt - 생성일자
 * @property {string} updatedAt - 수정일자
 * @property {number} id - ID
 * @property {number} userId - 사용자 ID
 * @property {number} year - 연도
 * @property {string} restType - 휴가 타입
 * @property {string} promoType - 촉진 타입
 * @property {string} notiDurationStartDate - 통보 기간 시작일
 * @property {string} notiDurationEndDate - 통보 기간 종료일
 * @property {string} planSubmissionStartDate - 계획 제출 시작일
 * @property {string} planSubmissionEndDate - 계획 제출 종료일
 * @property {string} usageSpeciNotiDeadline - 사용 시기 통보 마감일
 * @property {string} notiDateTime - 통보 일시
 * @property {date} notiReDateTimes - 재통보 일시
 * @property {date} viewDateTime - 열람 일시
 * @property {date} viewReDateTimes - 추가 열람 일시
 * @property {date} planSubmissionDateTime - 계획 제출 일시
 * @property {date} planSubmissionReDateTimes - 계획 재제출 일시
 * @property {date} planSubmissionDurationDateTimes - 계획 제출 기간
 * @property {date} planSubmissionDuration - 계획 제출 기간 (일수)
 * @property {date} usageSpeciNotiDateTime - 사용 시기 통보 일시
 * @property {date} usageSpeciReNotiDates - 사용 시기 재통보 일시
 * @property {date} usageSpeciNotiDuration - 사용 시기 통보 기간 (일수)
 * @property {date} usageSpeciNotiDurationDateTimes - 사용 시기 통보 기간
 * @property {date} usageSpeciViewDateTime - 사용 시기 열람 일시
 * @property {date} usageSpeciViewDateTimes - 사용 시기 추가 열람 일시
 * @property {string} memo - 메모
 * @property {null} sign - 서명
 * @property {number} user_id - 사용자 ID
 * @property {object} userRestInfo - 사용자 휴가 정보
 * @property {string} userRestInfo.createdAt - 생성일자
 * @property {string} userRestInfo.updatedAt - 수정일자
 * @property {number} userRestInfo.id - ID
 * @property {number} userRestInfo.year - 연도
 * @property {number} userRestInfo.userCompanyId - 사용자 회사 ID
 * @property {number} userRestInfo.joinYear - 입사 연도
 * @property {string} userRestInfo.type - 타입
 * @property {number} userRestInfo.carryOverDays - 이월 일수
 * @property {string} userRestInfo.startDate - 시작일
 * @property {string} userRestInfo.endDate - 종료일
 * @property {object[]} userRestInfo.yearsInfo - 연도 정보
 * @property {string} userRestInfo.yearsInfo.s - 시작일
 * @property {string} userRestInfo.yearsInfo.e - 종료일
 * @property {number} userRestInfo.yearsInfo.calculate - 계산
 * @property {number} userRestInfo.yearsInfo.modification - 수정
 * @property {number} userRestInfo.yearsInfo.attendRate - 출석률
 * @property {number} userRestInfo.yearsInfo.advance - 선지급
 * @property {number} userRestInfo.yearsInfo.usedDays - 사용 일수
 * @property {number} userRestInfo.yearsInfo.value - 값
 * @property {object} userRestInfo.optionInfo - 옵션 정보
 * @property {string} userRestInfo.optionInfo.createdAt - 생성일자
 * @property {string} userRestInfo.optionInfo.updatedAt - 수정일자
 * @property {number} userRestInfo.optionInfo.id - ID
 * @property {number} userRestInfo.optionInfo.companyId - 회사 ID
 * @property {number} userRestInfo.optionInfo.workPlaceId - 근무지 ID
 * @property {string} userRestInfo.optionInfo.vacAuth - 휴가 승인
 * @property {string} userRestInfo.optionInfo.annLeaveCrit - 연차 기준
 * @property {string} userRestInfo.optionInfo.accrualMonth - 적립 월
 * @property {string} userRestInfo.optionInfo.critLess1Yr - 1년 미만 기준
 * @property {string} userRestInfo.optionInfo.calcLess1YrLess1Mth - 1년 미만 1개월 미만 계산
 * @property {string} userRestInfo.optionInfo.decCalcUnitLess1Yr - 1년 미만 계산 단위
 * @property {string} userRestInfo.optionInfo.handleLess1YrLess1Mth - 1년 미만 1개월 미만 처리
 * @property {string} userRestInfo.optionInfo.useTimeDiff - 시간 차이 사용
 * @property {string} userRestInfo.optionInfo.autoGenVacReq - 자동 생성 휴가 요청
 * @property {string} userRestInfo.optionInfo.appLineStagesVacLeave - 휴가 승인 단계
 * @property {null} userRestInfo.optionInfo.alarms - 알람
 * @property {object} userRestInfo.optionInfo.timeNotation - 시간 표기법
 * @property {string} userRestInfo.optionInfo.timeNotation.type - 타입
 * @property {number} userRestInfo.optionInfo.timeNotation.decimal - 소수점
 * @property {string} userRestInfo.optionInfo.sendNotifEmail - 알림 이메일 전송
 * @property {boolean} userRestInfo.optionInfo.applyVacPromoScheme - 휴가 촉진 계획 적용
 * @property {boolean} userRestInfo.optionInfo.useAllowPaySettleDays - 유급 정산 일수 사용
 * @property {string} userRestInfo.optionInfo.carryoverLess1Yr - 1년 미만 이월
 * @property {boolean} userRestInfo.optionInfo.initAllocDaysUse - 초기 할당 일수 사용
 * @property {string} userRestInfo.optionInfo.initAllocDays - 초기 할당 일수
 * @property {string} userRestInfo.optionInfo.genVacOperMethod - 휴가 운영 방법
 * @property {string} userRestInfo.optionInfo.appLineGenVac - 휴가 승인 라인
 * @property {string} userRestInfo.optionInfo.stagesAppLineGenVac - 휴가 승인 단계
 * @property {string} userRestInfo.optionInfo.vacUseUnitLess1Yr - 1년 미만 휴가 사용 단위
 * @property {boolean} userRestInfo.optionInfo.carryoverVac - 휴가 이월
 * @property {boolean} userRestInfo.optionInfo.carryoverVacAllowPaySettle - 휴가 이월 유급 정산 허용
 * @property {array[]|string[]} userRestInfo.optionInfo.openCloseTime - 개폐 시간
 * @property {array[]|string[]} userRestInfo.optionInfo.breakTime - 휴식 시간
 * @property {boolean} userRestInfo.optionInfo.breakTimeIncl - 휴식 시간 포함
 * @property {[string, string]} userRestInfo.optionInfo.halfDayAmTime - 오전 반차 시간
 * @property {[string, string]} userRestInfo.optionInfo.halfDayPmTime - 오후 반차 시간
 * @property {boolean} userRestInfo.optionInfo.usePostProcess - 후처리 사용
 * @property {string[]} userRestInfo.optionInfo.vacUseUnit - 휴가 사용 단위
 * @property {null} userRestInfo.optionInfo.allowTimeDiffUnit - 시간 차이 단위 허용
 * @property {boolean} userRestInfo.optionInfo.allow30MinDiff - 30분 차이 허용
 * @property {boolean} userRestInfo.optionInfo.useHalfHalfDiff - 반반 차이 사용
 * @property {string} userRestInfo.optionInfo.useHumanResources - 인사 사용
 * @property {number} userRestInfo.optionInfo.work_place_id - 근무지 ID
 * @property {number} userRestInfo.optionInfo.company_id - 회사 ID
 * @property {number} userRestInfo.previousYearUnusedDays - 전년도 미사용 일수
 * @property {number} userRestInfo.previousYearUnusedDaysTemp - 전년도 미사용 일수 (임시)
 * @property {number} userRestInfo.calculate - 계산
 * @property {number} userRestInfo.generation - 생성
 * @property {number} userRestInfo.modification - 수정
 * @property {number} userRestInfo.deduction - 공제
 * @property {number} userRestInfo.beforeYearAdvance - 전년도 선지급
 * @property {number} userRestInfo.advance - 선지급
 * @property {number} userRestInfo.waitDays - 대기 일수
 * @property {number} userRestInfo.availableDayOff - 사용 가능 휴가 일수
 * @property {number} userRestInfo.usedDaysModification - 사용 일수 수정
 * @property {number} userRestInfo.usedDays - 사용 일수
 * @property {number} userRestInfo.remainingDays - 남은 일수
 * @property {boolean} userRestInfo.isClosed - 종료 여부
 * @property {boolean} userRestInfo.isUse - 사용 여부
 * @property {string[]} userRestInfo.etc - 기타
 * @property {number} userRestInfo.user_company_id - 사용자 회사 ID
 * @property {object[]} userRestInfo.userRestInfoPromotion - 사용자 휴가 촉진 정보
 * @property {date} userRestInfo.userRestInfoPromotion.createdAt - 생성일자
 * @property {date} userRestInfo.userRestInfoPromotion.updatedAt - 수정일자
 * @property {number} userRestInfo.userRestInfoPromotion.id - ID
 * @property {number} userRestInfo.userRestInfoPromotion.userId - 사용자 ID
 * @property {number} userRestInfo.userRestInfoPromotion.year - 연도
 * @property {string} userRestInfo.userRestInfoPromotion.restType - 휴가 타입
 * @property {string} userRestInfo.userRestInfoPromotion.promoType - 촉진 타입
 * @property {date} userRestInfo.userRestInfoPromotion.notiDurationStartDate - 통보 기간 시작일
 * @property {date} userRestInfo.userRestInfoPromotion.notiDurationEndDate - 통보 기간 종료일
 * @property {date} userRestInfo.userRestInfoPromotion.planSubmissionStartDate - 계획 제출 시작일
 * @property {date} userRestInfo.userRestInfoPromotion.planSubmissionEndDate - 계획 제출 종료일
 * @property {date} userRestInfo.userRestInfoPromotion.usageSpeciNotiDeadline - 사용 시기 통보 마감일
 * @property {date} userRestInfo.userRestInfoPromotion.notiDateTime - 통보 일시
 * @property {date} userRestInfo.userRestInfoPromotion.notiReDateTimes - 재통보 일시
 * @property {date} userRestInfo.userRestInfoPromotion.viewDateTime - 열람 일시
 * @property {date[]} userRestInfo.userRestInfoPromotion.viewReDateTimes - 추가 열람 일시
 * @property {date} userRestInfo.userRestInfoPromotion.planSubmissionDateTime - 계획 제출 일시
 * @property {date} userRestInfo.userRestInfoPromotion.planSubmissionReDateTimes - 계획 재제출 일시
 * @property {date} userRestInfo.userRestInfoPromotion.planSubmissionDurationDateTimes - 계획 제출 기간
 * @property {number} userRestInfo.userRestInfoPromotion.planSubmissionDuration - 계획 제출 기간 (일수)
 * @property {date} userRestInfo.userRestInfoPromotion.usageSpeciNotiDateTime - 사용 시기 통보 일시
 * @property {date} userRestInfo.userRestInfoPromotion.usageSpeciReNotiDates - 사용 시기 재통보 일시
 * @property {number} userRestInfo.userRestInfoPromotion.usageSpeciNotiDuration - 사용 시기 통보 기간 (일수)
 * @property {date} userRestInfo.userRestInfoPromotion.usageSpeciNotiDurationDateTimes - 사용 시기 통보 기간
 * @property {date} userRestInfo.userRestInfoPromotion.usageSpeciViewDateTime - 사용 시기 열람 일시
 * @property {date} userRestInfo.userRestInfoPromotion.usageSpeciViewDateTimes - 사용 시기 추가 열람 일시
 * @property {string} userRestInfo.userRestInfoPromotion.memo - 메모
 * @property {text} userRestInfo.userRestInfoPromotion.sign - 서명
 * @property {number} userRestInfo.userRestInfoPromotion.user_id - 사용자 ID
 */

export {};