import { types } from 'mobx-state-tree';
import AuthService from '../services/AuthService';

const NotificationList = types.model({
	id: types.optional(types.number, 0),
	title: types.optional(types.string, ''),
	message: types.optional(types.string, ''),
	data: types.optional(types.string, '{}'),
	createdAt: types.optional(types.string, ''),
	type: types.optional(types.string, ''),
	read: types.optional(types.boolean, false),
});

export const Notifiction = types
	.model('Notifiction', {
		isOpen: types.optional(types.boolean, false),
		badge: types.optional(types.number, 0),
		list: types.optional(types.array(NotificationList), []),
	})
	.views((self) => ({
		get get() {
			return { ...self };
		},
	}))
	.actions((self) => ({
		async setData(data) {
			self.list = data;
		},
		async setBadge(no) {
			self.badge = no;
		},
		async getRefresh(companyCode = null) {
			if (!companyCode) return;
			AuthService.alarmList(companyCode)
				.then((res) => {
					if (res.data) {
						const data = res.data?.data || [];
						self.setData(data); //?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
						self.setBadge(parseInt(res.data?.badge || 0));
					}
				})
				.catch(() => {
					//console.log('error')
					//window.location.replace(`/${demoPages.login.path}`)
				});
		},
	}));
