import React from 'react';
import PropTypes from 'prop-types';

const Logo = ({ width=695, height=109 }) => {
	return (
		<svg width={height !== 695 ? height * (695 / 109) : width} height={width !== 695 ? width * (109 / 695) : height} viewBox="0 0 695 109" fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path d="M38 4C38 1.79086 39.7909 0 42 0H54C56.2091 0 58 1.79086 58 4V20H38V4Z" fill="#126DFF" />
			<circle cx="48" cy="61" r="48" fill="white" />
			<circle cx="48" cy="61" r="48" fill="#126DFF" fillOpacity="0.25" />
			<path d="M96 61C96 71.5664 92.5134 81.8376 86.081 90.2205C79.6485 98.6035 70.6297 104.63 60.4233 107.364C50.2169 110.099 39.3933 109.39 29.6312 105.346C19.8691 101.303 11.714 94.1508 6.43078 85C1.14756 75.8492 -0.968551 65.2108 0.410646 54.7347C1.78984 44.2587 6.58727 34.5305 14.0589 27.0589C21.5305 19.5873 31.2587 14.7898 41.7347 13.4106C52.2108 12.0314 62.8492 14.1476 72 19.4308L48 61H96Z" fill="#126DFF" fillOpacity="0.25" />
			<path d="M96 61C96 72.6452 91.7665 83.8933 84.0883 92.6486C76.4101 101.404 65.8108 107.069 54.2653 108.589C42.7197 110.109 31.0153 107.38 21.3326 100.911C11.65 94.4408 4.64956 84.6717 1.63556 73.4233C-1.37844 62.1749 -0.200431 50.2144 4.95011 39.7701C10.1006 29.3259 18.8724 21.1102 29.6312 16.6538C40.3899 12.1974 52.4019 11.8041 63.4291 15.5474C74.4563 19.2906 83.7466 26.915 89.5692 37L48 61H96Z" fill="#126DFF" fillOpacity="0.3" />
			<path d="M96 61C96 70.4935 93.1848 79.7738 87.9105 87.6674C82.6362 95.5609 75.1397 101.713 66.3688 105.346C57.598 108.979 47.9468 109.93 38.6357 108.078C29.3246 106.226 20.7718 101.654 14.0589 94.9411C7.34595 88.2282 2.7744 79.6754 0.922306 70.3643C-0.929784 61.0532 0.0207761 51.402 3.65378 42.6312C7.28679 33.8603 13.4391 26.3638 21.3326 21.0895C29.2262 15.8152 38.5065 13 48 13L48 61H96Z" fill="#126DFF" fillOpacity="0.5" />
			<path d="M96 61C96 69.4258 93.7821 77.7031 89.5692 85C85.3563 92.2969 79.2969 98.3563 72 102.569C64.7031 106.782 56.4258 109 48 109C39.5742 109 31.2969 106.782 24 102.569C16.7031 98.3563 10.6437 92.2969 6.43078 85C2.2179 77.7031 -7.36603e-07 69.4258 0 61C7.36604e-07 52.5742 2.2179 44.2969 6.43078 37C10.6437 29.7031 16.7031 23.6437 24 19.4308L48 61H96Z" fill="#126DFF" />
			<circle cx="48" cy="61" r="15" fill="white" />
			<path d="M694.755 69.008C694.755 71.6533 694.584 74.0427 694.243 76.176H640.355C640.782 81.808 642.872 86.3307 646.627 89.744C650.382 93.1573 654.99 94.864 660.451 94.864C668.302 94.864 673.848 91.5787 677.091 85.008H692.835C690.702 91.4933 686.819 96.8267 681.187 101.008C675.64 105.104 668.728 107.152 660.451 107.152C653.71 107.152 647.651 105.659 642.275 102.672C636.984 99.6 632.803 95.3333 629.731 89.872C626.744 84.3253 625.251 77.9253 625.251 70.672C625.251 63.4187 626.702 57.0613 629.603 51.6C632.59 46.0533 636.728 41.7867 642.019 38.8C647.395 35.8133 653.539 34.32 660.451 34.32C667.107 34.32 673.038 35.7707 678.243 38.672C683.448 41.5733 687.502 45.6693 690.403 50.96C693.304 56.1653 694.755 62.1813 694.755 69.008ZM679.523 64.4C679.438 59.024 677.518 54.7147 673.763 51.472C670.008 48.2293 665.358 46.608 659.811 46.608C654.776 46.608 650.467 48.2293 646.883 51.472C643.299 54.6293 641.166 58.9387 640.483 64.4H679.523Z" fill="black" />
			<path d="M586.331 34.32C591.878 34.32 596.827 35.472 601.179 37.776C605.616 40.08 609.072 43.4933 611.547 48.016C614.107 52.5387 615.387 58 615.387 64.4V106H600.923V66.576C600.923 60.2613 599.344 55.44 596.187 52.112C593.03 48.6987 588.72 46.992 583.259 46.992C577.798 46.992 573.446 48.6987 570.203 52.112C567.046 55.44 565.467 60.2613 565.467 66.576V106H551.003V66.576C551.003 60.2613 549.424 55.44 546.267 52.112C543.11 48.6987 538.8 46.992 533.339 46.992C527.878 46.992 523.526 48.6987 520.283 52.112C517.126 55.44 515.547 60.2613 515.547 66.576V106H500.955V35.472H515.547V43.536C517.936 40.6347 520.966 38.3733 524.635 36.752C528.304 35.1307 532.23 34.32 536.411 34.32C542.043 34.32 547.078 35.5147 551.515 37.904C555.952 40.2933 559.366 43.7493 561.755 48.272C563.888 44.0053 567.216 40.6347 571.739 38.16C576.262 35.6 581.126 34.32 586.331 34.32Z" fill="black" />
			<path d="M478.469 26.128C475.824 26.128 473.605 25.232 471.813 23.44C470.021 21.648 469.125 19.4293 469.125 16.784C469.125 14.1387 470.021 11.92 471.813 10.128C473.605 8.336 475.824 7.44 478.469 7.44C481.029 7.44 483.205 8.336 484.997 10.128C486.789 11.92 487.685 14.1387 487.685 16.784C487.685 19.4293 486.789 21.648 484.997 23.44C483.205 25.232 481.029 26.128 478.469 26.128ZM485.637 35.472V106H471.045V35.472H485.637Z" fill="black" />
			<path d="M444.284 47.376V86.416C444.284 89.0613 444.881 90.9813 446.076 92.176C447.356 93.2853 449.489 93.84 452.476 93.84H461.436V106H449.916C443.345 106 438.311 104.464 434.812 101.392C431.313 98.32 429.564 93.328 429.564 86.416V47.376H421.244V35.472H429.564V17.936H444.284V35.472H461.436V47.376H444.284Z" fill="black" />
			<path d="M380.067 107.152C373.411 107.152 367.395 105.659 362.019 102.672C356.643 99.6 352.419 95.3333 349.347 89.872C346.275 84.3253 344.739 77.9253 344.739 70.672C344.739 63.504 346.317 57.1467 349.475 51.6C352.632 46.0533 356.941 41.7867 362.403 38.8C367.864 35.8133 373.965 34.32 380.707 34.32C387.448 34.32 393.549 35.8133 399.011 38.8C404.472 41.7867 408.781 46.0533 411.939 51.6C415.096 57.1467 416.675 63.504 416.675 70.672C416.675 77.84 415.053 84.1973 411.811 89.744C408.568 95.2907 404.131 99.6 398.499 102.672C392.952 105.659 386.808 107.152 380.067 107.152ZM380.067 94.48C383.821 94.48 387.32 93.584 390.563 91.792C393.891 90 396.579 87.312 398.627 83.728C400.675 80.144 401.699 75.792 401.699 70.672C401.699 65.552 400.717 61.2427 398.755 57.744C396.792 54.16 394.189 51.472 390.947 49.68C387.704 47.888 384.205 46.992 380.451 46.992C376.696 46.992 373.197 47.888 369.955 49.68C366.797 51.472 364.28 54.16 362.403 57.744C360.525 61.2427 359.587 65.552 359.587 70.672C359.587 78.2667 361.507 84.1547 365.347 88.336C369.272 92.432 374.179 94.48 380.067 94.48Z" fill="black" />
			<path d="M334.157 11.28V106H319.565V11.28H334.157Z" fill="black" />
			<path d="M304.247 11.28V106H289.655V11.28H304.247Z" fill="black" />
			<path d="M279.135 69.008C279.135 71.6533 278.964 74.0427 278.623 76.176H224.735C225.162 81.808 227.252 86.3307 231.007 89.744C234.762 93.1573 239.37 94.864 244.831 94.864C252.682 94.864 258.228 91.5787 261.471 85.008H277.215C275.082 91.4933 271.199 96.8267 265.567 101.008C260.02 105.104 253.108 107.152 244.831 107.152C238.09 107.152 232.031 105.659 226.655 102.672C221.364 99.6 217.183 95.3333 214.111 89.872C211.124 84.3253 209.631 77.9253 209.631 70.672C209.631 63.4187 211.082 57.0613 213.983 51.6C216.97 46.0533 221.108 41.7867 226.399 38.8C231.775 35.8133 237.919 34.32 244.831 34.32C251.487 34.32 257.418 35.7707 262.623 38.672C267.828 41.5733 271.882 45.6693 274.783 50.96C277.684 56.1653 279.135 62.1813 279.135 69.008ZM263.903 64.4C263.818 59.024 261.898 54.7147 258.143 51.472C254.388 48.2293 249.738 46.608 244.191 46.608C239.156 46.608 234.847 48.2293 231.263 51.472C227.679 54.6293 225.546 58.9387 224.863 64.4H263.903Z" fill="black" />
			<path d="M199.125 17.04V106H184.533V67.088H142.677V106H128.085V17.04H142.677V55.184H184.533V17.04H199.125Z" fill="black" />
		</svg>
	);
};
Logo.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};
// Logo.defaultProps = {
// 	width: 695,
// 	height: 109,
// };

export default Logo;
