export const authMenu = {
	// blank: {
	// 	id: 'blank',
	// 	text: 'blank',
	// 	path: '',
	// 	icon: null
	// },
	login: {
		id: 'login',
		text: 'Login',
		path: 'login',
		icon: 'Login',
	},
	signUp: {
		id: 'signUp',
		text: 'signUp',
		path: 'signUp',
		icon: 'PersonAdd',
	},
	sendMail: {
		id: 'sendMail',
		text: 'sendMail',
		path: 'sendMail',
		icon: 'Login',
	},
	VerifyEmail: {
		id: 'VerifyEmail',
		text: 'VerifyEmail',
		path: 'verifyEmail',
		icon: 'Login',
	},
	page404: {
		id: 'Page404',
		text: '404 Page',
		path: '404',
		icon: 'ReportGmailerrorred',
	},
	PasswdReset: {
		id: 'PasswdReset',
		text: 'PasswdReset',
		path: 'PasswdReset',
		icon: null,
		subMenu: null,
	},
	changePassword: {
		id: 'changePassword',
		text: '비밀번호 변경',
		path: '/manage/password',
		icon: 'Lock',
		subMenu: null,
	},
};

export const commonsMenu = {
	dashboard: {
		id: 'dashboard',
		text: '대시보드',
		path: '/',
		icon: 'Dashboard',
		subMenu: null,
	},	
};

export const commonsBottomMenu = {
	// 고객지원센터
	customer_support: {
		id: 'customer_support',
		text: '고객지원센터',
		path: '/system/cs',
		icon: 'ContactSupport',
		subMenu: {
			// 서비스 이용 가이드
			service_guide: {
				id: 'service_guide',
				text: '서비스 이용 가이드',
				path: '/system/cs/guide',
				icon: 'Help',
			},
			// 공지사항
			notice: {
				id: 'notice',
				text: '공지사항',
				path: '/system/cs/notice',
				icon: 'Settings',
			},
			// FAQ
			faq: {
				id: 'faq',
				text: 'FAQ',
				path: '/system/cs/faq',
				icon: 'Settings',
			},
			// 고객문의
			contact: {
				id: 'contact',
				text: '고객문의',
				path: '/system/cs/contact',
				icon: 'Settings',
			},
			// 약관 및 정책
			policy: {
				id: 'policy',
				text: '약관 및 정책',
				path: '/system/cs/policy',
				icon: 'Settings',
			},
		},
	},
};

export const systemAdminMenu = {
	// 서비스 현황
	// service_status: {
	// 	id: 'service_status',
	// 	text: '서비스 현황',
	// 	path: '/system/status',
	// 	icon: 'Settings',
	// 	subMenu: null,
	// },
	// 결제 관리
	// payment_manage: {
	// 	id: 'payment_manage',
	// 	text: '결제 관리',
	// 	path: '/system/payment',
	// 	icon: 'Settings',
	// 	subMenu: null,
	// },
	system_setting: {
		id: 'system_setting',
		text: '고객사 관리',
		path: '/manage/company',
		icon: 'Domain',
		subMenu: null,
	},
	// 매출 관리
	sales_manage: {
		id: 'sales_manage',
		text: '매출 관리',
		path: '/manage/sales',
		icon: 'CreditCard',
		subMenu: null,
	},
	// 시스템관리자 등록
	system_admin_register: {
		id: 'system_admin_register',
		text: '시스템관리자 등록',
		path: '/manage/admin',
		icon: 'PersonAddAlt',
		subMenu: null,
	},
	changePassword: {
		id: 'changePassword',
		text: '비밀번호 변경',
		path: '/manage/password',
		icon: 'Lock',
		subMenu: null,
	},
	// 시스템코드 관리
	// system_code: {
	// 	id: 'system_code',
	// 	text: '시스템코드 관리',
	// 	path: '/system/code',
	// 	icon: 'Settings',
	// 	subMenu: null,
	// },
	// 서비스 및 기능 관리
	// service_manage: {
	// 	id: 'service_manage',
	// 	text: '서비스 및 기능 관리',
	// 	path: '/system/service',
	// 	icon: 'Settings',
	// 	subMenu: {
	// 		// 서비스 설정
	// 		service_setting: {
	// 			id: 'service_setting',
	// 			text: '서비스 설정',
	// 			path: '/system/service/setting',
	// 			icon: 'Settings',
	// 		},
	// 		// 기능별 권한 관리
	// 		function_permission: {
	// 			id: 'function_permission',
	// 			text: '기능별 권한 관리',
	// 			path: '/system/service/permission',
	// 			icon: 'Settings',
	// 		},
	// 		// API 및 통합 설정
	// 		api_integration: {
	// 			id: 'api_integration',
	// 			text: 'API 및 통합 설정',
	// 			path: '/system/service/api',
	// 			icon: 'Settings',
	// 		},
	// 		// 데이터 관리
	// 		data_manage: {
	// 			id: 'data_manage',
	// 			text: '데이터 관리',
	// 			path: '/system/service/data',
	// 			icon: 'Settings',
	// 		},
	// 	},
	// },

	// data_manage: {
	// 	id: 'data_manage',
	// 	text: '데이터 관리',
	// 	path: '/system/data',
	// 	icon: 'Settings',
	// 	subMenu: {
	// 		// 백업 및 복구
	// 		backup_restore: {
	// 			id: 'backup_restore',
	// 			text: '백업 및 복구',
	// 			path: '/system/data/backup',
	// 			icon: 'Settings',
	// 		},
	// 		// 데이터 최적화
	// 		data_optimization: {
	// 			id: 'data_optimization',
	// 			text: '데이터 최적화',
	// 			path: '/system/data/optimization',
	// 			icon: 'Settings',
	// 		},
	// 	},
	// },
	// system_monitoring: {
	// 	id: 'system_monitoring',
	// 	text: '시스템 모니터링',
	// 	path: '/system/monitoring',
	// 	icon: 'Settings',
	// 	subMenu: {
	// 		// 상태 점검
	// 		status_check: {
	// 			id: 'status_check',
	// 			text: '상태 점검',
	// 			path: '/system/monitoring/status',
	// 			icon: 'Settings',
	// 		},
	// 		// 로그 분석
	// 		log_analysis: {
	// 			id: 'log_analysis',
	// 			text: '로그 분석',
	// 			path: '/system/monitoring/log',
	// 			icon: 'Settings',
	// 		},
	// 	},
	// },

	// system_setting_use: {
	// 	id: 'system_setting_use',
	// 	text: '사용기능설정',
	// 	path: '/system/setting/use',
	// 	icon: 'Settings',
	// 	subMenu: null,
	// },
};

export const adminMenu = {
	// hr: {
	// 	id: 'hr',
	// 	text: '인적정보 관리',
	// 	path: '/hr',
	// 	icon: 'Description',
	// 	subMenu: {
	// 		users: {
	// 			id: 'personalInfo',
	// 			text: '인적데이터 조회',
	// 			path: '/hr/personalInfo',
	// 			icon: 'Help',
	// 		},
	// 	},
	// 	// {
	// 	// 	users: {
	// 	// 		id: 'personalInfo',
	// 	// 		text: '인적데이터 조회',
	// 	// 		path: '/hr/personalInfo',
	// 	// 		icon: 'Help',
	// 	// 	},
	// 	// 	// 부서 관리
	// 	// 	codeMgmtDepartment: {
	// 	// 		id: 'codeMgmtDepartment',
	// 	// 		text: '코드 관리(부서)',
	// 	// 		path: '/setting/code/department',
	// 	// 		icon: 'AccountTree',
	// 	// 	},
	// 	// 	// departments: {
	// 	// 	// 	id: 'department',
	// 	// 	// 	text: '코드 관리(부서)',
	// 	// 	// 	path: '/setting/code/department',
	// 	// 	// 	icon: 'AccountTree',
	// 	// 	// },
	// },
	notice: {
		id: 'notice',
		text: '공지사항',
		path: '/board/notice',
		icon: 'ReportGmailerrorred',
	},
	hr: {
		id: 'hr',
		text: '인사정보 등록',
		path: '/hr/personal-info',
		icon: 'idCard',
		// subMenu: {
		// 	// users: {
		// 	// 	id: 'personalInfo',
		// 	// 	text: '인적데이터 조회',
		// 	// 	path: '/hr/personalInfo',
		// 	// 	icon: 'Help',
		// 	// },
		// },
		// {
		// 	users: {
		// 		id: 'personalInfo',
		// 		text: '인적데이터 조회',
		// 		path: '/hr/personalInfo',
		// 		icon: 'Help',
		// 	},
		// 	// 부서 관리
		// 	codeMgmtDepartment: {
		// 		id: 'codeMgmtDepartment',
		// 		text: '코드 관리(부서)',
		// 		path: '/setting/code/department',
		// 		icon: 'AccountTree',
		// 	},
		// 	// departments: {
		// 	// 	id: 'department',
		// 	// 	text: '코드 관리(부서)',
		// 	// 	path: '/setting/code/department',
		// 	// 	icon: 'AccountTree',
		// 	// },
	},
	setting: {
		id: 'setting',
		text: '환경설정',
		path: '/settings',
		icon: 'Settings',
		subMenu: {
			// default_setting: {
			// 	id: 'defaultSetting',
			// 	text: '기본환경 설정',
			// 	path: '/setting/default',
			// 	icon: 'Settings'
			// },
			// company_setting: {
			// 	id: 'company_setting',
			// 	text: '회사정보 관리',
			// 	path: '/setting/company',
			// 	icon: 'Settings',
			// },

			// 코드 관리
			codeMgmt: {
				id: 'codeMgmt',
				text: '코드 등록',
				path: '/settings/code',
				icon: 'Code',
			},
			//부서관리
			// codeMgmtDepartment: {
			// 	id: 'codeMgmtDepartment',
			// 	text: '코드 관리(부서)',
			// 	path: '/setting/code/department',
			// 	icon: 'AccountTree',
			// },
			// codeMgmtDeptPersonal: {
			// 	id: 'codeMgmtDeptPersonal',
			// 	text: '코드 관리(인적정보)',
			// 	path: '/setting/code/personal',
			// 	icon: 'Code',
			// },
			// codeMgmtLeave: {
			// 	id: 'codeMgmtLeave',
			// 	text: '코드 관리(휴가관리)',
			// 	path: '/setting/code/leave',
			// 	icon: 'Code',
			// },
			// policy_setting: {
			// 	id: 'policy_setting',
			// 	text: '정책 설정',
			// 	path: '/setting/policy',
			// 	icon: 'Settings'
			// },
			holiday_setting: {
				id: 'holidayReg',
				text: '휴일 등록',
				path: '/settings/holiday',
				icon: 'AirplaneTicket',
			},
			// 결재라인 관리
			dayoffApprovalLine: {
				id: 'dayoffApprovalLine',
				text: '결재라인 등록',
				path: '/settings/approval/manage',
				icon: 'PersonAddAlt',
			},
			// 결재라인 대행
			dayoffApprovalDelegation: {
				id: 'dayoffApprovalDelegation',
				text: '결재권한 위임',
				path: '/settings/approval/delegation',
				icon: 'PersonSearch',
			},
			// DATA 업로드
			dataUpload: {
				id: 'dataUpload',
				text: 'DATA 업로드',
				path: '/settings/data-upload',
				icon: 'FileUpload',
			},
			// hr_setting: {
			// 	id: 'hr_setting',
			// 	text: '구성원 설정',
			// 	path: '/setting/hr',
			// 	icon: 'Settings'
			// }
		},
	},
	dayoffManage: {
		id: 'dayoffManage',
		text: '연차휴가 일수관리',
		path: '/leave/manage/dayoff/create',
		icon: 'LeaveCreate',
	},
	dayoffApproval: {
		id: 'dayoffApproval',
		text: '휴가신청서 결재',
		path: '/leave/approval',
		icon: 'EventAvailable',
	},
	dayoffProcess: {
		id: 'dayoffProcess',
		text: '휴가신청서 처리조회',
		path: '/leave/process',
		icon: 'LeaveSearch',
	},
	dayoffHistory: {
		id: 'dayoffHistory',
		text: '휴가사용내역조회',
		path: '/leave/history',
		icon: 'EventNote',
	},
	// 연차사용촉진제
	dayoffPromotion: {
		id: 'dayoffPromotion',
		text: '연차사용촉진제',
		path: '/leave/promotion',
		icon: 'LeavePromotion',
	},
	// 비밀번호 변경
	changePassword: {
		id: 'changePassword',
		text: '비밀번호 변경',
		path: '/manage/password',
		icon: 'Lock',
	},
	// dayoff: {
	// 	id: 'dayoff',
	// 	text: '연차휴가 관리',
	// 	path: '/dayoff',
	// 	icon: 'LocalAirport',
	// 	subMenu: {
	// 		dayoff: {
	// 			id: 'dayoff',
	// 			text: '연차 관리',
	// 			path: '/dayoff/manage/dayoff/create',
	// 			icon: 'LocalAirport',
	// 		},
	// 		// 연차생성 마감 관리
	// 		dayoffClose: {
	// 			id: 'dayoffClose',
	// 			text: '연차 마감 관리',
	// 			path: '/dayoff/close',
	// 			icon: 'LocalAirport',
	// 		},

	// 		dayoffApproval: {
	// 			id: 'dayoffApproval',
	// 			text: '연차 승인/반려 처리',
	// 			path: '/dayoff/approvalRequest',
	// 			icon: 'LocalAirport',
	// 		},
	// 		// dayoffProcess: {
	// 		// 	id: 'dayoffProcess',
	// 		// 	text: '연차 사용 내역',
	// 		// 	path: '/dayoff/process',
	// 		// 	icon: 'LocalAirport',
	// 		// },
	// 		dayoffHistory: {
	// 			id: 'dayoffHistory',
	// 			text: '연차 사용 내역',
	// 			path: '/dayoff/history',
	// 			icon: 'LocalAirport',
	// 		},
	// 	},
	// },

	// edms: {
	// 	id: 'electronic_document_management_system',
	// 	text: '전자결재 관리',
	// 	path: '/edms',
	// 	icon: 'ListAlt',
	// 	subMenu: null,
	// },
	// report: {
	// 	id: 'report',
	// 	text: '보고서 기능',
	// 	path: '/report',
	// 	icon: 'Assignment',
	// 	subMenu: null,
	// },
};

export const userMenu = {
	// notice: {
	// 	id: 'notice',
	// 	text: '공지사항',
	// 	path: '/board/notice',
	// 	icon: 'ReportGmailerrorred',
	// },
	notice: {
		id: 'notice',
		text: '공지사항',
		path: '/board/notice',
		icon: 'ReportGmailerrorred',
	},
	payManage: {
		id: 'payManage',
		text: '수당지급 정산일수 등록',
		path: '/payment',
		icon: 'CreditCard',
	},
	remainDayoff: {
		id: 'remainDayoff',
		text: '연차휴가 조회',
		path: '/leave/remain',
		icon: 'EventNote',
	},
	// 휴가신청서 작성
	dayoffApply: {
		id: 'dayoffApply',
		text: '휴가신청서 작성',
		path: '/leave/apply',
		icon: 'EditCalendar',
	},
	// 연차촉진 
	dayoffPromotionUser: {
		id: 'dayoffPromotionUser',
		text: '연차사용촉진제',
		path: '/leave/promotion',
		icon: 'LeavePromotion',
	},
	// dayoffRequest: {
	// 	id: 'dayoffRequest',
	// 	text: '휴가신청',
	// 	path: '/dayoff/request',
	// 	icon: 'EditCalendar',
	// },
	// dayoffRequestDetail: {
	// 	id: 'dayoffRequestDetail',
	// 	text: '휴가신청 상세',
	// 	path: '/dayoff/request/detail',
	// 	icon: 'EditCalendar',
	// },
	// dayoffRequestDetail: {
	// 	id: 'dayoffRequestDetail',
	// 	text: '휴가신청 상세',
	// 	path: '/dayoff/request/detail/:id',
	// 	icon: 'EditCalendar',
	// },
	// 휴가신청서 결재
	dayoffApproval: {
		id: 'dayoffApproval',
		text: '휴가신청서 결재',
		path: '/leave/approval',
		icon: 'EventAvailable',
	},
	// 휴가사용내역 조회
	dayoffHistory: {
		id: 'dayoffHistory',
		text: '휴가사용내역 조회',
		path: '/leave/history',
		icon: 'LeaveSearch',
	},
	// 결재라인 대행
	dayoffApprovalDelegation: {
		id: 'dayoffApprovalDelegation',
		text: '결재권한 위임',
		path: '/leave/Approval-delegation',
		icon: 'PersonSearch',
	},
	// 연차휴가사용계획서
	// dayoffPlan: {
	// 	id: 'dayoffPlan',
	// 	text: '연차휴가사용계획서',
	// 	path: '/dayoff/plan',
	// 	icon: 'Event',
	// },
	// 비밀번호 변경
	changePassword: {
		id: 'changePassword',
		text: '비밀번호 변경',
		path: '/manage/password',
		icon: 'Lock',
		subMenu: null,
	},

	// // 연차 조회
	// dayoffStatus: {
	// 	id: 'dayoffStatus',
	// 	text: '연차 조회',
	// 	path: '/dayoffStatus',
	// 	icon: 'LocalAirport',
	// 	subMenu: {
	// 		// 잔여 연차 조회
	// 		remainDayoff: {
	// 			id: 'remainDayoff',
	// 			text: '잔여 연차 조회',
	// 			path: '/remainDayoff',
	// 			icon: 'LocalAirport',
	// 		},
	// 		// 연차 사용 내역 조회
	// 		usedDayoff: {
	// 			id: 'usedDayoff',
	// 			text: '연차 사용 내역 조회',
	// 			path: '/usedDayoff',
	// 			icon: 'LocalAirport',
	// 		},
	// 	},
	// },
	// // 연차 신청
	// dayoffApply: {
	// 	id: 'dayoffApply',
	// 	text: '연차 관리',
	// 	path: '/dayoffApply',
	// 	icon: 'LocalAirport',
	// 	subMenu: {
	// 		// 연차 신청
	// 		dayoffApply: {
	// 			id: 'dayoffApply',
	// 			text: '연차 내역',
	// 			path: '/dayoffApply',
	// 			icon: 'LocalAirport',
	// 		},
	// 		// 신청 상태 조회
	// 		applyStatus: {
	// 			id: 'applyStatus',
	// 			text: '신청 상태 조회',
	// 			path: '/applyStatus',
	// 			icon: 'LocalAirport',
	// 		},
	// 	},
	// },
	// // 내정보
	// myInfo: {
	// 	id: 'myInfo',
	// 	text: '내정보',
	// 	path: '/myInfo',
	// 	icon: 'AccountCircle',
	// 	subMenu: {
	// 		profile: {
	// 			id: 'profile',
	// 			text: '개인 정보 수정',
	// 			path: '/profile',
	// 			icon: 'SupervisedUserCircle',
	// 			subMenu: null,
	// 		},
	// 		// 비밀번호 변경
	// 		changePassword: {
	// 			id: 'changePassword',
	// 			text: '비밀번호 변경',
	// 			path: '/changePassword',
	// 			icon: 'Lock',
	// 			subMenu: null,
	// 		},
	// 	},
	// },
	// customer_support: {
	// 	id: 'customer_support',
	// 	text: '고객지원센터',
	// 	path: '/cs',
	// 	icon: 'ContactSupport',
	// 	subMenu: {
	// 		guide: {
	// 			id: 'guide',
	// 			text: '사용자 가이드',
	// 			path: '/guide',
	// 			icon: 'Help',
	// 		},
	// 		faq: {
	// 			id: 'faq',
	// 			text: 'FAQ',
	// 			path: '/faq',
	// 			icon: 'Chat',
	// 		},
	// 		contact: {
	// 			id: 'contact',
	// 			text: '문의하기',
	// 			path: '/contact',
	// 			icon: 'MarkChatUnread',
	// 		}
	// 	},
	// },
};
