import * as React from 'react';

const SvgCustomFileDownload = (props) => (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg" className='svg-icon'
        {...props}>
        <path d="M7.5 11.7885L3.23075 7.51925L4.28475 6.43475L6.75 8.9V0.5H8.25V8.9L10.7153 6.43475L11.7693 7.51925L7.5 11.7885ZM1.80775 15.5C1.30258 15.5 0.875 15.325 0.525 14.975C0.175 14.625 0 14.1974 0 13.6923V10.9808H1.5V13.6923C1.5 13.7692 1.53208 13.8398 1.59625 13.9038C1.66025 13.9679 1.73075 14 1.80775 14H13.1923C13.2692 14 13.3398 13.9679 13.4038 13.9038C13.4679 13.8398 13.5 13.7692 13.5 13.6923V10.9808H15V13.6923C15 14.1974 14.825 14.625 14.475 14.975C14.125 15.325 13.6974 15.5 13.1923 15.5H1.80775Z" fill="#797979" />
    </svg>
);

export default SvgCustomFileDownload;
