import React, { useCallback, useContext, useLayoutEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Accordion, { AccordionItem } from '../../../../components/bootstrap/Accordion';
import Button from '../../../../components/bootstrap/Button';
import { useMst } from '../../../../models';
import AlarmService from '../../../../services/AlarmService';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import { Base64 } from 'js-base64';
import { timeForToday, replaceMessageClear } from '../../../../lib/Util';
import Icon from '../../../../components/icon/Icon';
// // import 'moment/locale/ko';

const NotificationList = observer((props) => {
	const { t } = useTranslation(['translation', 'menu']);
	const { user, company, notifiction } = useMst();
	const navigate = useNavigate();
	const { setIsOpen } = props;

	const _readAlarm = useCallback(
		async (alarm, id) => {
			//console.log('READ ALARM > ', alarm.id, alarm.type);
			if (!alarm.read) {
				AlarmService.alarmRead(user.me.company.code, id).then(() => {
					notifiction.getRefresh(user.me.company.code);
					// store.NotificationStore.readList(index);
					// store.NotificationStore.reMoveBadge();
					// props.navigation.navigate(_checkRoute(alarm.type));
				});
			}
			//  else {
			//   props.navigation.navigate(_checkRoute(alarm.type));
			// }
		},
		[user, notifiction]
	);

	const NotiLink = (payload) => {
		let webLink = '';
		switch (payload.data.type) {
			case 'APPROVAL':
			case 'APPROVAL_COMPLETE':
			case 'APPROVAL_REQUEST':
			case 'APPROVAL_REJECT':
			case 'APPROVAL_REQUEST_CANCEL':
			case 'APPROVAL_CANCEL':				
			case 'APPROVAL_APPROVAL':
				webLink = '/leave/request/detail/' + payload.data.params.id;
				break;
			case 'PAYMANAGE':
				webLink = '/payment';
				break;			
			case 'APPROVAL_REQUEST_UNDO':
				webLink = '';
				break;
		}
		if (webLink != '') {
			navigate(webLink);
		}
	};

	return (
		<>
			{notifiction?.get?.list?.map((notis) => {
				let noti = { ...notis };
				noti.data = JSON.parse(noti.data);
				return (
					<div
						key={`notification-${noti.id}`}
						// isDisable={noti.read}
						// color={'light'}
						className={'w-100 px-0 py-1 text-start border border-0 border-light border-bottom d-flex flex-column'}
						style={{ borderStyle: 'dashed !important' }}>
						<div className='d-flex justify-content-between align-items-center w-100 px-3'>
							{/* <div className={`border p-2 border-${noti.read ? 'light' : 'info'}`}>
								<Icon icon='EventAvailable' size='2x' color={noti.read ? 'light' : 'info'} />
							</div> */}
							<Button
								type='button'
								className='flex-grow-1 px-4 text-start'
								onClick={(e) => {
									e.stopPropagation();
									// console.log('>>>>', noti)
									if (!noti.read) {
										 _readAlarm(noti, noti.id);
									}
									if(noti?.data?.path) {
									// 	window.location.href = noti?.data?.webLink;
										navigate(noti?.data?.path);
									}
									setIsOpen(false);
								}}
								rounded={0}>
								<div className={`${noti.read ? 'text-light' : 'text-info'}`}>{t(noti.data.title || noti.title, noti.data)}</div>
								<div className={`ms-2 mt-2 fw-normal ${noti.read ? 'text-light' : ''}`}>{replaceMessageClear(t(noti.data?.message || noti?.message, noti?.data || {}))}</div>
								<div className={`ms-2 mt-1 fw-light ${noti.read ? 'text-light' : 'text-muted'}`}>
									{dayjs(noti.createdAt).format('YYYY-MM-DD HH:mm')} <small>{timeForToday(noti.createdAt)}</small>
								</div>
							</Button>
							<Button
								type='button'
								className='p-0'
								rounded={0}
								onClick={(e) => {
									e.stopPropagation();
									if (!noti.read) {
										_readAlarm(noti, noti.id);
									}
									if(noti?.data?.path) {
										// 	window.location.href = noti?.data?.webLink;
											navigate(noti?.data?.path);
									}
									// NotiLink(noti);
									setIsOpen(false);
								}}>
								<Icon icon='ChevronRight' size='2x' color={noti.read ? 'light' : 'dark'} />
							</Button>
						</div>
					</div>
				);
			})}

			{notifiction?.get?.list?.length === 0 && <div className='lead text-center py-5'>{t('요청된 알림이 없습니다')}</div>}
		</>
	);
});
export default NotificationList;
