const EMAIL = {
    code : 'A010',
    name : '이메일',
    value : 'email'
}

const NOTICE = {
    code: 'A015',
    name: '공지사항',
    value : 'notice'
}
const PUSH =  {
    code: 'A020',
    name: '푸시알림',
    value : 'push'
}

const NOTIFICATION_CHANNELS
 = {
    EMAIL, 
    NOTICE,
    PUSH, 
    // [EMAIL.code] : EMAIL,
    // [NOTICE.code] : NOTICE,
    // [PUSH.code] : PUSH
}

export default NOTIFICATION_CHANNELS;