import { t } from 'mobx-state-tree';
import { APIClient } from '../lib/apiHelper';

const { get, post, put, remove } = new APIClient();

export default {
	/**
	 * 이미지 호출
	 */
	image: (path, fileName) => get(`/images${path}/${fileName}`),
	/**
	 * 현재 비밀번호 확인
	 * @param {*} currentPassword
	 * @returns
	 */
	checkCurrentPassword: (currentPassword) => post('/auth/check-current-password', currentPassword),
	/**
	 * 인증번호 발송
	 * @param {*} companyCode
	 * @param {*} id
	 * @param {*} type
	 * @param {*} code
	 */
	sendAuthCode: (data) => post(`/auth/send-auth-code`, data),
	/**
	 * 인증번호 확인
	 */
	verifyAuthCode: (data) => post(`/auth/verify-auth-code`, data),
	/**
	 * 이메일 인증
	 * @method GET
	 * @param {STRING} email
	 */
	emailAuth: (email) => get(`/auth/email-auth?email=${email}`),
	/**
	 * 이메일 인증 코드 확인
	 * @method GET
	 * @param {STRING} email
	 * @param {STRING} code
	 */
	emailAuthConfirm: (email, code) => get(`/auth/email-auth-confirm?email=${email}&code=${code}`),
	/**
	 * 이메일 존재 여부 확인
	 * @method GET
	 * @param {STRING} email
	 */
	isExistEmail: (email) => get(`/auth/is-exist-email?email=${email}`),
	/**
	 * 이메일 존재 여부 확인
	 * @method POST
	 * @param {STRING} email
	 */
	isExistEmails: (companyCode, emails) => post(`/auth/${companyCode}/is-exist-emails`, emails),
	/**
	 * 메일 인증 코드 확인
	 */
	isVerifyEmail: (code) => get('/auth/verifyEmail?code=' + code),
	/**
	 * 시스템관리자 등록
	 * @param {*} user
	 * @returns
	 */
	registerSystemAdmin: (user) => post('/systemAdmin', user),
	modifySystemAdmin: (user) => put('/systemAdmin', user),
	deleteSystemAdmin: (id) => remove('/systemAdmin/' + id),
	/**
	 * 시스템관리자 리스트
	 * @returns
	 */
	systemAdminList: () => get('/systemAdmin'),
	/**
	 * 회원 가입
	 * @method POST
	 * @param {OBJECT} user
	 * =========================
	 * @param {STRING} email
	 * @param {STRING} password
	 * @param {STRING} name
	 */
	register: (user) => post('/auth/register', user),
	/**
	 * 로그인
	 * @method POST
	 * @param {STRING} email
	 * @param {STRING} password
	 */
	login: (user) => post('/auth/login', user),
	logout: () => get('/auth/logout'),
	/**
	 * 내정보 가져 오기
	 * @method GET
	 * no-params
	 */
	me: () => get('/auth/me'),
	/**
	 * 비밀번호 찾기 이메일 인증
	 * @method GET
	 * @param {STRING} email
	 */
	forgetPassword: (email) => get(`/auth/forget-password?email=${email}`),
	/**
	 * 비밀번호 찾기 이메일 인증
	 * @method GET
	 * @param {STRING} email
	 * @param {STRING} code
	 */
	forgetPasswordConfirm: (email, code) => get(`/auth/forget-password-confirm?email=${email}&code=${code}`),
	/**
	 * 비밀번호 찾기 이메일 인증
	 * @method GET
	 * @param {STRING} email
	 * @param {STRING} password
	 */
	changePassword: async (email, password, companyCode=null) => {
		const dummy = password.split('').reverse().join('');
		const passwordData = { email, password, dummy, companyCode };

		return put('/auth/change-password', passwordData);
	},
	/**
	 * 토큰 접속
	 */
	token: (token) => get('/auth/token?check=' + token),
	/**
	 * 알림
	 */
	alarmList: (companyCode) => get(`/alarm/${companyCode}`),
};
