import React, { lazy, Suspense, useCallback, useContext, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useTour } from '@reactour/tour';
import Button, { ButtonGroup } from '../../../components/bootstrap/Button';
import { HeaderRight } from '../../../layout/Header/Header';
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../../../components/bootstrap/OffCanvas';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../../../components/bootstrap/Dropdown';
import Icon from '../../../components/icon/Icon';
import ThemeContext from '../../../contexts/themeContext';
import LANG, { getLangWithKey } from '../../../lang';
import showNotification from '../../../components/extras/showNotification';
import useDarkMode from '../../../hooks/useDarkMode';
import Popovers from '../../../components/bootstrap/Popovers';
import Badge from '../../../components/bootstrap/Badge';
import { useChannelIOApi } from 'react-channel-plugin';
import { useMst } from '../../../models';
import { observer } from 'mobx-react';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import { Configs as config, Configs } from '../../../models/Configs';
import { useToasts } from 'react-toast-notifications';
import Toasts from '../../../components/bootstrap/Toasts';

import AlarmService from '../../../services/AlarmService';
import firebaseInit from '../../../firebaseInit';
import firebase from 'firebase/compat/app';
import { getItem, setItem } from '../../../lib/localstorage';

const NotificationList = lazy(() => import('./components/NotificationList'));
// const NotificationSetting = lazy(() => import('./components/NotificationSetting'));
const User = lazy(() => import('../../../layout/User/User'));

// eslint-disable-next-line react/prop-types
const CommonHeaderRight = ({ beforeChildren, afterChildren }) => {
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();
	const [settingFlag, setSettingFlag] = useState(false);
	const { fullScreenStatus, setFullScreenStatus, lang, setLang } = useContext(ThemeContext);

	const { i18n } = useTranslation();
	const { t } = useTranslation(['translation', 'menu']);

	const { showMessenger, updateUser } = useChannelIOApi();

	const styledBtn = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'lg',
	};
	const [offcanvasStatus, setOffcanvasStatus] = useState(false);

	const { user, notifiction } = useMst();
	const { addToast } = useToasts();

	const changeLanguage = (lng) => {
		i18n
			.changeLanguage(lng)
			.then(() => {
				dayjs.locale(lng);
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon={getLangWithKey(lng)?.icon} size='lg' className='me-1' />
						<span>{`${getLangWithKey(lng)?.text}`}</span>
					</span>,
					t('You updated the language of the site.')
				);
			})
			.then(() => {
				setLang(lng);
			});
	};

	const allReadHandler = useCallback(async () => {
		AlarmService.alarmReadAll(user.me.company.code).then((response) => {
			if (response.data) {
				// console.log('response >>',response)
				showNotification('알람', '알람을 모두 읽었습니다.', 'info');
				notifiction.getRefresh(user.me.company.code);
				setOffcanvasStatus(false);
			} else {
				// showNotification('알람','모두읽기 실패.','danger')
			}
		});
	}, [user, notifiction]);

	/**
	 * Language attribute
	 */
	useLayoutEffect(() => {
		document.documentElement.setAttribute('lang', i18n.language);
		dayjs.locale(i18n.language);
		// if(user.me?.id > 0) {
		// 	// console.log('updateUser >>');
		// 	updateUser({
		// 		profile: {
		// 			name: user.me.name,
		// 			license: user.me.license,
		// 			email: user.me.email,
		// 			mobileNumber: user.me.phone,
		// 		}
		// 	})
		// }
	});

	// const { setIsOpen } = useTour();

	return (
		<HeaderRight>
			<div className='row g-3 align-items-center'>
				{beforeChildren}

				{/* <div className='col-auto'>
				<Popovers trigger='hover' desc={t('다크 / 화이트 모드')}>
					<Button
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...styledBtn}
						icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
						onClick={() => setDarkModeStatus(!darkModeStatus)}
						aria-label='Toggle fullscreen'
						data-tour='dark-mode'
					/>
				</Popovers>
			</div> */}

				{/* 채널톡 */}
				<div className='col-auto'>
					<Popovers trigger='hover' desc={<span className='text-nowrap'>{t('채팅 문의')}</span>}>
						<Button
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...styledBtn}
							icon={'ChannelTolk'}
							onClick={showMessenger}
							aria-label='Toggle fullscreen'
							data-tour='dark-mode'
						/>
					</Popovers>
				</div>

				{/* Lang Selector */}
				<div className='col-auto'>
					<Dropdown>
						<DropdownToggle hasIcon={false}>
							<Button
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...styledBtn}
								icon={getLangWithKey(i18n.language)?.icon}
								aria-label='Change language'
								data-tour='lang-selector'
							/>
						</DropdownToggle>
						<DropdownMenu isAlignmentEnd data-tour='lang-selector-menu'>
							{Object.keys(LANG).map((i) => (
								<DropdownItem key={LANG[i].lng}>
									<Button isDisable={lang === LANG[i].lng} icon={LANG[i].icon} onClick={() => changeLanguage(LANG[i].lng)}>
										{LANG[i].text}
									</Button>
								</DropdownItem>
							))}
						</DropdownMenu>
					</Dropdown>
				</div>

				{/*	Full Screen */}
				<div className='col-auto'>
					<Popovers trigger='hover' desc={t('전체화면')}>
						<Button
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...styledBtn}
							icon={fullScreenStatus ? 'FullscreenExit' : 'Fullscreen'}
							onClick={() => setFullScreenStatus(!fullScreenStatus)}
							aria-label='Toggle dark mode'
						/>
					</Popovers>
				</div>

				{/*	Notifications */}
				<div className='col-auto position-relative'>
					<Button
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...styledBtn}
						icon={config.get?.fcmCode ? 'Notifications' : 'NotificationsOff'}
						onClick={async () => {
							if (!config.get?.fcmCode) {
								// 요청하기
								let messaging = null;
								if (firebase.messaging.isSupported() && !messaging) {
									messaging = firebaseInit.messaging();
									Notification.requestPermission().then(async (permission) => {
										// console.log('알림 권한:', permission);
										try {
											if (permission === 'granted') {
												// console.log('알림 권한 허용됨');
												const token = await messaging.getToken(messaging, {
													vapidKey: process.env.REACT_APP_VAPID_KEY,
												});
												console.log('토큰:', token);
												setItem('webToken', token); // 추후 지워도 될듯~
												Configs.setFcm(token);
												const connectType = getItem('user-connect-type');
												// console.log('connectType:', connectType);
												if (connectType != 'token' && user.me.id > 0 && Configs.get.uuid && Configs.get.publicIp && Configs.get.os && token /*  && Configs.get.fcmCode */) {
													Configs.sendDevice();
												}
											} else {
												console.error('알림 권한 거부됨');
											}
										} catch (error) {
											console.error('Error getting permission or token:', error);
										}
									});
								}

								console.log('요청하기');
							}

							if (!offcanvasStatus) {
								setOffcanvasStatus(true);
							}
						}}
						aria-label='Notifications'
					/>
					{notifiction?.get?.badge > 0 && (
						<Badge color={'danger'} className='position-absolute top-0 end-0'>
							{notifiction?.get?.badge > 99 ? '99+' : notifiction?.get?.badge}
						</Badge>
					)}
				</div>

				{/*  profile */}
				<div className='col-auto'>
					<User />
				</div>

				{afterChildren}
			</div>

			<OffCanvas id='notificationCanvas' titleId='notificationCanvas' placement='end' isOpen={offcanvasStatus} setOpen={setOffcanvasStatus} isBackdrop={true} isBodyScroll={true}>
				<OffCanvasHeader setOpen={setOffcanvasStatus}>
					<OffCanvasTitle className='w-100 d-flex justify-content-end' id='notificationCanvas'>
						{/* <div>
						{t('알림')}
						<small>({notifiction?.get?.badge || 0})</small>
					</div> */}
						<div className='me-3'>
							<ButtonGroup isToolbar>
								{(notifiction?.get?.badge || 0) > 0 && (
									<Button color='dark' size='sm' onClick={() => allReadHandler()}>
										{'모두 읽음'}
									</Button>
								)}
								<Button
									icon={'GearFill'}
									color='light'
									onClick={(e) => {
										e.stopPropagation();
										// setSettingFlag(!settingFlag);
									}}
								/>
							</ButtonGroup>
						</div>
					</OffCanvasTitle>
				</OffCanvasHeader>
				<OffCanvasBody className='border border-top border-light p-0'>
					{/* {settingFlag ? <NotificationSetting /> : <NotificationList />} */}
					<Suspense fallback={<div>{t('알림 정보를 읽어오고 있습니다.')}</div>}>
						<NotificationList setIsOpen={setOffcanvasStatus} />
					</Suspense>
				</OffCanvasBody>
			</OffCanvas>
		</HeaderRight>
	);
};
CommonHeaderRight.propTypes = {
	beforeChildren: PropTypes.node,
	afterChildren: PropTypes.node,
};
// CommonHeaderRight.defaultProps = {
// 	beforeChildren: null,
// 	afterChildren: null,
// };

export default observer(CommonHeaderRight);
