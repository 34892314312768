import React, { Suspense, lazy, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';

import Brand from '../Brand/Brand';
import Navigation, { NavigationLine, NavigationTitle } from '../Navigation/Navigation';

import { useMst } from '../../models';
import ThemeContext from '../../contexts/themeContext';
import showNotification from '../../components/extras/showNotification';
import LANG, { getLangWithKey } from '../../lang';

import Icon from '../../components/icon/Icon';
import Button from '../../components/bootstrap/Button';
import Tooltips from '../../components/bootstrap/Tooltips';
import useDarkMode from '../../hooks/useDarkMode';
import useAsideTouch from '../../hooks/useAsideTouch';
import Popovers from '../../components/bootstrap/Popovers';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../../components/bootstrap/Dropdown';
import { adminMenu, authMenu, commonsBottomMenu, commonsMenu, systemAdminMenu, userMenu } from '../../menu';
import { getMenuWithActions, getMenuWithActionsUser } from '../../lib/Util';
import { getItem, removeItem } from '../../lib/localstorage';
import { Base64 } from 'js-base64';
import AuthService from '../../services/AuthService';

// import firebaseInit from '../../firebaseInit';
// import firebase from 'firebase/compat/app';
// import { useToasts } from 'react-toast-notifications';
// import Toasts from '../../components/bootstrap/Toasts';

// const User = lazy(() => import('../User/User'));
// let messaging = null;
const Aside = observer(props => {

	// console.log('Aside observer');

	const { asideStatus, setAsideStatus, asideSubStatus, setAsideSubStatus, asideSubMenuStatus, asideMenu, mobileDesign, lang, setLang, noticeCount, menu } = useContext(ThemeContext);
	const { asideStyle, asideSubStyle, touchStatus, hasTouchButton, asideWidthWithSpace, asideSubWidthWithSpace, x, x2 } = useAsideTouch();
	const isModernDesign = process.env.REACT_APP_MODERN_DESGIN === 'true';
	const constraintsRef = useRef(null);

	const { t } = useTranslation(['translation', 'menu']);
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();
	const { user, company, navtabmenus, notifiction } = useMst();
	const userType = getItem('userType');
	// console.log('user', user?.me)

	const navigate = useNavigate();
	const location = useLocation(); 
	const { i18n } = useTranslation();

	const styledBtn = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'lg',
	};
	
	// const refreshMenu = () => {
	// 	let menus = [];
	// 	switch(user.me.type) {
	// 		case 'SYSTEM':
	// 			menus = systemAdminMenu;
	// 			break;
	// 		case 'ADMIN':
	// 			menus = getMenuWithActions(adminMenu, user.me);
	// 			break;
	// 		case 'USER':
	// 			menus = getMenuWithActionsUser(userMenu, {
	// 				...user.me,
	// 				isApproval: company.get?.isApproval || false,
	// 			});
	// 			break;
	// 		default:
	// 			menus = [];
	// 			break;
	// 	}
	// 	setMenu(menus);
	// }
	// refreshMenu();

	// if (firebase.messaging.isSupported() && !messaging) {
	// 	messaging = firebaseInit.messaging();
	// }
	// console.log('user.me', user.me)
	// const originalSession = getItem('original-refresh-token');
	// const originalUserId = getItem('original-user-id');

	// const cleaerRestInfo = async () => {
	// 	// await company.setRestInfo({})
	// };
	// const setRestInfo = async () => {
	// 	// await CompanyService.restdayInfo({ companyId: company.id, userId: user.id }).then( async res => {
	// 	// 	console.log('res >> ',res)
	// 	// 	if(res?.data)
	// 	// 		await company.setRestInfo(res.data);
	// 	// });
	// };

	const changeLanguage = (lng) => {
		i18n
			.changeLanguage(lng)
			.then(() => {
				dayjs.locale(lng);
				// dayjs.updateLocale(lng);
				// dayjs.defineLocale(lng);
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon={getLangWithKey(lng)?.icon} size='lg' className='me-1' />
						<span>{`${getLangWithKey(lng)?.text}`}</span>
					</span>,
					t('You updated the language of the site.')
				);
			})
			.then(() => {
				setLang(lng);
			});
	};

	useEffect(() => {
		// console.log("----------------", JSON.stringify([]));
		// const navTabls = JSON.parse(getItem(`navTabMenu_${company.get.id}_${company.get.isDoc ? 'admin':'user'}`) || JSON.stringify([]));
		// // console.log('load tabs', navTabls);
		// if(navtabmenus?.setMenus)
		// 	navtabmenus?.setMenus(navTabls);
		// if (company.get.isDoc) {
		// 	// console.log('admin');
		// 	cleaerRestInfo();
		// } else {
		// 	// console.log('user');
		// 	setRestInfo();
		// }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [/* company.get.isDoc, */ navtabmenus]);

	// useEffect(() => {
	// 	if (!isPermittedPage(user.me.permissions?.menuRole, location.pathname)) {
	// 		alert('접근 권한이 없는 페이지 입니다.')
	// 		navigation(-1)
	// 	} else if (location.pathname === '/e-approval/setting/outOfOffice' && !(user.me.permissions?.detailRole?.approval?.absentee)) {
	// 		// 부재설정 기능은 권한관리의 detailRole에 속해 있어서, 전체적인 page Layout Aside.js 에서 판단하는것이 아니라 이곳에다가 따로 path를 부여하는식으로 체크 했습니다.
	// 		alert('접근 권한이 없는 페이지 입니다.')
	// 		navigation(-1)
	// 	}
	// }, [location.pathname, navigation, user.me.permissions?.menuRole, user.me.permissions?.detailRole?.approval?.absentee])


	return (
		<>
			<motion.aside
				style={asideStyle}
				className={classNames(
					'aside',
					'bg-white',
					{ open: asideStatus },
					{
						'aside-touch-bar': hasTouchButton && isModernDesign,
						'aside-touch-bar-close': !touchStatus && hasTouchButton && isModernDesign,
						'aside-touch-bar-open': touchStatus && hasTouchButton && isModernDesign,
					}
				)}>
				<div className='aside-head'>
					<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} asideSubStatus={asideSubStatus} setAsideSubStatus={setAsideSubStatus} />
				</div>
				<div className='aside-body pt-4'>
					{/* {user?.me?.type === 'USER' && (
					<div className='navigation-item'>
						<div
							className='navigation-link cursor-pointer'
							onClick={() => {
								navigation('/dashboard');
							}}>
							<div className={`navigation-link-info fw-bold rounded bg-light w-100 py-2 px-3 d-flex justify-content-between`}>
								<span>휴가신청서 결재</span>
							</div>
						</div>
					</div>
				)} */}

					{/* {originalSession && user.me.type == 'USER' && (
						<div className='navigation-item'>
							<div
							className='navigation-link cursor-pointer'
							onClick={ async () => {								

								removeItem('original-refresh-token');
								removeItem('original-user-id');

								window.open(
									(process.env.NODE_ENV === 'development' ? 'http://dayoff.insa.com:3000/auth/?key=' : 'https://dayoff.neocube.kr/auth/?key=') + Base64.encode(`${originalSession}###${originalUserId}`),
									'_self',
									'noopener,noreferrer'
								);								
							}}>
							<div className={`navigation-link-info fw-bold rounded bg-light w-100 py-2 px-3 d-flex justify-content-center ${!user.me.isAdmin ? 'flex-row-reverse' : 'flex-row'}`}>
								관리자 복귀
							</div>
							
						</div>
						</div>
					)} */}
					{/* {company.get.isAdmin && ()} */}
					{/* {user?.me?.type === 'ADMIN' && (
					<div className='navigation-item'>
						<div
							className='navigation-link cursor-pointer'
							onClick={() => {
								user.setIsAdmin(!user.me.isAdmin);
								// 상단 탭 불러오기
								navigation('/');
							}}>
							<div className={`navigation-link-info fw-bold rounded bg-light w-100 py-2 px-3 d-flex justify-content-between ${!user.me.isAdmin ? 'flex-row-reverse' : 'flex-row'}`}>
								<span>{user.me.isAdmin ? '사용자' : '관리자'} 전환</span>
								<span className={`${user.me.isAdmin ? 'bg-success' : 'bg-info'} rounded`} style={{width: 20}}>&nbsp;</span>
							</div>
							
						</div>
					</div>
					)} */}

					<Navigation menu={commonsMenu} id='aside-common-menu' />

					{userType=== 'SYSTEM' ? (
						<>
							{/* <NavigationTitle>시스템 관리자</NavigationTitle> */}
							<Navigation menu={menu} id='aside-menu-system' />
						</>
					) : null}
					{userType === 'ADMIN' ? (
						<>
							{/* <NavigationTitle>관리자</NavigationTitle> */}
							<Navigation menu={menu} id='aside-menu-admin' />
							{+user.me.isUser > 0 && (
								<div className='navigation'>
									<div className={`navigation-item ${asideStatus ? '' : 'd-flex justify-content-center'}`}>
										<Button type='button' color='light' rounded={1} className='fw-light text-dark navigation-link' icon='SyncAlt' isOutline onClick={(e) => {
											e.stopPropagation();

											const refreshToken = getItem('refresh-token');
											window.open(
												(process.env.REACT_APP_TYPE === 'prod'
													? 'https://dayoff.hellotime.co.kr/auth/?key='
													: process.env.REACT_APP_TYPE === 'dev'
														? 'https://dayoff.neocube.kr/auth/?key='
														: 'http://dayoff.insa.com:3000/auth/?key=') + Base64.encode(`${refreshToken}###${user.me.isUser}###USER###${dayjs().toISOString()}`),
												'helloUser'
												// '_blank',
												// 'noopener,noreferrer'
											);
										}}>								
										<span className='navigation-text'>나의 사용자 모드로 전환</span>
										</Button>
									</div>
								</div>
							)}
						</>
					) : null}
					{userType === 'USER' ? (
						<>
							{/* <NavigationTitle>사용자</NavigationTitle> */}
							<Navigation menu={menu} id='aside-menu-user' />
							{+user.me.isAdmin > 0 && (
								<div className='navigation'>
									<div className={`navigation-item ${asideStatus ? '' : 'd-flex justify-content-center'}`}>
										<Button type='button' color='light' rounded={1} className='fw-light text-dark navigation-link' icon='SyncAlt' isOutline onClick={(e) => {
											e.stopPropagation();
											
											const refreshToken = getItem('refresh-token');
											window.open(
												(process.env.REACT_APP_TYPE === 'prod'
													? 'https://dayoff-ops.hellotime.co.kr/auth/?key='
													: process.env.REACT_APP_TYPE === 'dev'
														? 'https://dayoff-ops.neocube.kr/auth/?key='
														: 'http://dayoff-ops.insa.com:3000/auth/?key=') + Base64.encode(`${refreshToken}###${user.me.isAdmin}###ADMIN###${dayjs().toISOString()}`),
												'helloAdmin'
												// '_blank',
												// 'noopener,noreferrer'
											);
										}}>								
										<span className='navigation-text'>관리자 모드로로 전환</span>
										</Button>
									</div>
								</div>
							)}
						</>
					) : null}

					{/* {user?.me?.type !== 'SYSTEM' ? (
						<Navigation menu={commonsBottomMenu} id='aside-common-bottom-menu' />
					) : null} */}

					{/*  사용자 전용 메뉴 */}
					{/* {!company.get.isDoc && (
						<>
							<Navigation menu={(company.get.isAdmin || (user.me.workGubun != '기능직' && user.me.workGubun != '현장직')) ? usersMenu : Object.values(usersMenu).filter(menu => menu.id != 'approvalDocument')} id='aside-menu-two' />
						</>
					)} */}
					{/*  관리자 전용 메뉴 */}
					{/* {company.get.isDoc && (
						<>
							<Navigation menu={getRoleFilteredMenu(user.me.permissions.menuRole, 'admin')} id='aside-menu-two' />
						</>
					)} */}
				</div>
				<div className='aside-foot'>

					<Button
						// icon='Logout'
						className='d-block d-sm-none text-decoration-underline text-black-50'
						onClick={async () => {
							AuthService.logout().then(async () => {
								await Promise.all([removeItem('access-token'), removeItem('refresh-token'), user.logout()]).then(() => {
									navigate(`/${authMenu.login.path}`);
								});
							});
						}}>
						{t('로그아웃')}
					</Button>																

					{/* 사용자 버튼 */}
					{/* <Suspense
						fallback={
							<Dropdown className={'nav-user-wrap'}>
								<DropdownToggle hasIcon={false}>
									<div className='user rounded bg-l25-light'></div>
								</DropdownToggle>
								<DropdownMenu></DropdownMenu>
							</Dropdown>
						}>
						<User />
					</Suspense> */}
				</div>
				{/* Aside 닫기 버튼(모바일) */}
				{asideStatus && mobileDesign && (
					<div className='position-absolute' style={{ left: '15.5rem', top: '0.5rem' }}>
						<Button
							type='button'
							aria-label='Toggle Aside'
							color='light'
							rounded={2}
							className='p-2'
							isLight={true}
							onClick={() => {
								setAsideStatus(!asideStatus);
								setAsideSubStatus(!asideSubStatus);
							}}>
							<Icon icon='Close' size='3x' />
						</Button>
					</div>
				)}
			</motion.aside>

			{/* SUB */}
			{asideSubMenuStatus && (
				<motion.asides
					style={asideSubStyle}
					className={classNames(
						'aside-sub',
						//{ 'd-none': !asideSubStatus && hasTouchButton && isModernDesign },
						{ open: asideSubStatus },
						{
							'aside-touch-bar': hasTouchButton && isModernDesign,
							'aside-touch-bar-close': !touchStatus && hasTouchButton && isModernDesign,
							'aside-touch-bar-open': touchStatus && hasTouchButton && isModernDesign,
						}
					)}>
					<div className='aside-sub-head'></div>
					<div className='aside-sub-body'>
						<Navigation menu={asideMenu} id='aside-sub-dashboard' />
					</div>
					<div className='aside-sub-foot'></div>
				</motion.asides>
			)}

			{asideStatus && hasTouchButton && isModernDesign && (
				<>
					<motion.div className='aside-drag-area' ref={constraintsRef} />
					<Tooltips title={t('Toggle Aside')} flip={['top', 'right']}>
						<motion.div
							className='aside-touch'
							drag='x'
							whileDrag={{ scale: 1.2 }}
							whileHover={{ scale: 1.1 }}
							dragConstraints={constraintsRef}
							// onDrag={(event, info) => console.log(info.point.x, info.point.y)}
							dragElastic={0.1}
							style={{ x, zIndex: 1039 }}
							onClick={() => {
								// console.log('x.get()', x.get())
								// console.log('x2.get()', x2.get())
								// console.log('>>', x2.get() === 0 ? asideSubWidthWithSpace : 0)
								x.set(x.get() === 0 ? asideWidthWithSpace : 0);
								x2.set(x2.get() === 0 ? asideSubWidthWithSpace : 0);
							}}
						/>
					</Tooltips>
				</>
			)}
		</>
	);
});

export default Aside;
