import React, { lazy } from 'react';
// import { componentsMenu, dashboardMenu, demoPages, layoutMenu, extraMenu, adminsMenu, adminCompnaySettingMenu, usersMenu, commonsMenu, noticeListMenu, pawsswordFind, eapproval } from '../menu';
import { adminMenu, authMenu, systemAdminMenu, userMenu } from '../menu';
import { rootStore } from '../models';
import { getItem } from '../lib/localstorage';

/**
 * Lazy page
 */
const AUTH = {
	LOGIN: lazy(() => import('../pages/CommonMenu/Login/LoginNew')),
	PASSWD_RESET: lazy(() => import('../pages/CommonMenu/Login/PasswdResetNew')),
	VERIFYEMAIL: lazy(() => import('../pages/CommonMenu/SignUp/VerifyEmail')),
	SIGNUP: lazy(() => import('../pages/CommonMenu/SignUp/SignUpNew')),
	SENDMAIL: lazy(() => import('../pages/CommonMenu/SignUp/SendMail')),
	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
	CHANGE_PASSWORD: lazy(() => import('../pages/user/ChangePassword')),
};

const SYSTEMADMIN = {
	DASHBOARD: lazy(() => import('../pages/system/Dashboard')),
	BOARD: lazy(() => import('../pages/common/Board/BoardList')),
	BOARD_WRITE: lazy(() => import('../pages/common/Board/BoardWrite')),
	BOARD_VIEW: lazy(() => import('../pages/common/Board/BoardView')),
	SETTING: lazy(() => import('../pages/system/SystemSetting')),
	SETTING_DETAIL: lazy(() => import('../pages/system/SystemSettingDetail')),
	SYSTEM_ADMIN: lazy(() => import('../pages/system/SystemAdmin')),
	SALES_MANAGE: lazy(() => import('../pages/system/SalesManage')),
	// CHANGE_PASSWORD: lazy(() => import('../pages/user/ChangePassword')),
	// SETTING_CODE: lazy(() => import('../pages/system/SystemSettingCode')),
};

const ADMIN = {
	DASHBOARD: lazy(() => import('../pages/admin/Dashboard')),
	BOARD: lazy(() => import('../pages/common/Board/BoardList')),
	BOARD_WRITE: lazy(() => import('../pages/common/Board/BoardWrite')),
	BOARD_VIEW: lazy(() => import('../pages/common/Board/BoardView')),
	SETTING_POLICY: lazy(() => import('../pages/admin/PolicySetting')),
	SETTING_HOLIDAY: lazy(() => import('../pages/system/HolidaySetting')),
	SETTING_CODE: lazy(() => import('../pages/system/CodeSetting')),
	SETTING_UPLOAD: lazy(() => import('../pages/system/UploadSetting')),
	// SETTING_DEPART: lazy(() => import('../pages/system/DepartSetting')),
	HR_USERS: lazy(() => import('../pages/hr/User')),
	DAYOFF_MANAGE: lazy(() => import('../pages/dayoff/Manage')),
	DAYOFF_CLOSE: lazy(() => import('../pages/dayoff/Close')),
	DAYOFF_APPROVAL_LINE: lazy(() => import('../pages/dayoff/ApprovalLineManage')),
	DAYOFF_APPROVAL_DELEGATION: lazy(() => import('../pages/dayoff/ApprovalDelegation')),
	DAYOFF_APPROVAL: lazy(() => import('../pages/user/dayoff/UserRequestApprovalList')),
	DAYOFF_PROCESS: lazy(() => import('../pages/user/dayoff/UserRequestProcess')),
	DAYOFF_REQUEST: lazy(() => import('../pages/user/dayoff/UserRequest')),
	DAYOFF_REQUEST_LIST: lazy(() => import('../pages/user/dayoff/UserRequestList')),
	DAYOFF_PROMOTION: lazy(() => import('../pages/dayoff/LeavePromotion')),
	// CHANGE_PASSWORD: lazy(() => import('../pages/user/ChangePassword')),
};

const USER = {
	DASHBOARD: lazy(() => import('../pages/user/Dashboard')),
	BOARD: lazy(() => import('../pages/common/Board/BoardList')),
	BOARD_WRITE: lazy(() => import('../pages/common/Board/BoardWrite')),
	BOARD_VIEW: lazy(() => import('../pages/common/Board/BoardView')),
	DAYOFF_MANAGE: lazy(() => import('../pages/user/dayoff/Manage')),
	DAYOFF_APPROVAL: lazy(() => import('../pages/user/dayoff/UserRequestApprovalList')),
	DAYOFF_APPROVAL_DELEGATION: lazy(() => import('../pages/dayoff/ApprovalDelegation')),
	DAYOFF_REQUEST_LIST: lazy(() => import('../pages/user/dayoff/UserRequestList')),
	DAYOFF_REMAIN: lazy(() => import('../pages/user/dayoff/UserRequestRemain')),
	DAYOFF_PAYMANAGE: lazy(() => import('../pages/user/dayoff/UserPayManage')),
	DAYOFF_REQUEST: lazy(() => import('../pages/user/dayoff/UserRequest')),
	DAYOFF_PROMOTION: lazy(() => import('../pages/user/dayoff/UserPromotion')),
	// CHANGE_PASSWORD: lazy(() => import('../pages/user/ChangePassword')),
};

/**
 * Setting Route
 */
const authRoute = [
	{
		path: authMenu.VerifyEmail.path,
		element: <AUTH.VERIFYEMAIL />,
		exact: true,
	},
	{
		path: authMenu.login.path,
		element: <AUTH.LOGIN />,
		exact: true,
	},
	{
		path: authMenu.signUp.path,
		element: <AUTH.SIGNUP />,
		exact: true,
	},
	{
		path: authMenu.PasswdReset.path,
		element: <AUTH.PASSWD_RESET />,
		exact: true,
	},
	{
		path: authMenu.sendMail.path,
		element: <AUTH.SENDMAIL />,
		exact: true,
	},
	{
		path: authMenu.page404.path,
		element: <AUTH.PAGE_404 />,
		exact: true,
	},
	{
		path: '/auth',
		element: <AUTH.LOGIN />,
		exact: true,
	},
	{
		path: authMenu.changePassword.path,
		element: <AUTH.CHANGE_PASSWORD />,
		exact: true,
	}
];

const systemAdminRoute = [
	{
		path: '/',
		element: <SYSTEMADMIN.DASHBOARD />,
		exact: true,
	},
	{
		path: '/board/:type',
		element: <SYSTEMADMIN.BOARD />,
		exact: true,
	},
	{
		path: '/board/:type/:action',
		element: <SYSTEMADMIN.BOARD_WRITE />,
		exact: true,
	},
	{
		path: '/board/:type/:action/:id',
		element: <SYSTEMADMIN.BOARD_WRITE />,
		exact: true,
	},
	{
		path: '/board/:type/view/:id',
		element: <SYSTEMADMIN.BOARD_VIEW />,
		exact: true,
	},
	{
		path: systemAdminMenu.system_setting.path,
		element: <SYSTEMADMIN.SETTING />,
		exact: true,
	},
	{
		path: systemAdminMenu.system_setting.path + '/:code',
		element: <SYSTEMADMIN.SETTING_DETAIL />,
		exact: true,
	},
	{
		path: systemAdminMenu.system_admin_register.path,
		element: <SYSTEMADMIN.SYSTEM_ADMIN />,
		exact: true,
	},
	{
		path: systemAdminMenu.changePassword.path,
		element: <AUTH.CHANGE_PASSWORD />,
		exact: true,
	},
	{
		path: systemAdminMenu.sales_manage.path,
		element: <SYSTEMADMIN.SALES_MANAGE />,
		exact: true,
	}
	// {
	// 	path: systemAdminMenu.system_code.path,
	// 	element: <SYSTEMADMIN.SETTING_CODE />,
	// 	exact: true,
	// },
	// {
	// 	path: systemAdminMenu.system_code.path+'/:type',
	// 	element: <SYSTEMADMIN.SETTING_CODE />,
	// 	exact: true,
	// }
];

const adminRoute = [
	{
		path: '/',
		element: <ADMIN.DASHBOARD />,
		exact: true,
	},
	// {
	// 	path: adminMenu.setting.subMenu.policy_setting.path,
	// 	element: <ADMIN.SETTING_POLICY />,
	// 	exact: true,
	// },
	{
		path: '/board/:type',
		element: <ADMIN.BOARD />,
		exact: true,
	},
	{
		path: '/board/:type/:action',
		element: <ADMIN.BOARD_WRITE />,
		exact: true,
	},
	{
		path: '/board/:type/p/:page',
		element: <ADMIN.BOARD />,
		exact: true,
	},	
	{
		path: '/board/:type/:action/:id',
		element: <ADMIN.BOARD_WRITE />,
		exact: true,
	},
	{
		path: '/board/:type/view/:id',
		element: <ADMIN.BOARD_VIEW />,
		exact: true,
	},


	{
		path: adminMenu.setting.subMenu.holiday_setting.path,
		element: <ADMIN.SETTING_HOLIDAY />,
		exact: true,
	},
	{
		path: adminMenu.setting.subMenu.codeMgmt.path,
		element: <ADMIN.SETTING_CODE />,
		exact: true,
	},
	{
		path: adminMenu.setting.subMenu.codeMgmt.path + '/:type',
		element: <ADMIN.SETTING_CODE />,
		exact: true,
	},
	{
		path: adminMenu.setting.subMenu.dataUpload.path,
		element: <ADMIN.SETTING_UPLOAD />,
		exact: true,
	},
	// {
	// 	path: adminMenu.setting.subMenu.codeMgmtLeave.path,
	// 	element: <ADMIN.SETTING_CODE />,
	// 	exact: true,
	// },
	{
		path: adminMenu.hr.path, // subMenu.users
		element: <ADMIN.HR_USERS />,
		exact: true,
	},
	// {
	// 	path: adminMenu.dayoff.path,
	// 	element: <ADMIN.DAYOFF_MANAGE />,
	// 	exact: true,
	// },
	{
		path: '/leave/manage/:tab/:tab2', // adminMenu.dayoff.path +
		element: <ADMIN.DAYOFF_MANAGE />,
		exact: true,
	},
	{
		path: '/leave/request/detail/:id',
		element: <ADMIN.DAYOFF_REQUEST />,
		exact: true,
	},
	{
		path: '/leave/request',
		element: <ADMIN.DAYOFF_REQUEST />,
		exact: true,
	},
	// {
	// 	path: adminMenu.dayoff.subMenu.dayoff.path,
	// 	element: <ADMIN.DAYOFF_MANAGE />,
	// 	exact: true,
	// },
	// {
	// 	path: adminMenu.dayoff.subMenu.dayoffClose.path,
	// 	element: <ADMIN.DAYOFF_CLOSE />,
	// 	exact: true,
	// },
	// 결재라인 관리
	{
		path: adminMenu.setting.subMenu.dayoffApprovalLine.path,
		element: <ADMIN.DAYOFF_APPROVAL_LINE />,
		exact: true,
	},
	{
		path: adminMenu.setting.subMenu.dayoffApprovalDelegation.path,
		element: <ADMIN.DAYOFF_APPROVAL_DELEGATION />,
		exact: true,
	},
	{
		path: adminMenu.dayoffApproval.path,
		element: <ADMIN.DAYOFF_APPROVAL />,
		exact: true,
	},
	{
		path: adminMenu.dayoffHistory.path,
		element: <ADMIN.DAYOFF_REQUEST_LIST />,
		exact: true,
	},
	{
		path: adminMenu.dayoffProcess.path,
		element: <ADMIN.DAYOFF_PROCESS />,
		exact: true,
	},
	{
		path: adminMenu.dayoffPromotion.path,
		element: <ADMIN.DAYOFF_PROMOTION />,
		exact: true,
	},
	{
		path: adminMenu.dayoffPromotion.path + '/:tab',
		element: <ADMIN.DAYOFF_PROMOTION />,
		exact: true,
	},
	{
		path: adminMenu.changePassword.path,
		element: <AUTH.CHANGE_PASSWORD />,
		exact: true,
	},
];

const userRoute = [
	{
		path: '/',
		element: <USER.DASHBOARD />,
		exact: true,
	},
	{
		path: '/board/:type',
		element: <USER.BOARD />,
		exact: true,
	},
	{
		path: '/board/:type/:action',
		element: <USER.BOARD_WRITE />,
		exact: true,
	},
	{
		path: '/board/:type/p/:page',
		element: <USER.BOARD />,
		exact: true,
	},
	{
		path: '/board/:type/:action/:id',
		element: <USER.BOARD_WRITE />,
		exact: true,
	},
	{
		path: '/board/:type/view/:id',
		element: <USER.BOARD_VIEW />,
		exact: true,
	},
	{
		path: userMenu.dayoffApply.path,
		element: <USER.DAYOFF_MANAGE />,
		exact: true,
	},
	{
		path: userMenu.dayoffApproval.path,
		element: <USER.DAYOFF_APPROVAL />,
		exact: true,
	},
	{
		path: userMenu.dayoffApprovalDelegation.path,
		element: <USER.DAYOFF_APPROVAL_DELEGATION />,
		exact: true,
	},
	{
		path: userMenu.dayoffHistory.path,
		element: <USER.DAYOFF_REQUEST_LIST />,
		exact: true,
	},
	{
		path: userMenu.remainDayoff.path,
		element: <USER.DAYOFF_REMAIN />,
		exact: true,
	},
	{
		path: userMenu.payManage.path,
		element: <USER.DAYOFF_PAYMANAGE />,
		exact: true,
	},
	{
		path: '/leave/request/detail/:id',
		element: <USER.DAYOFF_REQUEST />,
		exact: true,
	},
	{
		path: '/leave/request',
		element: <USER.DAYOFF_REQUEST />,
		exact: true,
	},
	{
		path: userMenu.changePassword.path,
		element: <AUTH.CHANGE_PASSWORD />,
		exact: true,
	},
	{
		path: userMenu.dayoffPromotionUser.path,
		element: <USER.DAYOFF_PROMOTION />,
		exact: true,
	},
	{
		path: userMenu.dayoffPromotionUser.path + '/:tab',
		element: <USER.DAYOFF_PROMOTION />,
		exact: true,
	},
	// {
	// 	path: '/dayoff/request/detail',
	// 	element: <USER.DAYOFF_REQUEST />,
	// 	exact: true,
	// },
];

// const AAAA = {
// 	DASHBOARD: lazy(() => import('../pages'))
// }

// const commonRoute = [

// ]

// console.log('rootStore.user.me.type', rootStore.user?.me?.type);
const userType = getItem('userType');
let contents = [...authRoute];
switch (userType) {
	case 'SYSTEM':
		contents = [...contents, ...systemAdminRoute];
		break;
	case 'ADMIN':
		contents = [...contents, ...adminRoute];
		break;
	case 'USER':
		contents = [...contents, ...userRoute];
		break;
	default:
		contents = [...authRoute, ...systemAdminRoute, ...adminRoute, ...userRoute];
		break;
}
// const contents = [...authRoute, ...systemAdminRoute, ...adminRoute, ...userRoute];

export default contents;
