import React, { useEffect, useRef } from 'react';
import Loader from './Loader';

const LoaderContext = React.createContext();

export const LoaderProvider = ({ children }) => {
	const ref = useRef(null);

	const startLoader = (message = null) => {
		if (ref?.current) ref.current.start(message);
	};
	const stopLoader = () => {
		if (ref?.current) ref.current.stop();
	};
	const value = { ref, startLoader, stopLoader };
	// React.useMemo(() => {
	//     return { ref, startLoader, stopLoader }
	//     // eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [ref, startLoader, stopLoader]);

	return (
		<LoaderContext.Provider value={value}>
			{children}
			<Loader ref={ref} />
		</LoaderContext.Provider>
	);
};

export const useLoader = () => React.useContext(LoaderContext);
