import { types } from 'mobx-state-tree';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import CompanyService from '../services/CompanyService';

export const Company = types
	.model('Company', {
		id: types.optional(types.number, 0),
		name: types.optional(types.string, ''),
		userId: types.optional(types.number, 0),
		companyId: types.optional(types.number, 0),
		roleId: types.optional(types.number, 0),
		type: types.optional(types.string, ''),
		code: types.optional(types.string, ''),
		joinDate: types.optional(types.string, ''),
		applyJoinDate: types.optional(types.string, ''),
		retireDate: types.optional(types.string, ''),
		corporationDate: types.optional(types.string, ''),
		license: types.optional(types.string, ''),
		isChat: types.optional(types.boolean, false),
		isMailing: types.optional(types.boolean, false),
		employeeType: types.optional(types.string, ''),
		
		featureApplyUnit: types.optional(types.string, ''),
		holidayApplyUnit: types.optional(types.string, ''),

		role: types.optional(types.string, '{}'),
		workPlace: types.optional(types.string, '{}'),
		userRestInfos: types.optional(types.string, '{}'),
		rank: types.optional(types.string, '{}'),
		depart: types.optional(types.string, '{}'),
		position: types.optional(types.string, '{}'),
		logoFile: types.optional(types.string, '{}'),

		isApproval: types.optional(types.boolean, false), // 결재권자 여부
		// isAdmin: types.optional(types.boolean, false),
	})
	.views((self) => ({
		get get() {
			// console.log('get', self.id, self.isApproval);
			return {
				...self,
				role: JSON.parse(self.role),
				workPlace: JSON.parse(self?.workPlace),
				userRestInfos: JSON.parse(self.userRestInfos),
				rank: JSON.parse(self.rank),
				depart: JSON.parse(self.depart),
				position: JSON.parse(self.position),
				logoFile: JSON.parse(self.logoFile),
			};
		},
	}))
	.actions((self) => ({
		setData(data) {
			// console.log('set', data?.isApproval);
			self.id = data?.id || self.id;
			self.userId = data?.userId || self.userId;
			self.companyId = data?.companyId || self.companyId;
			self.name = data?.name || self.name;
			self.code = data?.code || self.code;
			self.roleId = data?.roleId || self.roleId;
			self.type = data?.type || self.type;
			self.joinDate = data?.joinDate || self.joinDate;
			self.applyJoinDate = data?.applyJoinDate || self.applyJoinDate;
			self.retireDate = data?.retireDate || self.retireDate;
			self.corporationDate = data?.corporationDate || self.corporationDate;
			self.license = data?.license || self.license;
			self.isChat = data?.isChat;
			self.isMailing = data?.isMailing;
			self.employeeType = data?.employeeType || self.employeeType;
			self.featureApplyUnit = data?.company?.featureApplyUnit || self.featureApplyUnit;
			self.holidayApplyUnit = data?.company?.holidayApplyUnit || self.holidayApplyUnit;

			self.role = JSON.stringify(data?.role || {});
			self.workPlace = JSON.stringify(data?.workPlace || {});
			self.userRestInfos = JSON.stringify(data?.userRestInfos || []);
			self.rank = JSON.stringify(data?.rank || {});
			self.depart = JSON.stringify(data?.depart || {});
			self.position = JSON.stringify(data?.position || {});
			self.logoFile = JSON.stringify(data?.logoFile || {});

			self.isApproval = data?.isApproval;
			// self.isAdmin = data?.isAdmin || self.isAdmin;
		},

		setCode(code) {
			self.code = code;
		},

		setCorporationDate(date) {
			self.corporationDate = date;
		},

		async selectCompany() {
			const res = await CompanyService.selectCompany();
			// console.log('res', res);
			// .then((res) => {
				if (res?.data) {
					// console.log('isApproval', res.data?.isApproval);
					// self.logout();
					self.setData({
						...res.data,
						code: res?.data?.company?.code || '',
						name: res?.data?.company?.name || '',
						role: res.data?.role || {},
						workPlace: res.data?.workPlace || {},
						userRestInfos: res.data?.userRestInfos || {},
						rank: res.data?.rank || {},
						depart: res.data?.depart || {},
						position: res.data?.position || {},
						isApproval: res.data?.isApproval || false,
						logoFile: res.data?.company?.logoFile || {},
						employeeType: res.data?.company?.employeeType || '',
						corporationDate: res?.data?.company?.corporationDate || '',
					});
					// self.setCode(res?.data?.company?.code || '');
					// self.setCorporationDate(res?.data?.company?.corporationDate || '');
				}
				return self.get
			// })
		},

		logout() {
			self.id = 0;
			self.userId = 0;
			self.companyId = 0;
			self.name = '';
			self.roleId = 0;
			self.code = '';
			self.role = '{}';
			self.workPlace = '{}';
			self.userRestInfos = '{}';
			self.rank = '{}';
			self.depart = '{}';
			self.position = '{}';
			self.type = '';
			self.joinDate = '';
			self.applyJoinDate = '';
			self.retireDate = '';
			self.corporationDate = '';
			self.license = '';
			self.employeeType = '';
			self.featureApplyUnit = '';
			self.holidayApplyUnit = '';
			self.isChat = false;
			self.isMailing = false;
			self.logoFile = '{}';
			self.isApproval = false;
			// self.isAdmin = false;
		},
	}));

export default Company;
