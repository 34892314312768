import * as React from 'react';

const SvgCustomFileView = (props) => (
    <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg" className='svg-icon'
    {...props}>
    <path d="M1.25128 20.0193L0.207031 18.95L3.40703 15.75H0.791531V14.25H5.95128V19.4095H4.45128V16.8193L1.25128 20.0193ZM8.14353 19.5V18H13.6915C13.7814 18 13.8551 17.9712 13.9128 17.9135C13.9704 17.8558 13.9993 17.7821 13.9993 17.6923V6.5H9.49928V2H2.30703C2.2172 2 2.14345 2.02883 2.08578 2.0865C2.02811 2.14417 1.99928 2.21792 1.99928 2.30775V12.0577H0.499281V2.30775C0.499281 1.80908 0.675865 1.38308 1.02903 1.02975C1.38236 0.676583 1.80836 0.5 2.30703 0.5H10.2493L15.4993 5.75V17.6923C15.4993 18.1909 15.3227 18.6169 14.9695 18.9703C14.6162 19.3234 14.1902 19.5 13.6915 19.5H8.14353Z" fill="#797979"/>
    </svg>    
);

export default SvgCustomFileView;
