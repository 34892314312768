import React, { forwardRef, useImperativeHandle, useState, useEffect, useRef } from 'react';
import Spinner from '../../components/bootstrap/Spinner';
import dayjs from 'dayjs';

const Loader = forwardRef((props, ref) => {
	const [loading, setLoading] = useState(0);
	const [message, setMessage] = useState(null);
	// const [startTime, setStartTime] = useState(null);
	// const [endTime, setEndTime] = useState(null);
	// const intervalRef = useRef(null);

	// useEffect(() => {
	// 	if(loading > 0) {
	// 		// setEndTime(dayjs());
	// 		intervalRef.current = setInterval(() => {
	// 			console.log('interval', loading)
	// 			setEndTime(dayjs());
	// 		}, 1000);
	// 		// console.log('intervalRef', intervalRef.current)
	// 	}
	// }, [loading, intervalRef, setEndTime]);

	useImperativeHandle(ref, () => ({
		start: (message = null) => {
			const loadingCount = loading + 1;
			setLoading(loadingCount);
			// setStartTime(dayjs());
			// setEndTime(dayjs());
			setMessage(message ? message : '정보를 불러오고 있습니다.');
		},
		stop: () => {
			const loadingCount = loading > 0 ? loading - 1 : 0;
			setLoading(loadingCount);
			// setStartTime(null);
			// setEndTime(null);
			// console.log('clearInterval', intervalRef.current)
			// clearInterval(intervalRef.current)
		},
		isLoading: () => loading >= 1,
	}));

	if (!loading) {
		return null;
	}

	return (
		<div className='w-100 h-100 d-flex flex-column align-items-center justify-content-center text-white fw-bold' style={{ position: 'absolute', backgroundColor: 'rgba(0,0,0,0.5)', zIndex: 99999 }}>
			<Spinner size={'5rem'} color={'light'} />
			<h5 className='mt-3'>{message}</h5>
			{/* {(loading > 0 && endTime) && <h6 className='mt-3'>경과시간: {dayjs(endTime).diff(startTime, 'second')}초</h6>} */}
		</div>
	);
});

export default Loader;
